import component from './pt-BR/component';
import globalHeader from './pt-BR/globalHeader';
import menu from './pt-BR/menu';
import pwa from './pt-BR/pwa';
import settingDrawer from './pt-BR/settingDrawer';
import settings from './pt-BR/settings';

export default {
  'navBar.lang': 'Idiomas',
  'layout.user.link.help': 'ajuda',
  'layout.user.link.privacy': 'política de privacidade',
  'layout.user.link.terms': 'termos de serviços',
  'app.preview.down.block': 'Download this page to your local project',
  'search.info': `To refine your search keyword, please see examples below:

  1. If no operator is used, then search is based on OR.Below  
     is a search of records with 'refund' or 'return' keyword:
     refund return
  
  2. Use + to indicate AND:
     +michael +jackson
  
  3. Use - to indicate NOT:
     +michael -jackson
  
  4. Use * to indicate partial keyword. Search records with 
     phone number 16502329999:
     1650232*
  
  5. Use " to search exact phrase:
     "broken package"`,
  'charts.tickets': 'Tickets Closed',
  'charts.phoneCalls': 'Phone Calls',
  'charts.emails': 'Emails',
  'charts.chats': 'Chats',
  'charts.tooltip.overview':
    'Overview of tickets created and closed by channel and date. You can also view basic metrics of call, email and chat activities.',
  'charts.tooltip.callsTotal': 'Based on all calls, including unanswered or after-hour calls.',
  'charts.tooltip.callUnanswered':
    'Based on all unanswered calls, including voicemail, missed or prematurely hung-up calls. Both work hour and after hour calls are included.',
  'charts.tooltip.avgWait':
    'Based on only inbound calls which are answered by agents. It does not include calls resulting in voicemail after max wait time is reached.',
  'charts.tooltip.avgTalk': 'Based on inbound calls answered by agents.',
  'charts.tooltip.chatsTotal': 'Based on all chats, including offline message sessions.',
  'charts.tooltip.chatsMissed': 'Based on messages left when no agents are online.',
  'charts.tooltip.email': 'Sent/Received emails and average response time in hour by day.',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
};
