import component from './zh-TW/component';
import globalHeader from './zh-TW/globalHeader';
import menu from './zh-TW/menu';
import pwa from './zh-TW/pwa';
import settingDrawer from './zh-TW/settingDrawer';
import settings from './zh-TW/settings';

export default {
  'navBar.lang': '語言',
  'layout.user.link.help': '幫助',
  'layout.user.link.privacy': '隱私',
  'layout.user.link.terms': '條款',
  'app.preview.down.block': '下載此頁面到本地項目',
  'search.info': `To refine your search keyword, please see examples below:

  1. If no operator is used, then search is based on OR.Below  
     is a search of records with 'refund' or 'return' keyword:
     refund return
  
  2. Use + to indicate AND:
     +michael +jackson
  
  3. Use - to indicate NOT:
     +michael -jackson
  
  4. Use * to indicate partial keyword. Search records with 
     phone number 16502329999:
     1650232*
  
  5. Use " to search exact phrase:
     "broken package"`,
  'charts.tickets': 'Tickets Closed',
  'charts.phoneCalls': 'Phone Calls',
  'charts.emails': 'Emails',
  'charts.chats': 'Chats',
  'charts.tooltip.overview':
    'Overview of tickets created and closed by channel and date. You can also view basic metrics of call, email and chat activities.',
  'charts.tooltip.callsTotal': 'Based on all calls, including unanswered or after-hour calls.',
  'charts.tooltip.callUnanswered':
    'Based on all unanswered calls, including voicemail, missed or prematurely hung-up calls. Both work hour and after hour calls are included.',
  'charts.tooltip.avgWait':
    'Based on only inbound calls which are answered by agents. It does not include calls resulting in voicemail after max wait time is reached.',
  'charts.tooltip.avgTalk': 'Based on inbound calls answered by agents.',
  'charts.tooltip.chatsTotal': 'Based on all chats, including offline message sessions.',
  'charts.tooltip.chatsMissed': 'Based on messages left when no agents are online.',
  'charts.tooltip.email': 'Sent/Received emails and average response time in hour by day.',
  '': 'Average first response time by day.',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
};
