import request from '@/utils/request';
import { func } from 'prop-types';

export async function getEntityDetailById(entity, id) {
  return request(`/api/${entity}/${id}`);
}

export async function getEntityDetailLayout(entity) {
  return request(`/api/${entity}/layout/detail`);
}
export async function getEntityDetailComposeSmall(entity) {
  return request(`/api/${entity}/layout/composeSmall`);
}

export async function getEntityDetailSmallLayout(entity) {
  return request(`/api/${entity}/layout/detailSmall`);
}

export async function getEntityListLayout(entity) {
  return request(`/api/${entity}/layout/list`);
}

export async function getEntitySmallListLayout(entity) {
  return request(`/api/${entity}/layout/listSmall`);
}

export async function getBottomPanelsDetailLayout(entity) {
  return request(`/api/${entity}/layout/bottomPanelsDetail`);
}

export async function getSidePanelsDetailLayout(entity) {
  return request(`/api/${entity}/layout/sidePanelsDetail`);
}

export async function getDefaultSidePanelLayout(entity) {
  return request(`/api/${entity}/layout/defaultSidePanel`);
}

export async function getEntityFilters(entity) {
  return request(`/api/${entity}/layout/filters`);
}

export async function getEntityLinkData(entity, entityId, linkName, params) {
  return request.get(`/api/${entity}/${entityId}/${linkName}`, { params });
}

export async function deleteLinkEntity(entity, entityId, linkName, linkId) {
  return request(`/api/${entity}/${entityId}/${linkName}`, {
    method: 'DELETE',
    data: {
      id: linkId,
    },
  });
}

export async function createEntityLinks(entity, entityId, linkName, ids) {
  return request(`/api/${entity}/${entityId}/${linkName}`, {
    method: 'POST',
    data: {
      ids,
    },
  });
}

//Timesheet start
export async function searchTimesheet(entity, params){
  return request.get(`/api/${entity}/clientMonthlyTimesheetHourStats`,{ params })
}

export async function postActionUpdateStatusById(entity, params){
  return request(`/api/${entity}/updateStatusById`,{
    method: 'POST',
    data: params
  })
}

export async function setClientApproval(entity, params){
  return request(`/api/${entity}/setClientApproval`,{
    method: 'POST',
    data: params
  })
}

export async function selectTimesheetAllClient(entity){
  return request.get(`/api/${entity}/selectAllClient`)
}

export async function selectTimesheetAllUser(entity){
  return request.get(`/api/${entity}/selectAllUser`)
}

/**
 * 查询所有的场地经理
 * @returns 
 */
export async function selectAllSiteManager() {
  return request.get(`/api/SMTimesheet/selectAllSiteManager`)
}

/**
 * 查询所有运营经理
 * @returns 
 */
export async function selectAllOperationManager() {
  return request.get('/api/SMTimesheet/selectAllOperationManager')
}

export async function selectUserByClient(entity, params){
  return request.get(`/api/${entity}/selectUserByClient`,{ params })
}

export async function selectClientByUser(entity, params){
  return request.get(`/api/${entity}/selectClientByUser`,{ params })
}

/**
 * 通过场地经理查询对应客户
 * @param {*} params 
 */
export async function selectClientBySiteManager(params) {
  return request.get(`/api/SMTimesheet/selectClientBySiteManager`,{ params })
}

export async function selectTimesheetByClient(entity, params){
  return request.get(`/api/${entity}/selectTimesheetByClient`,{ params })
}

export async function selectTimesheetDesc(entity, params){
  return request.get(`/api/${entity}/selectTimesheetDesc`,{ params })
}

export async function workingHoursApproval(params){
  return request(`/api/Timesheet/workingHoursApproval`,{
    method: 'POST',
    data: params
  })
}

/**
 * 工时审批(场地)
 * @param {*} params 
 * @returns 
 */
export async function workingSMTimeHoursApproval(params){
  return request(`/api/SMTimesheet/workingHoursApproval`, {
    method: 'POST',
    data: params
  })
}

/**
 * 工时审批(运营)
 * @param {*} params 
 * @returns 
 */
export async function workingSMTimeHoursApproval2(params){
  return request(`/api/SMTimesheet/workingHoursApproval2`, {
    method: 'POST',
    data: params
  })
}

export async function batchSetTimesheetByStatus(params){
  return request(`/api/Timesheet/batchSetTimesheetByStatus`,{
    method: 'POST',
    data: params
  })
}

/**
 * 批量同意工时状态
 * @param {*} params 
 * @returns 
 */
export async function batchSetSMTimesheetByStatus(params){
  return request(`/api/SMTimesheet/batchSetTimesheetByStatus`, {
    method: 'POST',
    data: params
  })
}

export async function finalizeApproval(params){
  return request(`/api/Timesheet/finalizeApproval`,{
    method: 'POST',
    data: params
  })
}

/**
 * 最终同意客服工时信息
 * @param {*} params 
 * @returns 
 */
export async function finaliSMTimeApproval(params) {
  return request(`/api/SMTimesheet/finalizeApproval`, {
    method: 'POST',
    data: params
  })
}

export async function updateTimesheetDataById(params){
  return request(`/api/Timesheet/updateTimesheetDataById`,{
    method: 'POST',
    data: params
  })
}

/**
 * 修改对应工时数据(场地)
 * @param {*} params 
 * @returns 
 */
export async function updateSMTimesheetDataById(params) {
  return request(`/api/SMTimesheet/updateTimesheetDataById`, {
    method: 'POST',
    data: params
  })
}

/**
 * 修改对应工时数据(运营)
 * @param {*} params 
 * @returns 
 */
export async function updateSMTimesheetDataById2(params) {
  return request(`/api/SMTimesheet/updateTimesheetDataById2`, {
    method: 'POST',
    data: params
  })
}

export async function selectPayableItemDesc(params){
  return request.get(`/api/Timesheet/selectPayableItemDesc`,{ params })
}

export async function oneSetPayableItemByStatus(params){
  return request(`/api/Timesheet/oneSetPayableItemByStatus`,{
    method: 'POST',
    data: params
  })
}

export async function batchSetPayableItemByStatus(params){
  return request(`/api/Timesheet/batchSetPayableItemByStatus`,{
    method: 'POST',
    data: params
  })
}

export async function updateClientSendMessage(params){
  return request(`/api/Timesheet/updateClientSendMessage`,{
    method: 'POST',
    data: params
  })
}

export async function selectClientSendMessage(params){
  return request.get(`/api/Timesheet/selectClientSendMessage`,{ params })
}

/**
 * 获取页面是否设置定时发送
 * @param {*} params 接口调用参数 
 * @returns 
 */
export async function selectIsSetAutoRequest(params){
  return request.get(`/api/Timesheet/selectIsSetAutoRequest`,{ params })
}

/**
 * 根据客服查询对应工时详情信息
 * @param {*} params 
 * @returns 
 */
export async function selectSMTimeClientSendMessage(params) {
  return request.get(`/api/SMTimesheet/isSendEmailNotifications`, {params})
}

export async function sendTimesheetApprovalEmail(params){
  return request(`/api/Timesheet/sendTimesheetApprovalEmail`,{
    method: 'POST',
    data: params
  })
}

/**
 * 设置邮件定时发送
 * @param {*} params 接口调用参数
 * @returns 
 */
export async function sendSetAutoRequest(params){
  return request(`/api/Timesheet/setAutoRequest`,{
    method: 'POST',
    data: params
  })
}

/**
 * 根据客服查询对应工时详情信息
 * @param {*} params 
 * @returns 
 */
export async function sendSMTimesheetApprovalEmail(params) {
  return request(`/api/SMTimesheet/sendEmailToClientSuccessManager`, {
    method: 'POST',
    data: params
  })
}

export async function selectStreamData(params){
  return request.get(`/api/Timesheet/selectStreamData`,{ params })
}

export async function timesheetExcelExport(params){
  return request(`/api/Timesheet/timesheetExcelExport`,{
    method: 'POST',
    data: params,
    responseType: 'blob',
    contentType: 'application/json',
  })
}

export async function updateAccountantCommentByClient(params){
  return request(`/api/Timesheet/updateAccountantCommentByClient`,{
    method: 'POST',
    data: params
  })
}

export async function selectTotalAgentByClientSuccessManager(params){
  return request.get(`/api/Timesheet/selectTotalAgentByClientSuccessManager`,{ params })
}

/**
 * 查询场地经理名下的客服总数
 * @param {*} params 
 * @returns 
 */
export async function selectSMTotalAgentByClientSuccessManager(params) {
  return request.get(`/api/SMTimesheet/selectTotalAgentBySiteManager`,{ params })
}

export async function createPayableItem(params){
  return request(`/api/Timesheet/createPayableItem`,{
    method: 'POST',
    data: params
  })
}

export async function deletePayableItem(params){
  return request(`/api/Timesheet/deletePayableItem`,{
    method: 'POST',
    data: params
  })
}

export async function updatePayableItem(params){
  return request(`/api/Timesheet/updatePayableItem`,{
    method: 'POST',
    data: params
  })
}

export async function updateAgentCommentToClient(params){
  return request(`/api/Timesheet/updateAgentCommentToClient`,{
    method: 'POST',
    data:params
  });
}

export async function selectAgentCommentToClient(params){
  return request.get(`/api/Timesheet/selectAgentCommentToClient?${params}`);
}

//end

export async function searchEntity(entity, params) {
  return request.get(`/api/${entity}?${params.toString()}`);
}

export async function searchToEmail(entity, params) {
  return request.get(`/api/EmailAddress/action/${entity}`, { params });
}

export async function searchToEmailTemplate(entity, params) {
  return request.get(`/api/EmailTemplate/action/${entity}`, { params });
}

export async function createEntity(entity, payload) {
  return request(`/api/${entity}`, {
    method: 'POST',
    data: payload,
    errorHandler: (error) => {
      const { response } = error;
      return response;
    }
  });
}

export async function updateEntity(entity, id, payload) {
  return request(`/api/${entity}/${id}`, {
    method: 'PUT',
    data: payload,
  });
}

export async function deleteEntityById(entity, id) {
  return request(`/api/${entity}/${id}`, {
    method: 'DELETE',
  });
}

export async function deleteEntityBatch(entity, ids) {
  return request(`/api/${entity}/action/massDelete`, {
    method: 'POST',
    data: { ids },
  });
}

export async function getActionParse(entity, params) {
  return request.get(`/api/${entity}/action/parse`, { params });
}

export async function markAsImportant(current, payload) {
  return request(`/api/Email/action/${current ? 'markAsNotImportant' : 'markAsImportant'}`, {
    method: 'POST',
    data: payload,
  });
}

export async function merge(entity, payload) {
  return request(`/api/${entity}/action/merge`, {
    method: 'POST',
    data: payload,
  });
}

export async function deleteFollow(entity, id) {
  return request(`/api/${entity}/${id}/subscription`, {
    method: 'DELETE',
    // data: {isFollowed: true}
  })
}

export async function putFollow(entity, id) {
  return request(`/api/${entity}/${id}/subscription`, {
    method: 'PUT',
    // data: {isFollowed: true}
  })
}

export async function getEntityDetailHistory(entity, entityId, linkName, params) {
  return request.get(`/api/Activities/${entity}/${entityId}/history`, { params });
}

export async function getExportList(entity,payload){
  return request(`/api/${entity}/action/export`,{
    method:'POST',
    data:payload
  });
}

export async function getMassUpdateSelect(entity){
  return request.get(`/api/${entity}/layout/massUpdate`);
}

export async function getMassUpdate(entity,payload){
  return request(`/api/${entity}/action/massUpdate`,{
    method:'PUT',
    data:payload
  });
}

export async function setSpeedTest(payload){
  return request(`/api/SpeedTest`,{
    method:'POST',
    data:payload 
  })
}

export async function selectAccountByType(payload){
  if(payload){
    return request(`/api/Feedback/selectAccountByType?${payload}`,{
      method:'GET'
    })
  }
  return request(`/api/Feedback/selectAccountByType`,{
    method:'GET'
  })
}

export async function selectFeedbackConfigByAccount(payload){
  return request(`/api/Feedback/selectFeedbackConfigByAccount?${payload}`,{
    method:'GET',
  })
}

export async function satisfactionOverview(payload){
  return request(`/api/Feedback/satisfactionOverview?${payload}`,{
    method:'GET',
  })
}

export async function satisfactionDesc(payload){
  return request(`/api/Feedback/satisfactionDesc?${payload}`,{
    method:'GET',
  })
}

export async function satisfactionExport(payload){
  return request(`/api/Feedback/satisfactionExport?${payload}`,{
    method:'GET',
  })
}

export async function selectFeedbackQuestionByFeedbackConfig(payload){
  return request(`/api/Feedback/selectFeedbackQuestionByFeedbackConfig?feedbackConfigId=${payload}`,{
    method:'GET',
  })
}

export async function selectUserByAccountAndFeedbackConfig(payload){
  return request(`/api/Feedback/selectUserByAccountAndFeedbackConfig?${payload}`,{
    method:'GET',
  })
}

export async function getAccount(){
  return request(`/api/Account?select=id,name`,{
    method:"GET",
  });
}

/**
 * 语音智能检测解析相关数据
 * @param {*} data 需要解析语音参数
 * @returns 
 */
export async function getAudioData(data) {
  return request(`/api/newAudit/createAuditRecord `, {
    method:'POST',
    data:data 
  })
}




