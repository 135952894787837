// 对工单id 进行保存与清除
export default {
    namespaced: 'room',
    state: {

    },
    effects: {
        *fetch({ payload }, { call, put }){
            if(payload?.id){
                yield put({
                    type: 'handleCaseId',
                    payload:payload.id
                });
            }
        },
        *clear(_, { put }){
            yield put({
                type: 'handleClear',
            })
        },
        *deleteRoom({ payload },{ put }){
            if(payload){
                yield put({
                    type: 'handleDeleteRoom',
                    payload:payload
                })
            }
        }
    },
    reducers: {
        handleCaseId(state, { payload }){
            return payload
        },
        handleClear(){
            return '';
        },
        handleDeleteRoom(state,{ payload }){
            return { delete:payload }
        }
    }
}