import { getSettings } from "@/services/crmSettings";
import espoConfig from "../../config/espoConfig";
import crmSettings from "../../mock/crmSettings";

export default {
  namespace: 'crmSettings',
  state: {},

  effects: {
    *fetch(_, {call, put}) {
      if (espoConfig.useMock) {
        yield put({
          type: 'setSettings',
          payload: crmSettings
        });
      } else {
        const response = yield call(getSettings);
        yield put({
          type: 'setSettings',
          payload: response
        });
      }
    }
  },

  reducers: {
    setSettings(_, { payload }) {
      return payload;
    },
    clear() {
      return {};
    }
  }
}