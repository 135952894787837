import request from '@/utils/request';

export async function getOutstandingEmails() {
  return request('/api/Email?folderId=inbox&select=subject,from,fromName,dateSent,status&maxSize=5&offset=0');
}

export async function getEmailFolder() {
  return request.get(`/api/EmailFolder/action/listAll?maxSize=200&offset=0&orderBy=order&order=asc`);
}

export async function getServiceEmailByAccount(params) {
  return request.get(`/api/Other/getServiceEmailByAccount?entityName=Account&entityId=${params}`);
}
