// 一键拨号 电话信息 进行保存与清除
export default {
    namespaced: 'dialpad',
    state: {

    },
    effects: {
        *fetch({ payload }, { call, put }){
            if(payload){
                yield put({
                    type: 'handleDialing',
                    payload:payload
                });
            }
        },
        *clear(_, { put }){
            yield put({
                type: 'handleClear',
            })
        },
    },
    reducers: {
        handleDialing(state, { payload }){
            return payload
        },
        handleClear(){
            return {};
        },
    }
}