import { parse } from 'querystring';
import moment from 'moment';
import { utils } from 'umi';
// eslint no-useless-escape:0 import/prefer-default-export:0

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);
export const isAntDesignPro = () => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};
export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const generateMixed = () => {
  let res = "";
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 17; i++) {
    res += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return res;
}

export const verifySuffix = (FileName, type) => {
  if (type == 'audio') var suffix = `(mp3|wav)`;
  if (type == 'picture') var suffix = `(bmp|jpg|png|gif|svg|jpeg)`;
  if (type == 'file') var suffix = `(txt|doc|docx|xls|pdf|mp4|avi|mpeg|wmv|mov|ppt|pptx)`;

  const regular = new RegExp(`.*\.${suffix}`);
  return regular.test(FileName);
}

export const currentTime = (time, lan, timeZone) => {
  if (lan === 'en_US') {
    moment.updateLocale(timeZone, {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: 'a few seconds',
        ss: '%d seconds',
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
      }
    });
  let valTime = moment(time).add(localStorage.getItem('diffTime') ?? 0)
  let initialTime = moment(time)
  valTime._z = null
  if(valTime._isUTC === false){
    return valTime.fromNow()
  }else{
    initialTime._offset= null
    return initialTime.fromNow()
  }
    // return moment(time).add(localStorage.getItem('diffTime') ?? 0).fromNow();
  }
  if (lan === 'zh_CN') {
    moment.updateLocale(timeZone, {
      relativeTime: {
        future: "%s 后",
        past: "%s 前",
        s: '几秒',
        ss: '%d 秒',
        m: "1分钟",
        mm: "%d 分钟",
        h: "1小时",
        hh: "%d 小时",
        d: "1天",
        dd: "%d 天",
        M: "1个月",
        MM: "%d 个月",
        y: "1一年",
        yy: "%d年"
      }
    });
    let valTime = moment(time).add(localStorage.getItem('diffTime') ?? 0)
    let initialTime = moment(time)
    valTime._z = null
    if(valTime._isUTC === false){
      return valTime.fromNow()
    }else{
      initialTime._offset= null
      return initialTime.fromNow()
    }
    // return moment(time).add(localStorage.getItem('diffTime') ?? 0).fromNow();
  }
}

export const getColor = (style) => {
  if (style === 'primary') {
    return {
      background: '#7ba2c3',
      color: '#fafbfc',
    };
  }
  if (style === 'danger') {
    return {
      background: '#ebccd1',
      color: '#ad4846',
    };
  }
  if (style === 'warning') {
    return {
      background: '#efda97',
      color: '#9e7328',
    };
  }
  if (style === 'info') {
    return {
      background: '#e5ddf8',
      color: '#83779c',
    };
  }
  if (style === 'success') {
    return {
      background: '#d9efce',
      color: '#509831',
    };
  }
  return {
    background: 'rgb(244, 251, 255)',
    color: '#333',
  };
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
