import { getEntityFilters, getEntityListLayout, searchEntity } from "@/services/entity";
import { getEmailFolder } from "@/services/email";
import _ from 'lodash'
/**
 * 存储各个entity列表页面的data，layout，pagination等，这样的话当从编辑/查看页面返回
 * 来的时候就能回到原来的页数，并且不用重新请求数据。
 */
export default {
  namespace: 'entities',
  state: {},

  effects: {
    *fetchData({ payload }, { call, put }) {
      const { entity, params, pageCache } = payload;
      if (entity === 'Chat' && params.toString().indexOf("select") == -1) {
        return;
      }
      const data = yield call(searchEntity, entity, params);
      if (data && data.list) {
        yield put({
          type: 'setData',
          payload: {
            entity,
            data,
            pageCache,
          },
        });
        return data.list
      }
    },
    *fetchToTalData({ payload }, { call, put }) {
      const { entity,disParams } = payload;
      let params = new URLSearchParams();
      // * get all the list data as totalData
      entity === 'Chat' && params.append(
        'select',
        'accountId,accountName,channel,contactId,contactName,messagesIds,messagesNames,numberOfMessages,status,takenCareOf,createdAt,teamsIds,assignedUserId,servicePhoneNumber',
      );
      // params.append('maxSize','20')
      if(disParams.maxSize){
        params.append('maxSize',disParams.maxSize)
      }
      let totalData = yield call(searchEntity, entity, params, disParams);
      if (totalData && totalData.list) {
        if (entity === 'Chat') {
          totalData.list.forEach(
            (item, i) => {
              totalData.list[i].messagesName = '';
              totalData.list[i].messagesIds && totalData.list[i].messagesIds.forEach((v, index) => {
                totalData.list[i].messagesName += totalData.list[i].messagesNames[v] + ';';
              });
            })
        }
        yield put({
          type: 'setTotalData',
          payload: {
            entity,
            totalData
          },
        });
      }
    },

    *fetchListLayout({ payload, callback }, { call, put }) {
      const { entity } = payload;
      const listLayout = yield call(getEntityListLayout, entity);
      if (Array.isArray(listLayout)) {
        yield put({
          type: 'setListLayout',
          payload: {
            entity,
            listLayout: listLayout.filter(layout => layout.name !== 'dial'), // 新系统不显示dial字段
          }
        });

        if (callback) {
          callback(listLayout);
        }
      }
    },

    *fetchFilters({ payload }, { call, put }) {
      const { entity } = payload;
      const filters = yield call(getEntityFilters, entity);
      if (Array.isArray(filters)) {
        yield put({
          type: 'setFilters',
          payload: {
            entity,
            filters,
          },
        });
      }
    },

    *fetchFolder(_, { call, put }) {
      const response = yield call(getEmailFolder);
      yield put({
        type: 'setFolder',
        payload: response,
      });
    },

    *clear(_, { put }) {
      yield put({
        type: 'clearEntities',
      });
    },
  },

  reducers: {
    clearEntities() {
      return {};
    },

    setDataChanged(state = {}, { payload }) {
      const { entity, dataChanged } = payload;
      const moduleData = state[entity] || {};
      return { ...state, [entity]: { ...moduleData, dataChanged } };
    },

    setData(state = {}, { payload }) {
      const { entity, data, pageCache } = payload;
      const moduleData = state[entity] || {};
      return { ...state, [entity]: { ...moduleData, data, pageCache, dataChanged: false } };
    },

    setTotalData(state = {}, { payload }) {
      const { entity, totalData } = payload;
      const moduleData = state[entity] || {};
      return { ...state, [entity]: { ...moduleData, totalData } };
    },

    setFolder(state = {}, { payload }) {
      payload.list = payload.list.filter(item => ['inbox', 'sent', 'drafts', 'trash'].includes(item.id))
      return { ...state, folder: payload.list };
    },

    setListLayout(state = {}, { payload }) {
      const { entity, listLayout } = payload;
      const moduleData = state[entity] || {};
      return { ...state, [entity]: { ...moduleData, listLayout } };
    },

    setFilters(state = {}, { payload }) {
      const { entity, filters } = payload;
      const moduleData = state[entity] || {};
      return { ...state, [entity]: { ...moduleData, filters } };
    },

    setActiveRow(state = {}, { payload }) {
      const { entity, activeRow } = payload;
      const moduleData = state[entity] || {};
      return { ...state, [entity]: { ...moduleData, activeRow } };
    },

    setExpandAllRows(state = {}, { payload }) {
      const { entity, expandAllRows } = payload;
      const moduleData = state[entity] || {};
      return { ...state, [entity]: { ...moduleData, expandAllRows, tableId: Math.random() } };
    },
    updateDataList(state = {}, { payload }) {
      const { entity, id, key, status } = payload;
      let moduleData = state[entity] || {};
      moduleData.data.list.forEach(
        (item, i) => {
          if (item.id === id) {
            moduleData.data.list[i][key] = status;
          }
        }
      )
      return { ...state, [entity]: { ...moduleData } };
    }
  },
};
