export default {
  'app.settings.menuMap.basic': 'Pengaturan Dasar',
  'app.settings.menuMap.security': 'Pengaturan Keamanan',
  'app.settings.menuMap.binding': 'Kaitkan Akun',
  'app.settings.menuMap.notification': 'Notifikasi Pesan Baru',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Ganti avatar',
  'app.settings.basic.email': 'Email',
  'app.settings.basic.email-message': 'Silakan masukkan email Anda!',
  'app.settings.basic.nickname': 'Nickname',
  'app.settings.basic.nickname-message': 'Silakan masukkan Nickname Anda!',
  'app.settings.basic.profile': 'Profil Personal',
  'app.settings.basic.profile-message': 'Silakan masukkan profil personal Anda!',
  'app.settings.basic.profile-placeholder': 'Perkenalkan singkat tentang diri Anda',
  'app.settings.basic.country': 'Negara/Wilayah',
  'app.settings.basic.country-message': 'Silakan masukkan Negara Anda!',
  'app.settings.basic.geographic': 'Provinsi/Kota',
  'app.settings.basic.geographic-message': 'Silakan masukkan informasi lokasi Anda!',
  'app.settings.basic.address': 'Alamat',
  'app.settings.basic.address-message': 'Silakan masukkan alamat Anda!',
  'app.settings.basic.phone': 'Nomor Telepon',
  'app.settings.basic.phone-message': 'Silakan masukkan nomor telepon Anda!',
  'app.settings.basic.update': 'Pembaruan Informasi',
  'app.settings.security.strong': 'Kuat',
  'app.settings.security.medium': 'Sedang',
  'app.settings.security.weak': 'Lemah',
  'app.settings.security.password': 'Password Akun',
  'app.settings.security.password-description': 'Kekuatan password saat ini',
  'app.settings.security.phone': 'Keamanan telepon',
  'app.settings.security.phone-description': 'Telepon yang ditautkan',
  'app.settings.security.question': 'Pertanyaan Keamanan',
  'app.settings.security.question-description':
    'Pertanyaan keamanan belum dibuat, dan kebijakan keamanan efektif melindungi keamanan akun',
  'app.settings.security.email': 'Email Backup ',
  'app.settings.security.email-description': 'Email yang ditautkan',
  'app.settings.security.mfa': 'Perangkat MFA',
  'app.settings.security.mfa-description':
    'Menghapus Perangkat MFA yang tertaut, setelah menautkan, dapat dikonfirmasi 2 kali',
  'app.settings.security.modify': 'Ubah',
  'app.settings.security.set': 'Set',
  'app.settings.security.bind': 'Tautkan',
  'app.settings.binding.taobao': 'Tautkan ke Taobao',
  'app.settings.binding.taobao-description': 'Saat ini sedang tidak ditautkan ke Akun Taobao',
  'app.settings.binding.alipay': 'Tautkan ke Alipay',
  'app.settings.binding.alipay-description': 'Saat ini sedang tidak ditautkan ke Akun Alipay',
  'app.settings.binding.dingding': 'Tautkan ke DingTalk',
  'app.settings.binding.dingding-description': 'Saat ini sedang tidak ditautkan ke Akun DingTalk',
  'app.settings.binding.bind': 'Tautkan',
  'app.settings.notification.password': 'Password Akun',
  'app.settings.notification.password-description':
    'Pesan dari pengguna lain akan diberitahukan dalam bentuk stasiun surat',
  'app.settings.notification.messages': 'Pesan Sistem',
  'app.settings.notification.messages-description':
    'Pesan dari sistem akan diberitahukan dalam bentuk stasiun surat',
  'app.settings.notification.todo': 'Notifikasi To-do',
  'app.settings.notification.todo-description':
    'Daftar to-do list akan diberitahukan dalam bentuk surat dari stasiun',
  'app.settings.open': 'Buka',
  'app.settings.close': 'Tutup',
};
