export default {
  'menu.welcome': '歡迎',
  'menu.more-blocks': '更多區塊',
  'menu.home': '首頁',
  'menu.login': '登錄',
  'menu.admin': '权限',
  'menu.admin.sub-page': '二级管理页',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.register': '註冊',
  'menu.register.result': '註冊結果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析頁',
  'menu.dashboard.monitor': '監控頁',
  'menu.dashboard.workplace': '工作臺',
  'menu.form': '表單頁',
  'menu.form.basic-form': '基礎表單',
  'menu.form.step-form': '分步表單',
  'menu.form.step-form.info': '分步表單（填寫轉賬信息）',
  'menu.form.step-form.confirm': '分步表單（確認轉賬信息）',
  'menu.form.step-form.result': '分步表單（完成）',
  'menu.form.advanced-form': '高級表單',
  'menu.list': '列表頁',
  'menu.list.table-list': '查詢表格',
  'menu.list.basic-list': '標淮列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（項目）',
  'menu.list.search-list.applications': '搜索列表（應用）',
  'menu.profile': '詳情頁',
  'menu.profile.basic': '基礎詳情頁',
  'menu.profile.advanced': '高級詳情頁',
  'menu.result': '結果頁',
  'menu.result.success': '成功頁',
  'menu.result.fail': '失敗頁',
  'menu.account': '個人頁',
  'menu.account.center': '個人中心',
  'menu.account.settings': '個人設置',
  'menu.account.trigger': '觸發報錯',
  'menu.account.logout': '退出登錄',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.editor': '圖形編輯器',
  'menu.editor.flow': '流程編輯器',
  'menu.editor.mind': '腦圖編輯器',
  'menu.editor.koni': '拓撲編輯器',
  'menu.Emails': '郵件',
  'menu.EmailEdit': '編輯',
  'menu.EmailView': '查看',
  'menu.more': '更多',
  'menu.more.create': '新增',
  'menu.more.edit': '編輯',
  'menu.more.view': '查看',
  'menu.more.Timesheet': '時間表',
  'menu.more.TimesheetEdit': '編輯',
  'menu.more.TimesheetView': '查看',
  'menu.more.AdminTimesheet': '工時管控',
  'menu.more.WriteUp': '詳細',
  'menu.more.WriteUpEdit': '編輯',
  'menu.more.WriteUpView': '查看',
  'menu.more.AbsenceNote': '缺席說明',
  'menu.more.AbsenceNoteEdit': '編輯',
  'menu.more.AbsenceNoteView': '查看',
  'menu.more.AgentSurvey': '代理調查',
  'menu.more.AgentSurveyEdit': '編輯',
  'menu.more.AgentSurveyView': '查看',
  'menu.more.Signature': '簽名',
  'menu.more.SignatureEdit': '編輯',
  'menu.more.SignatureView': '查看',
  'menu.fulfillment': '實現',
  'menu.fulfillment.create': '新增',
  'menu.fulfillment.edit': '編輯',
  'menu.fulfillment.view': '查看',
  'menu.fulfillment.Order': '訂單',
  'menu.fulfillment.OrderEdit': '編輯',
  'menu.fulfillment.OrderView': '查看',
  'menu.fulfillment.Product': '產品',
  'menu.fulfillment.ProductEdit': '編輯',
  'menu.fulfillment.ProductView': '查看',
  'menu.Order': '訂單',
  'menu.OrderEdit': '編輯',
  'menu.OrderView': '查看',
  'menu.Product': '產品',
  'menu.ProductEdit': '編輯',
  'menu.ProductView': '查看',
  'menu.Document': '文件',
  'menu.DocumentEdit': '編輯',
  'menu.DocumentView': '查看',
  'menu.more.Exam': '檢查',
  'menu.more.ExamEdit': '編輯',
  'menu.more.ExamView': '查看',
  'menu.more.Quiz': '測驗',
  'menu.more.QuizEdit': '編輯',
  'menu.more.QuizView': '查看',
  'menu.more.CannedMessage': '錄音信息',
  'menu.more.CannedMessageEdit': '編輯',
  'menu.more.CannedMessageView': '查看',
  'menu.more.ScreenshotActivity': '監測槽',
  'menu.more.ScreenshotActivityEdit': '編輯',
  'menu.more.ScreenshotActivityView': '查看',
  'menu.more.ScreenshotAppsUrls': '監控應用程序',
  'menu.more.ScreenshotAppsUrlsEdit': '編輯',
  'menu.more.ScreenshotAppsUrlsView': '查看',
  'menu.more.ScreenshotReport': '檢測總結',
  'menu.more.ScreenshotReportEdit': '編輯',
  'menu.more.ScreenshotReportView': '查看',
  'menu.more.ServiceRequest': '服務請求',
  'menu.more.ServiceRequestEdit': '編輯',
  'menu.more.ServiceRequestView': '查看',
  'menu.more.SpeedTest': '速度測試',
  'menu.more.SpeedTestEdit': '編輯',
  'menu.more.SpeedTestView': '查看',
  'menu.more.Screenshot': '監控截圖',
  'menu.more.ScreenshotEdit': '編輯',
  'menu.more.ScreenshotView': '查看',
  'menu.stats': '分析',
  'menu.stats.Overview': '預覽',
  'menu.stats.Agent': '客服',
  'menu.stats.PhoneCall': '電話',
  'menu.stats.Email': '郵件',
  'menu.stats.Chat': '聊天',
  'menu.notification': '通知',
  'menu.dashboard': '儀表盤',
  'menu.contact': '客戶',
  'menu.case': '工單',
  'menu.Chat': '對話',
  'menu.PhoneCalls': '電話',
  'menu.ChatsOpen': '開啓聊天',
};
