import request from '@/utils/request';

const TimeApi = {

  getUtcTime: async () => {
    return request('/api/utcTime');
  }

};

export default TimeApi;