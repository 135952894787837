export default {
  'pages.layouts.userLayout.title':
    'Ant Design is the most influential web design specification in Xihu district',
  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password（admin/ant.design)',
  'pages.login.username.placeholder': 'Username',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Password',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Submit',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',
  'pages.welcome.advancedComponent': 'Advanced Component',
  'pages.welcome.link': 'Welcome',
  'pages.welcome.advancedLayout': 'Advanced Layout',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.admin.subPage.title': 'This page can only be viewed by Admin',
  'pages.admin.subPage.alertMessage':
    'Umi ui is now released, welcome to use npm run ui to start the experience.',
  'pages.searchTable.createForm.newRule': 'New Rule',
  'pages.searchTable.updateForm.ruleConfig': 'Rule configuration',
  'pages.searchTable.updateForm.basicConfig': 'Basic Information',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Rule Name',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Please enter the rule name!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Rule Description',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Please enter at least five characters',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Please enter a rule description of at least five characters!',
  'pages.searchTable.updateForm.ruleProps.title': 'Configure Properties',
  'pages.searchTable.updateForm.object': 'Monitoring Object',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Rule Template',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Rule Type',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Set Scheduling Period',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Starting Time',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Please choose a start time!',
  'pages.searchTable.titleDesc': 'Description',
  'pages.searchTable.ruleName': 'Rule name is required',
  'pages.searchTable.titleCallNo': 'Number of Service Calls',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'default',
  'pages.searchTable.nameStatus.running': 'running',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'abnormal',
  'pages.searchTable.titleUpdatedAt': 'Last Scheduled at',
  'pages.searchTable.exception': 'Please enter the reason for the exception!',
  'pages.searchTable.titleOption': 'Option',
  'pages.searchTable.config': 'Configuration',
  'pages.searchTable.subscribeAlert': 'Subscribe to alerts',
  'pages.searchTable.title': 'Enquiry Form',
  'pages.searchTable.new': 'New',
  'pages.searchTable.chosen': 'chosen',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Total Number of Service Calls',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'bacth deletion',
  'pages.searchTable.batchApproval': 'batch approval',
  'pages.commentRequired': 'Comment is required.',
  'pages.dashboard.opentickets': 'Open tickets',
  'pages.dashboard.ticketstoupdate': 'Tickets to update',
  'pages.dashboard.callstoreturn': 'Calls to return',
  'pages.dashboard.callsticket': 'Calls w/o ticket',
  'pages.dashboard.missedsms': 'Missed SMS',
  'pages.dashboard.missedWebchat': 'Missed Webchat',
  'pages.dashboard.missedfbmsges': 'Missed FB Msges',
  'pages.dashboard.goal': 'Goal',
  'pages.dashboard.monthlychange': 'Monthly Change',
  'pages.dashboard.csat': 'CSAT',
  'pages.dashboard.csatrating': 'CSAT 5-rating ',
  'pages.dashboard.csatlowrating': 'CSAT Low Rating ',
  'pages.dashboard.averagewaittimeange': 'Average Wait Time',
  'pages.dashboard.unexecutedunanswered': 'Unexecuted Unanswered',
  'pages.dashboard.csattip': 'Please ask for rating at then end of each call',
  'pages.dashboard.csatratingtip': 'Provide the best customer service',
  'pages.dashboard.csatlowratingtip': 'Thrive in difficult situatons ',
  'pages.dashboard.averagewaittimeangetip': 'Pick up calls quickly',
  'pages.dashboard.unexecutedunansweredtip': 'Try to not miss any call',
  'pages.stats.overview': 'Overview',
  'pages.stats.callsTotal': 'Calls Total',
  'pages.stats.callUnanswered': 'Calls Unanswered',
  'pages.stats.avgWait': 'Avg Wait',
  'pages.stats.avgTalk': 'Avg Talk',
  'pages.stats.chatsTotal': 'Chats Total',
  'pages.stats.chatsMissed': 'Chats Missed',
  'pages.stats.emailSent': 'Emails Sent',
  'pages.stats.emailReceived': 'Emails Received',
  'pages.stats.ticketscreated': 'Tickets Created',
  'pages.stats.ticketsclosed': 'Tickets Closed',
  'pages.stats.ticketscreatedbyhour': 'Tickets Created By Hour',
  'pages.stats.creationdate': 'Creation date',
  'pages.stats.phonecallanalytics': 'Phone Call Analytics',
  'pages.stats.inbound': 'Inbound',
  'pages.stats.outbound': 'Outbound',
  'pages.stats.answeredvsunanswered': 'Answered vs. Unanswered',
  'pages.stats.inboundoutbound': 'Inbound vs. Outbound',
  'pages.stats.rating': 'Rating',
  'pages.stats.inboundanswered': 'Inbound Answered',
  'pages.stats.inboundunAnswered': 'Inbound UnAnswered',
  'pages.stats.outboundanswered': 'Outbound Answered',
  'pages.stats.outboundunanswered': 'Outbound UnAnswered',
  'pages.stats.ratedtotal': 'Rated Total',
  'page.case.view.msg.success': 'success',
  'pages.stats.inbounda': 'Inbound <br> Answered',
  'pages.stats.outbounda': 'Outbund <br> Answered',
  'page.stats.overview.ticket': 'Support Volume',
  'page.stats.overview.chat': 'Volume Metrics',
  'page.stats.overview.channel': 'By Channel',
  'page.stats.overview.respondedandmiss': 'Received vs. Missed',
  'page.stats.overview.workperiod': 'By Work Period',
  'page.stats.overview.missedduringwork': 'Received vs. Missed During Work Hour',
  'pages.stats.Yes': 'Yes',
  'pages.stats.No': 'No',
  'pages.stats.numberoftickets':'Number of tickets',
  'pages.stats.day':'Day',
  'pages.stats.month':'Month',
  'pages.stats.Completed':'Completed',
  'pages.stats.missed':'Missed',
  'pages.stats.hungup':'Hungup',
  'pages.stats.voicemail':'Voicemail',
  'pages.stats.busy':'Busy',
  'pages.stats.talked':'Talked',
  'pages.stats.unreachable':'Unreachable',
  'pages.stats.inboundtaken':'Inbound Unanswered <br> Taken Care Of',
  'pages.stats.waittimesla':'Wait Time SLA (sec.)',
  'pages.stats.received':'Received',
  'pages.stats.sent':'Sent',
  'pages.stats.ticketsbytypes':'Tickets By Types',
  'pages.stats.type':'Type',
  'pages.stats.created':'Created',
  'pages.stats.closed':'Closed',
  'pages.stats.avg':'Avg. Lead Time(hrs)',
  'pages.stats.inprogress':'In Progress',
  'pages.stats.directsolution':'Direct Solution',
  'pages.stats.avgresponsetimeHour':'Avg. Response Time (Hours)',
  'pages.stats.emailvolume':'Email Volume',
  'pages.stats.emailresponsetime':'Email Response Time(hrs.) SLA',
  'pages.stats.minute':'minute',
  'pages.stats.second':'second',
  'pages.stats.web':'Web',
  'pages.stats.avgchatduration':'Avg Chat Duration',
  'pages.stats.avgfirstresponsetime':'Avg 1st Response Time',
  'pages.stats.avgrectiontime':'Avg Reaction Time',
  'pages.stats.avgresponsetime':'Avg Response Time',
  'pages.stats.bestresponsetime':'Best 1st Response Time',
  'pages.stats.totalconversations':'Total Conversations',
  'pages.stats.totalmessages':'Total Messages',
  'pages.contectEdit.validPhone':'Please enter the valid phone number'
};
