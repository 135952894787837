import { getOutstandingTasks } from "@/services/task";

export default {
  namespace: 'recentTask',
  state: {
    list: [],
    total: 0,
  },
  effects: {
    *fetch(_, {call, put}) {
      
      const response = yield call(getOutstandingTasks);
      yield put({
        type: 'setResult',
        payload: response
      });
    }
  },

  reducers: {
    setResult(_, { payload }) {
      return payload;
    },
    clear() {
      return {};
    }
  }
}