import { getMetadata } from "@/services/metadata";
import metadata from "../../mock/metadata";
import espoConfig from "../../config/espoConfig";

export default {
  namespace: 'metadata',
  state: {},

  effects: {
    *fetch(_, {call, put}) {
      if (espoConfig.useMock) {
        yield put({
          type: 'setMetadata',
          payload: metadata
        });
      } else {
        const response = yield call(getMetadata);
        yield put({
          type: 'setMetadata',
          payload: response
        });
      }
    },

    *clear(_, { put }) {
      yield put({
        type: 'clearMetadata',
      });
    }
  },

  reducers: {
    setMetadata(_, { payload }) {
      return payload;
    },
    clearMetadata() {
      return {};
    }
  }
}