export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Início',
  'menu.login': 'Login',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.register': 'Registro',
  'menu.register.result': 'Resultado de registro',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Análise',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Ambiente de Trabalho',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Formulário',
  'menu.form.basic-form': 'Formulário Básico',
  'menu.form.step-form': 'Formulário Assistido',
  'menu.form.step-form.info': 'Formulário Assistido(gravar informações de transferência)',
  'menu.form.step-form.confirm': 'Formulário Assistido(confirmar informações de transferência)',
  'menu.form.step-form.result': 'Formulário Assistido(finalizado)',
  'menu.form.advanced-form': 'Formulário Avançado',
  'menu.list': 'Lista',
  'menu.list.table-list': 'Tabela de Busca',
  'menu.list.basic-list': 'Lista Básica',
  'menu.list.card-list': 'Lista de Card',
  'menu.list.search-list': 'Lista de Busca',
  'menu.list.search-list.articles': 'Lista de Busca(artigos)',
  'menu.list.search-list.projects': 'Lista de Busca(projetos)',
  'menu.list.search-list.applications': 'Lista de Busca(aplicações)',
  'menu.profile': 'Perfil',
  'menu.profile.basic': 'Perfil Básico',
  'menu.profile.advanced': 'Perfil Avançado',
  'menu.result': 'Resultado',
  'menu.result.success': 'Sucesso',
  'menu.result.fail': 'Falha',
  'menu.exception': 'Exceção',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Disparar',
  'menu.account': 'Conta',
  'menu.account.center': 'Central da Conta',
  'menu.account.settings': 'Configurar Conta',
  'menu.account.trigger': 'Disparar Erro',
  'menu.account.logout': 'Sair',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.Emails': 'Email',
  'menu.EmailEdit': 'Edit',
  'menu.EmailView': 'View',
  'menu.more': 'More',
  'menu.more.create': 'Create',
  'menu.more.edit': 'Edit',
  'menu.more.view': 'View',
  'menu.more.Timesheet': 'Timesheets',
  'menu.more.TimesheetEdit': 'Edit',
  'menu.more.TimesheetView': 'View',
  'menu.more.AdminTimesheet': 'Timesheet Tracking',
  'menu.more.WriteUp': 'Write-ups',
  'menu.more.WriteUpEdit': 'Edit',
  'menu.more.WriteUpView': 'View',
  'menu.more.AbsenceNote': 'Absence Notes',
  'menu.more.AbsenceNoteEdit': 'Edit',
  'menu.more.AbsenceNoteView': 'View',
  'menu.more.AgentSurvey': 'Agent Surveys',
  'menu.more.AgentSurveyEdit': 'Edit',
  'menu.more.AgentSurveyView': 'View',
  'menu.more.Signature': 'Signatures',
  'menu.more.SignatureEdit': 'Edit',
  'menu.more.SignatureView': 'View',
  'menu.fulfillment': 'Implementation',
  'menu.fulfillment.create': 'Create',
  'menu.fulfillment.edit': 'Edit',
  'menu.fulfillment.view': 'View',
  'menu.fulfillment.Order': 'Orders',
  'menu.fulfillment.OrderEdit': 'Edit',
  'menu.fulfillment.OrderView': 'View',
  'menu.fulfillment.Product': 'Products',
  'menu.fulfillment.ProductEdit': 'Edit',
  'menu.fulfillment.ProductView': 'View',
  'menu.Order': 'Orders',
  'menu.OrderEdit': 'Edit',
  'menu.OrderView': 'View',
  'menu.Product': 'Products',
  'menu.ProductEdit': 'Edit',
  'menu.ProductView': 'View',
  'menu.more.Exam': 'Exams',
  'menu.more.ExamEdit': 'Edit',
  'menu.more.ExamView': 'view',
  'menu.more.Quiz': 'Quizzes',
  'menu.more.QuizEdit': 'Edit',
  'menu.more.QuizView': 'view',
  'menu.more.CannedMessage': 'Canned Messages',
  'menu.more.CannedMessageEdit': 'Edit',
  'menu.more.CannedMessageView': 'view',
  'menu.more.ScreenshotActivity': 'Monitoring Slots',
  'menu.more.ScreenshotActivityEdit': 'Edit',
  'menu.more.ScreenshotActivityView': 'view',
  'menu.more.ScreenshotAppsUrls': 'Monitoring Apps',
  'menu.more.ScreenshotAppsUrlsEdit': 'Edit',
  'menu.more.ScreenshotAppsUrlsView': 'view',
  'menu.more.ScreenshotReport': 'Monitoring Summaries',
  'menu.more.ScreenshotReportEdit': 'Edit',
  'menu.more.ScreenshotReportView': 'view',
  'menu.more.ServiceRequest': 'Service Requests',
  'menu.more.ServiceRequestEdit': 'Edit',
  'menu.more.ServiceRequestView': 'view',
  'menu.more.SpeedTest': 'Speed Tests',
  'menu.more.SpeedTestEdit': 'Edit',
  'menu.more.SpeedTestView': 'view',
  'menu.more.Screenshot': 'Monitoring Screenshots',
  'menu.more.ScreenshotEdit': 'Edit',
  'menu.more.ScreenshotView': 'view',
  'menu.stats': 'análise',
  'menu.stats.Overview': 'Preview',
  'menu.stats.Agent': 'Representação',
  'menu.stats.PhoneCall': 'Telefone',
  'menu.stats.Email': 'correio',
  'menu.stats.Chat': 'prosear',
  'menu.notification': 'informação',
  'menu.ChatsOpen': 'Conversa Aberta',
  // 'menu.dashboard': 'O painel de instrumentos',
  // 'menu.contact': '客戶',
  // 'menu.case': '案例',
  // 'menu.Chat': '對話',
  // 'menu.phoneCall': '電話',
};
