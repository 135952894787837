export default {
	"ActionHistoryRecord": {
		"fields": {
			"user": "User",
			"action": "Action",
			"createdAt": "Date",
			"userType": "User Type",
			"target": "Target",
			"targetType": "Target Type",
			"authToken": "Auth Token",
			"ipAddress": "IP Address",
			"authLogRecord": "Auth Log Record"
		},
		"links": {
			"authToken": "Auth Token",
			"authLogRecord": "Auth Log Record",
			"user": "User",
			"target": "Target"
		},
		"presetFilters": {
			"onlyMy": "Only My"
		},
		"options": {
			"action": {
				"read": "Read",
				"update": "Update",
				"delete": "Delete",
				"create": "Create"
			}
		}
	},
	"Admin": {
		"labels": {
			"Enabled": "Enabled",
			"Disabled": "Disabled",
			"System": "System",
			"Users": "Users",
			"Email": "Email",
			"Data": "Data",
			"Customization": "Customization",
			"Available Fields": "Available Fields",
			"Layout": "Layout",
			"Entity Manager": "Entity Manager",
			"Add Panel": "Add Panel",
			"Add Field": "Add Field",
			"Settings": "Settings",
			"Scheduled Jobs": "Scheduled Jobs",
			"Upgrade": "Upgrade",
			"Clear Cache": "Clear Cache",
			"Rebuild": "Rebuild",
			"Teams": "Teams",
			"Roles": "Roles",
			"Portal": "Portal",
			"Portals": "Portals",
			"Portal Roles": "Portal Roles",
			"Portal Users": "Portal Users",
			"API Users": "API Users",
			"Outbound Emails": "Outbound Emails",
			"Group Email Accounts": "Group Email Accounts",
			"Personal Email Accounts": "Personal Email Accounts",
			"Inbound Emails": "Inbound Emails",
			"Email Templates": "Email Templates",
			"Import": "Import",
			"Layout Manager": "Layout Manager",
			"User Interface": "User Interface",
			"Auth Tokens": "Auth Tokens",
			"Auth Log": "Auth Log",
			"Authentication": "Authentication",
			"Currency": "Currency",
			"Integrations": "Integrations",
			"Extensions": "Extensions",
			"Webhooks": "Webhooks",
			"Dashboard Templates": "Dashboard Templates",
			"Upload": "Upload",
			"Installing...": "Installing...",
			"Upgrading...": "Upgrading...",
			"Upgraded successfully": "Upgraded successfully",
			"Installed successfully": "Installed successfully",
			"Ready for upgrade": "Ready for upgrade",
			"Run Upgrade": "Run Upgrade",
			"Install": "Install",
			"Ready for installation": "Ready for installation",
			"Uninstalling...": "Uninstalling...",
			"Uninstalled": "Uninstalled",
			"Create Entity": "Create Entity",
			"Edit Entity": "Edit Entity",
			"Create Link": "Create Link",
			"Edit Link": "Edit Link",
			"Notifications": "Notifications",
			"Jobs": "Jobs",
			"Reset to Default": "Reset to Default",
			"Email Filters": "Email Filters",
			"Action History": "Action History",
			"Label Manager": "Label Manager",
			"Template Manager": "Template Manager",
			"Lead Capture": "Lead Capture",
			"Attachments": "Attachments",
			"System Requirements": "System Requirements",
			"PDF Templates": "PDF Templates",
			"PHP Settings": "PHP Settings",
			"Database Settings": "Database Settings",
			"Permissions": "Permissions",
			"Email Addresses": "Email Addresses",
			"Phone Numbers": "Phone Numbers",
			"Layout Sets": "Layout Sets",
			"Success": "Success",
			"Fail": "Fail",
			"is recommended": "is recommended",
			"extension is missing": "extension is missing",
			"Workflow Manager": "Workflows",
			"Flowcharts": "Flowcharts",
			"Processes": "Processes",
			"Business Process Management": "Business Process Management",
			"Report Filters": "Report Filters",
			"Report Panels": "Report Panels"
		},
		"layouts": {
			"list": "List",
			"detail": "Detail",
			"listSmall": "List (Small)",
			"detailSmall": "Detail (Small)",
			"detailPortal": "Detail (Portal)",
			"detailSmallPortal": "Detail (Small, Portal)",
			"listSmallPortal": "List (Small, Portal)",
			"listPortal": "List (Portal)",
			"relationshipsPortal": "Relationship Panels (Portal)",
			"filters": "Search Filters",
			"massUpdate": "Mass Update",
			"relationships": "Relationship Panels",
			"defaultSidePanel": "Side Panel Fields",
			"bottomPanelsDetail": "Bottom Panels",
			"bottomPanelsEdit": "Bottom Panels (Edit)",
			"bottomPanelsDetailSmall": "Bottom Panels (Detail Small)",
			"bottomPanelsEditSmall": "Bottom Panels (Edit Small)",
			"sidePanelsDetail": "Side Panels (Detail)",
			"sidePanelsEdit": "Side Panels (Edit)",
			"sidePanelsDetailSmall": "Side Panels (Detail Small)",
			"sidePanelsEditSmall": "Side Panels (Edit Small)",
			"kanban": "Kanban",
			"detailConvert": "Convert Lead",
			"listForAccount": "List (for Account)",
			"listForContact": "List (for Contact)"
		},
		"fieldTypes": {
			"address": "Address",
			"array": "Array",
			"foreign": "Foreign",
			"duration": "Duration",
			"password": "Password",
			"personName": "Person Name",
			"autoincrement": "Auto-increment",
			"bool": "Boolean",
			"currency": "Currency",
			"currencyConverted": "Currency (Converted)",
			"date": "Date",
			"datetime": "Date-Time",
			"datetimeOptional": "Date\/Date-Time",
			"email": "Email",
			"enum": "Enum",
			"enumInt": "Enum Integer",
			"enumFloat": "Enum Float",
			"float": "Float",
			"int": "Integer",
			"link": "Link",
			"linkMultiple": "Link Multiple",
			"linkParent": "Link Parent",
			"linkOne": "Link One",
			"phone": "Phone",
			"text": "Text",
			"url": "Url",
			"varchar": "Varchar",
			"file": "File",
			"image": "Image",
			"multiEnum": "Multi-Enum",
			"attachmentMultiple": "Attachment Multiple",
			"rangeInt": "Range Integer",
			"rangeFloat": "Range Float",
			"rangeCurrency": "Range Currency",
			"wysiwyg": "Wysiwyg",
			"map": "Map",
			"number": "Number (auto-increment)",
			"colorpicker": "Color Picker",
			"checklist": "Checklist",
			"barcode": "Barcode",
			"jsonArray": "Json Array",
			"jsonObject": "Json Object"
		},
		"fields": {
			"type": "Type",
			"name": "Name",
			"label": "Label",
			"tooltipText": "Tooltip Text",
			"required": "Required",
			"default": "Default",
			"maxLength": "Max Length",
			"options": "Options",
			"after": "After (field)",
			"before": "Before (field)",
			"link": "Link",
			"field": "Field",
			"min": "Min",
			"max": "Max",
			"translation": "Translation",
			"previewSize": "Preview Size",
			"noEmptyString": "Empty string value is not allowed",
			"defaultType": "Default Type",
			"seeMoreDisabled": "Disable Text Cut",
			"cutHeight": "Cut Height (px)",
			"entityList": "Entity List",
			"isSorted": "Is Sorted (alphabetically)",
			"audited": "Audited",
			"trim": "Trim",
			"height": "Height (px)",
			"minHeight": "Min Height (px)",
			"provider": "Provider",
			"typeList": "Type List",
			"rows": "Number of rows of textarea",
			"lengthOfCut": "Length of cut",
			"sourceList": "Source List",
			"prefix": "Prefix",
			"nextNumber": "Next Number",
			"padLength": "Pad Length",
			"disableFormatting": "Disable Formatting",
			"dynamicLogicVisible": "Conditions making field visible",
			"dynamicLogicReadOnly": "Conditions making field read-only",
			"dynamicLogicRequired": "Conditions making field required",
			"dynamicLogicOptions": "Conditional options",
			"probabilityMap": "Stage Probabilities (%)",
			"notActualOptions": "Not Actual Options",
			"readOnly": "Read-only",
			"maxFileSize": "Max File Size (Mb)",
			"isPersonalData": "Is Personal Data",
			"useIframe": "Use Iframe",
			"useNumericFormat": "Use Numeric Format",
			"strip": "Strip",
			"minuteStep": "Minutes Step",
			"inlineEditDisabled": "Disable Inline Edit",
			"allowCustomOptions": "Allow Custom Options",
			"displayAsLabel": "Display as Label",
			"displayAsList": "Display as List",
			"maxCount": "Max Item Count",
			"accept": "Accept",
			"viewMap": "View Map Button",
			"codeType": "Code Type",
			"lastChar": "Last Character",
			"onlyDefaultCurrency": "Only default currency",
			"displayRawText": "Display raw text (no markdown)"
		},
		"strings": {
			"rebuildRequired": "Rebuild is required"
		},
		"messages": {
			"formulaFunctions": "More functions can be found in [documentation]({documentationUrl}).",
			"rebuildRequired": "You need to run rebuild from CLI.",
			"upgradeVersion": "EspoCRM will be upgraded to version **{version}**. Please be patient as this may take a while.",
			"upgradeDone": "EspoCRM has been upgraded to version **{version}**.",
			"upgradeBackup": "We recommend making a backup of your EspoCRM files and data before upgrading.",
			"thousandSeparatorEqualsDecimalMark": "The thousands separator character can not be the same as the decimal point character.",
			"userHasNoEmailAddress": "User has no email address.",
			"selectEntityType": "Select entity type in the left menu.",
			"selectUpgradePackage": "Select upgrade package",
			"downloadUpgradePackage": "Download upgrade package(s) [here]({url}).",
			"selectLayout": "Select needed layout in the left menu and edit it.",
			"selectExtensionPackage": "Select extension package",
			"extensionInstalled": "Extension {name} {version} has been installed.",
			"installExtension": "Extension {name} {version} is ready for an installation.",
			"cronIsNotConfigured": "Scheduled jobs are not running. Hence inbound emails, notifications and reminders are not working. Please follow the [instructions](https:\/\/www.espocrm.com\/documentation\/administration\/server-configuration\/#user-content-setup-a-crontab) to setup cron job.",
			"newVersionIsAvailable": "New EspoCRM version {latestVersion} is available. Please follow the [instructions](https:\/\/www.espocrm.com\/documentation\/administration\/upgrading\/) to upgrade your instance.",
			"newExtensionVersionIsAvailable": "New {extensionName} version {latestVersion} is available.",
			"uninstallConfirmation": "Are you sure you want to uninstall the extension?",
			"upgradeInfo": "Check the [documentation]({url}) about how to upgrade your EspoCRM instance.",
			"upgradeRecommendation": "This way of upgrading is not recommended. It's better to upgrade from CLI.",
			"newAdvancedPackVersionIsAvailable": "New Advanced Pack version {latestVersion} is available. It can be downloaded on the customer portal."
		},
		"descriptions": {
			"settings": "System settings of application.",
			"scheduledJob": "Jobs which are executed by cron.",
			"jobs": "Jobs execute tasks in the background.",
			"upgrade": "Upgrade EspoCRM.",
			"clearCache": "Clear all backend cache.",
			"rebuild": "Rebuild backend and clear cache.",
			"users": "Users management.",
			"teams": "Teams management.",
			"roles": "Roles management.",
			"portals": "Portals management.",
			"portalRoles": "Roles for portal.",
			"portalUsers": "Users of portal.",
			"outboundEmails": "SMTP settings for outgoing emails.",
			"groupEmailAccounts": "Group IMAP email accounts. Email import and Email-to-Case.",
			"personalEmailAccounts": "Users email accounts.",
			"emailTemplates": "Templates for outbound emails.",
			"import": "Import data from CSV file.",
			"layoutManager": "Customize layouts (list, detail, edit, search, mass update).",
			"entityManager": "Create and edit custom entities. Manage fields and relationships.",
			"userInterface": "Configure UI.",
			"authTokens": "Active auth sessions. IP address and last access date.",
			"authentication": "Authentication settings.",
			"currency": "Currency settings and rates.",
			"extensions": "Install or uninstall extensions.",
			"integrations": "Integration with third-party services.",
			"notifications": "In-app and email notification settings.",
			"inboundEmails": "Settings for incoming emails.",
			"emailFilters": "Email messages that match the specified filter won't be imported.",
			"actionHistory": "Log of user actions.",
			"labelManager": "Customize application labels.",
			"templateManager": "Customize message templates.",
			"authLog": "Login history.",
			"leadCapture": "API entry points for Web-to-Lead.",
			"attachments": "All file attachments stored in the system.",
			"systemRequirements": "System Requirements for EspoCRM.",
			"apiUsers": "Separate users for integration purposes.",
			"webhooks": "Manage webhooks.",
			"emailAddresses": "All emailes addresses stored in the system.",
			"phoneNumbers": "All phone numbers stored in the system.",
			"dashboardTemplates": "Deploy dashboards to users.",
			"layoutSets": "Collections of layouts that can be assigned to teams & portals.",
			"pdfTemplates": "Templates for printing to PDF.",
			"workflowManager": "Configure Workflow rules.",
			"bpmnFlowcharts": "Definitions of business processes.",
			"bpmnProcesses": "Instances of business processes.",
			"reportFilters": "Custom list view filters based on reports.",
			"reportPanels": "Detail view panels showing report results."
		},
		"options": {
			"previewSize": {
				"x-small": "X-Small",
				"small": "Small",
				"medium": "Medium",
				"large": "Large"
			}
		},
		"logicalOperators": {
			"and": "AND",
			"or": "OR",
			"not": "NOT"
		},
		"systemRequirements": {
			"requiredPhpVersion": "PHP Version",
			"requiredMysqlVersion": "MySQL Version",
			"requiredMariadbVersion": "MariaDB version",
			"host": "Host Name",
			"dbname": "Database Name",
			"user": "User Name",
			"writable": "Writable",
			"readable": "Readable"
		},
		"templates": {
			"accessInfo": "Access Info",
			"accessInfoPortal": "Access Info for Portals",
			"assignment": "Assignment",
			"mention": "Mention",
			"noteEmailReceived": "Note about Received Email",
			"notePost": "Note about Post",
			"notePostNoParent": "Note about Post (no Parent)",
			"noteStatus": "Note about Status Update",
			"passwordChangeLink": "Password Change Link",
			"invitation": "Invitation",
			"reminder": "Reminder",
			"reportSendingGrid1": "Report Grid-1",
			"reportSendingGrid2": "Report Grid-2",
			"reportSendingList": "Report List"
		}
	},
	"ApiUser": {
		"labels": {
			"Create ApiUser": "Create API User"
		}
	},
	"Attachment": {
		"fields": {
			"role": "Role",
			"related": "Related",
			"file": "File",
			"type": "Type",
			"field": "Field",
			"sourceId": "Source ID",
			"storage": "Storage",
			"size": "Size (bytes)"
		},
		"options": {
			"role": {
				"Attachment": "Attachment",
				"Inline Attachment": "Inline Attachment",
				"Import File": "Import File",
				"Export File": "Export File",
				"Mail Merge": "Mail Merge",
				"Mass Pdf": "Mass Pdf"
			}
		},
		"insertFromSourceLabels": {
			"Document": "Insert Document"
		},
		"presetFilters": {
			"orphan": "Orphan"
		}
	},
	"AuthLogRecord": {
		"fields": {
			"username": "Username",
			"ipAddress": "IP Address",
			"requestTime": "Request Time",
			"createdAt": "Requested At",
			"isDenied": "Is Denied",
			"denialReason": "Denial Reason",
			"portal": "Portal",
			"user": "User",
			"authToken": "Auth Token Created",
			"requestUrl": "Request URL",
			"requestMethod": "Request Method",
			"authTokenIsActive": "Auth Token is Active",
			"authenticationMethod": "Authentication Method"
		},
		"links": {
			"authToken": "Auth Token Created",
			"user": "User",
			"portal": "Portal",
			"actionHistoryRecords": "Action History"
		},
		"presetFilters": {
			"denied": "Denied",
			"accepted": "Accepted"
		},
		"options": {
			"denialReason": {
				"CREDENTIALS": "Invalid credentials",
				"INACTIVE_USER": "Inactive user",
				"IS_PORTAL_USER": "Portal user",
				"IS_NOT_PORTAL_USER": "Not a portal user",
				"USER_IS_NOT_IN_PORTAL": "User is not related to the portal"
			}
		}
	},
	"AuthToken": {
		"fields": {
			"user": "User",
			"ipAddress": "IP Address",
			"lastAccess": "Last Access Date",
			"createdAt": "Login Date",
			"isActive": "Is Active",
			"portal": "Portal"
		},
		"links": {
			"actionHistoryRecords": "Action History"
		},
		"presetFilters": {
			"active": "Active",
			"inactive": "Inactive"
		},
		"labels": {
			"Set Inactive": "Set Inactive"
		},
		"massActions": {
			"setInactive": "Set Inactive"
		}
	},
	"Currency": {
		"names": {
			"AED": "United Arab Emirates Dirham",
			"AFN": "Afghan Afghani",
			"ALL": "Albanian Lek",
			"AMD": "Armenian Dram",
			"ANG": "Netherlands Antillean Guilder",
			"AOA": "Angolan Kwanza",
			"ARS": "Argentine Peso",
			"AUD": "Australian Dollar",
			"AWG": "Aruban Florin",
			"AZN": "Azerbaijani Manat",
			"BAM": "Bosnia-Herzegovina Convertible Mark",
			"BBD": "Barbadian Dollar",
			"BDT": "Bangladeshi Taka",
			"BGN": "Bulgarian Lev",
			"BHD": "Bahraini Dinar",
			"BIF": "Burundian Franc",
			"BMD": "Bermudan Dollar",
			"BND": "Brunei Dollar",
			"BOB": "Bolivian Boliviano",
			"BOV": "Bolivian Mvdol",
			"BRL": "Brazilian Real",
			"BSD": "Bahamian Dollar",
			"BTN": "Bhutanese Ngultrum",
			"BWP": "Botswanan Pula",
			"BYN": "Belarusian Ruble",
			"BZD": "Belize Dollar",
			"CAD": "Canadian Dollar",
			"CDF": "Congolese Franc",
			"CHE": "WIR Euro",
			"CHF": "Swiss Franc",
			"CHW": "WIR Franc",
			"CLF": "Chilean Unit of Account (UF)",
			"CLP": "Chilean Peso",
			"CNH": "Chinese Yuan (offshore)",
			"CNY": "Chinese Yuan",
			"COP": "Colombian Peso",
			"COU": "Colombian Real Value Unit",
			"CRC": "Costa Rican Col\u00f3n",
			"CUC": "Cuban Convertible Peso",
			"CUP": "Cuban Peso",
			"CVE": "Cape Verdean Escudo",
			"CZK": "Czech Koruna",
			"DJF": "Djiboutian Franc",
			"DKK": "Danish Krone",
			"DOP": "Dominican Peso",
			"DZD": "Algerian Dinar",
			"EGP": "Egyptian Pound",
			"ERN": "Eritrean Nakfa",
			"ETB": "Ethiopian Birr",
			"EUR": "Euro",
			"FJD": "Fijian Dollar",
			"FKP": "Falkland Islands Pound",
			"GBP": "British Pound",
			"GEL": "Georgian Lari",
			"GHS": "Ghanaian Cedi",
			"GIP": "Gibraltar Pound",
			"GMD": "Gambian Dalasi",
			"GNF": "Guinean Franc",
			"GTQ": "Guatemalan Quetzal",
			"GYD": "Guyanaese Dollar",
			"HKD": "Hong Kong Dollar",
			"HNL": "Honduran Lempira",
			"HRK": "Croatian Kuna",
			"HTG": "Haitian Gourde",
			"HUF": "Hungarian Forint",
			"IDR": "Indonesian Rupiah",
			"ILS": "Israeli New Shekel",
			"INR": "Indian Rupee",
			"IQD": "Iraqi Dinar",
			"IRR": "Iranian Rial",
			"ISK": "Icelandic Kr\u00f3na",
			"JMD": "Jamaican Dollar",
			"JOD": "Jordanian Dinar",
			"JPY": "Japanese Yen",
			"KES": "Kenyan Shilling",
			"KGS": "Kyrgystani Som",
			"KHR": "Cambodian Riel",
			"KMF": "Comorian Franc",
			"KPW": "North Korean Won",
			"KRW": "South Korean Won",
			"KWD": "Kuwaiti Dinar",
			"KYD": "Cayman Islands Dollar",
			"KZT": "Kazakhstani Tenge",
			"LAK": "Laotian Kip",
			"LBP": "Lebanese Pound",
			"LKR": "Sri Lankan Rupee",
			"LRD": "Liberian Dollar",
			"LSL": "Lesotho Loti",
			"LYD": "Libyan Dinar",
			"MAD": "Moroccan Dirham",
			"MDL": "Moldovan Leu",
			"MGA": "Malagasy Ariary",
			"MKD": "Macedonian Denar",
			"MMK": "Myanmar Kyat",
			"MNT": "Mongolian Tugrik",
			"MOP": "Macanese Pataca",
			"MRO": "Mauritanian Ouguiya",
			"MUR": "Mauritian Rupee",
			"MWK": "Malawian Kwacha",
			"MXN": "Mexican Peso",
			"MXV": "Mexican Investment Unit",
			"MYR": "Malaysian Ringgit",
			"MZN": "Mozambican Metical",
			"NAD": "Namibian Dollar",
			"NGN": "Nigerian Naira",
			"NIO": "Nicaraguan C\u00f3rdoba",
			"NOK": "Norwegian Krone",
			"NPR": "Nepalese Rupee",
			"NZD": "New Zealand Dollar",
			"OMR": "Omani Rial",
			"PAB": "Panamanian Balboa",
			"PEN": "Peruvian Sol",
			"PGK": "Papua New Guinean Kina",
			"PHP": "Philippine Piso",
			"PKR": "Pakistani Rupee",
			"PLN": "Polish Zloty",
			"PYG": "Paraguayan Guarani",
			"QAR": "Qatari Rial",
			"RON": "Romanian Leu",
			"RSD": "Serbian Dinar",
			"RUB": "Russian Ruble",
			"RWF": "Rwandan Franc",
			"SAR": "Saudi Riyal",
			"SBD": "Solomon Islands Dollar",
			"SCR": "Seychellois Rupee",
			"SDG": "Sudanese Pound",
			"SEK": "Swedish Krona",
			"SGD": "Singapore Dollar",
			"SHP": "St. Helena Pound",
			"SLL": "Sierra Leonean Leone",
			"SOS": "Somali Shilling",
			"SRD": "Surinamese Dollar",
			"SSP": "South Sudanese Pound",
			"STN": "S\u00e3o Tom\u00e9 & Pr\u00edncipe Dobra (2018)",
			"SYP": "Syrian Pound",
			"SZL": "Swazi Lilangeni",
			"SVC": "Salvadoran Col\u00f3n",
			"THB": "Thai Baht",
			"TJS": "Tajikistani Somoni",
			"TND": "Tunisian Dinar",
			"TOP": "Tongan Pa\u02bbanga",
			"TRY": "Turkish Lira",
			"TTD": "Trinidad & Tobago Dollar",
			"TWD": "New Taiwan Dollar",
			"TZS": "Tanzanian Shilling",
			"UAH": "Ukrainian Hryvnia",
			"UGX": "Ugandan Shilling",
			"USD": "US Dollar",
			"USN": "US Dollar (Next day)",
			"UYI": "Uruguayan Peso (Indexed Units)",
			"UYU": "Uruguayan Peso",
			"UZS": "Uzbekistani Som",
			"VEF": "Venezuelan Bol\u00edvar",
			"VND": "Vietnamese Dong",
			"VUV": "Vanuatu Vatu",
			"WST": "Samoan Tala",
			"XAF": "Central African CFA Franc",
			"XCD": "East Caribbean Dollar",
			"XOF": "West African CFA Franc",
			"XPF": "CFP Franc",
			"YER": "Yemeni Rial",
			"ZAR": "South African Rand",
			"ZMW": "Zambian Kwacha",
			"ZWL": "Zimbabwe Dollar"
		}
	},
	"DashboardTemplate": {
		"fields": {
			"layout": "Layout",
			"append": "Append (don't remove user's tabs)"
		},
		"links": [],
		"labels": {
			"Create DashboardTemplate": "Create Template",
			"Deploy to Users": "Deploy to Users",
			"Deploy to Team": "Deploy to Team"
		}
	},
	"DashletOptions": {
		"fields": {
			"title": "Title",
			"dateFrom": "Date From",
			"dateTo": "Date To",
			"autorefreshInterval": "Auto-refresh Interval",
			"displayRecords": "Display Records",
			"isDoubleHeight": "Height 2x",
			"mode": "Mode",
			"enabledScopeList": "What to display",
			"users": "Users",
			"entityType": "Entity Type",
			"primaryFilter": "Primary Filter",
			"boolFilterList": "Additional Filters",
			"sortBy": "Order (field)",
			"sortDirection": "Order (direction)",
			"expandedLayout": "Layout",
			"skipOwn": "Don't show own records",
			"url": "URL",
			"dateFilter": "Date Filter",
			"team": "Team",
			"futureDays": "Next X Days",
			"useLastStage": "Group by last reached stage",
			"report": "Report",
			"column": "Summation Column",
			"displayOnlyCount": "Display Only Total",
			"displayTotal": "Display Total",
			"useSiMultiplier": "SI Multiplier"
		},
		"options": {
			"mode": {
				"agendaWeek": "Week (agenda)",
				"basicWeek": "Week",
				"month": "Month",
				"basicDay": "Day",
				"agendaDay": "Day (agenda)",
				"timeline": "Timeline"
			}
		},
		"messages": {
			"selectEntityType": "Select Entity Type in dashlet options."
		},
		"tooltips": {
			"skipOwn": "Actions made by your user account won't be displayed."
		}
	},
	"DynamicLogic": {
		"labels": {
			"Field": "Field"
		},
		"options": {
			"operators": {
				"equals": "Equals",
				"notEquals": "Not Equals",
				"greaterThan": "Greater Than",
				"lessThan": "Less Than",
				"greaterThanOrEquals": "Greater Than Or Equals",
				"lessThanOrEquals": "Less Than Or Equals",
				"in": "In",
				"notIn": "Not In",
				"inPast": "In Past",
				"inFuture": "Is Future",
				"isToday": "Is Today",
				"isTrue": "Is True",
				"isFalse": "Is False",
				"isEmpty": "Is Empty",
				"isNotEmpty": "Is Not Empty",
				"contains": "Contains",
				"notContains": "Not Contains",
				"has": "Contains",
				"notHas": "Not Contains"
			}
		}
	},
	"Email": {
		"fields": {
			"name": "Name (Subject)",
			"parent": "Parent",
			"status": "Status",
			"dateSent": "Date Sent",
			"from": "From",
			"to": "To",
			"cc": "CC",
			"bcc": "BCC",
			"replyTo": "Reply To",
			"replyToString": "Reply To (String)",
			"personStringData": "Person String Data",
			"isHtml": "Is Html",
			"body": "Body",
			"bodyPlain": "Body (Plain)",
			"subject": "Subject",
			"attachments": "Attachments",
			"selectTemplate": "Select Template",
			"fromEmailAddress": "From Address (link)",
			"toEmailAddresses": "To EmailAddresses",
			"emailAddress": "Email Address",
			"deliveryDate": "Delivery Date",
			"account": "Account",
			"users": "Users",
			"replied": "Replied",
			"replies": "Replies",
			"isRead": "Is Read",
			"isNotRead": "Is Not Read",
			"isImportant": "Is Important",
			"isReplied": "Is Replied",
			"isNotReplied": "Is Not Replied",
			"isUsers": "Is User's",
			"inTrash": "In Trash",
			"sentBy": "Sent By",
			"folder": "Folder",
			"inboundEmails": "Group Accounts",
			"emailAccounts": "Personal Accounts",
			"hasAttachment": "Has Attachment",
			"assignedUsers": "Assigned Users",
			"ccEmailAddresses": "CC Email Addresses",
			"bccEmailAddresses": "BCC EmailAddresses",
			"replyToEmailAddresses": "Reply-To EmailAddresses",
			"messageId": "Message Id",
			"messageIdInternal": "Message Id (Internal)",
			"folderId": "Folder Id",
			"fromName": "From Name",
			"fromString": "From String",
			"fromAddress": "From Address",
			"replyToName": "Reply-To Name",
			"replyToAddress": "Reply-To Address",
			"isSystem": "Is System"
		},
		"links": {
			"replied": "Replied",
			"replies": "Replies",
			"inboundEmails": "Group Accounts",
			"emailAccounts": "Personal Accounts",
			"assignedUsers": "Assigned Users",
			"sentBy": "Sent By",
			"attachments": "Attachments",
			"fromEmailAddress": "From Email Address",
			"toEmailAddresses": "To EmailAddresses",
			"ccEmailAddresses": "CC EmailAddresses",
			"bccEmailAddresses": "BCC EmailAddresses",
			"replyToEmailAddresses": "Reply-To EmailAddresses"
		},
		"options": {
			"status": {
				"Draft": "Draft",
				"Sending": "Sending",
				"Sent": "Sent",
				"Archived": "Archived",
				"Received": "Received",
				"Failed": "Failed"
			}
		},
		"labels": {
			"Create Email": "Archive Email",
			"Archive Email": "Archive Email",
			"Compose": "Compose",
			"Reply": "Reply",
			"Reply to All": "Reply to All",
			"Forward": "Forward",
			"Original message": "Original message",
			"Forwarded message": "Forwarded message",
			"Email Accounts": "Personal Email Accounts",
			"Inbound Emails": "Group Email Accounts",
			"Email Templates": "Email Templates",
			"Send Test Email": "Send Test Email",
			"Send": "Send",
			"Email Address": "Email Address",
			"Mark Read": "Mark Read",
			"Sending...": "Sending...",
			"Save Draft": "Save Draft",
			"Mark all as read": "Mark all as read",
			"Show Plain Text": "Show Plain Text",
			"Mark as Important": "Mark as Important",
			"Unmark Importance": "Unmark Importance",
			"Move to Trash": "Move to Trash",
			"Retrieve from Trash": "Retrieve from Trash",
			"Move to Folder": "Move to Folder",
			"Filters": "Filters",
			"Folders": "Folders",
			"No Subject": "No Subject",
			"View Users": "View Users",
			"Create Lead": "Create Lead",
			"Create Contact": "Create Contact",
			"Add to Contact": "Add to Contact",
			"Add to Lead": "Add to Lead",
			"Create Task": "Create Task",
			"Create Case": "Create Case"
		},
		"messages": {
			"noSmtpSetup": "SMTP is not configured: {link}",
			"testEmailSent": "Test email has been sent",
			"emailSent": "Email has been sent",
			"savedAsDraft": "Saved as draft",
			"sendConfirm": "Send the email?",
			"removeSelectedRecordsConfirmation": "Are you sure you want to remove selected emails?\n\nThey will be removed for other users too.",
			"removeRecordConfirmation": "Are you sure you want to remove the email?\n\nIt will be removed for other users too.",
			"confirmInsertTemplate": "The email body will be lost. Are you sure you want to insert the template?"
		},
		"presetFilters": {
			"sent": "Sent",
			"archived": "Archived",
			"inbox": "Inbox",
			"drafts": "Drafts",
			"trash": "Trash",
			"important": "Important"
		},
		"massActions": {
			"markAsRead": "Mark as Read",
			"markAsNotRead": "Mark as Not Read",
			"markAsImportant": "Mark as Important",
			"markAsNotImportant": "Unmark Importance",
			"moveToTrash": "Move to Trash",
			"moveToFolder": "Move to Folder",
			"retrieveFromTrash": "Retrieve from Trash"
		}
	},
	"EmailAccount": {
		"fields": {
			"name": "Name",
			"status": "Status",
			"host": "Host",
			"username": "Username",
			"password": "Password",
			"port": "Port",
			"monitoredFolders": "Monitored Folders",
			"ssl": "SSL",
			"fetchSince": "Fetch Since",
			"emailAddress": "Email Address",
			"sentFolder": "Sent Folder",
			"storeSentEmails": "Store Sent Emails",
			"keepFetchedEmailsUnread": "Keep Fetched Emails Unread",
			"emailFolder": "Put in Folder",
			"useImap": "Fetch Emails",
			"useSmtp": "Use SMTP",
			"smtpHost": "SMTP Host",
			"smtpPort": "SMTP Port",
			"smtpAuth": "SMTP Auth",
			"smtpSecurity": "SMTP Security",
			"smtpAuthMechanism": "SMTP Auth Mechanism",
			"smtpUsername": "SMTP Username",
			"smtpPassword": "SMTP Password"
		},
		"links": {
			"filters": "Filters",
			"emails": "Emails"
		},
		"options": {
			"status": {
				"Active": "Active",
				"Inactive": "Inactive"
			},
			"smtpAuthMechanism": {
				"plain": "PLAIN",
				"login": "LOGIN",
				"crammd5": "CRAM-MD5"
			}
		},
		"labels": {
			"Create EmailAccount": "Create Email Account",
			"IMAP": "IMAP",
			"Main": "Main",
			"Test Connection": "Test Connection",
			"Send Test Email": "Send Test Email",
			"SMTP": "SMTP"
		},
		"messages": {
			"couldNotConnectToImap": "Could not connect to IMAP server",
			"connectionIsOk": "Connection is Ok"
		},
		"tooltips": {
			"useSmtp": "The ability to send emails.",
			"emailAddress": "The user record (assigned user) should have the same email address to be able to use this email account for sending.",
			"monitoredFolders": "Multiple folders should be separated by comma.\n\nYou can add a 'Sent' folder to sync emails sent from an external email client.",
			"storeSentEmails": "Sent emails will be stored on the IMAP server. Email Address field should match the address emails will be sent from."
		}
	},
	"EmailAddress": {
		"labels": {
			"Primary": "Primary",
			"Opted Out": "Opted Out",
			"Invalid": "Invalid"
		},
		"fields": {
			"optOut": "Opted Out",
			"invalid": "Invalid"
		},
		"presetFilters": {
			"orphan": "Orphan"
		}
	},
	"EmailFilter": {
		"fields": {
			"from": "From",
			"to": "To",
			"subject": "Subject",
			"bodyContains": "Body Contains",
			"action": "Action",
			"isGlobal": "Is Global",
			"emailFolder": "Folder"
		},
		"labels": {
			"Create EmailFilter": "Create Email Filter",
			"Emails": "Emails"
		},
		"options": {
			"action": {
				"Skip": "Ignore",
				"Move to Folder": "Put in Folder"
			}
		},
		"tooltips": {
			"name": "Give the filter a descriptive name.",
			"subject": "Use a wildcard *:\n\ntext* - starts with text,\n*text* - contains text,\n*text - ends with text.",
			"bodyContains": "Body of the email contains any of the specified words or phrases.",
			"from": "Emails being sent from the specified address. Leave empty if not needed. You can use wildcard *.",
			"to": "Emails being sent to the specified address. Leave empty if not needed. You can use wildcard *.",
			"isGlobal": "Applies this filter to all emails incoming to system."
		}
	},
	"EmailFolder": {
		"fields": {
			"skipNotifications": "Skip Notifications"
		},
		"labels": {
			"Create EmailFolder": "Create Folder",
			"Manage Folders": "Manage Folders",
			"Emails": "Emails"
		}
	},
	"EmailTemplate": {
		"fields": {
			"name": "Name",
			"status": "Status",
			"isHtml": "Is Html",
			"body": "Body",
			"subject": "Subject",
			"attachments": "Attachments",
			"insertField": "Placeholders",
			"oneOff": "One-off",
			"category": "Category"
		},
		"links": [],
		"labels": {
			"Create EmailTemplate": "Create Email Template",
			"Info": "Info",
			"Available placeholders": "Available placeholders"
		},
		"messages": {
			"infoText": "Available placeholders:\n\n{optOutUrl} – URL for an unsubsbribe link;\n\n{optOutLink} – an unsubscribe link."
		},
		"tooltips": {
			"oneOff": "Check if you are going to use this template only once. E.g. for Mass Email."
		},
		"presetFilters": {
			"actual": "Actual"
		},
		"placeholderTexts": {
			"today": "Today's date",
			"now": "Current date & time",
			"currentYear": "Current Year",
			"optOutUrl": "URL for an unsubsbribe link",
			"optOutLink": "an unsubscribe link"
		}
	},
	"EmailTemplateCategory": {
		"labels": {
			"Create EmailTemplateCategory": "Create Category",
			"Manage Categories": "Manage Categories",
			"EmailTemplates": "Email Templates"
		},
		"fields": {
			"order": "Order",
			"childList": "Child List"
		},
		"links": {
			"emailTemplates": "Email Templates"
		}
	},
	"EntityManager": {
		"labels": {
			"Fields": "Fields",
			"Relationships": "Relationships",
			"Layouts": "Layouts",
			"Schedule": "Schedule",
			"Log": "Log",
			"Formula": "Formula"
		},
		"fields": {
			"name": "Name",
			"type": "Type",
			"labelSingular": "Label Singular",
			"labelPlural": "Label Plural",
			"stream": "Stream",
			"label": "Label",
			"linkType": "Link Type",
			"entityForeign": "Foreign Entity",
			"linkForeign": "Foreign Link",
			"link": "Link",
			"labelForeign": "Foreign Label",
			"sortBy": "Default Order (field)",
			"sortDirection": "Default Order (direction)",
			"relationName": "Middle Table Name",
			"linkMultipleField": "Link Multiple Field",
			"linkMultipleFieldForeign": "Foreign Link Multiple Field",
			"disabled": "Disabled",
			"textFilterFields": "Text Filter Fields",
			"audited": "Audited",
			"auditedForeign": "Foreign Audited",
			"statusField": "Status Field",
			"beforeSaveCustomScript": "Before Save Custom Script",
			"color": "Color",
			"kanbanViewMode": "Kanban View",
			"kanbanStatusIgnoreList": "Ignored groups in Kanban view",
			"iconClass": "Icon",
			"countDisabled": "Disable record count",
			"fullTextSearch": "Full-Text Search",
			"parentEntityTypeList": "Parent Entity Types",
			"foreignLinkEntityTypeList": "Foreign Links"
		},
		"options": {
			"type": {
				"": "None",
				"Base": "Base",
				"Person": "Person",
				"CategoryTree": "Category Tree",
				"Event": "Event",
				"BasePlus": "Base Plus",
				"Company": "Company"
			},
			"linkType": {
				"manyToMany": "Many-to-Many",
				"oneToMany": "One-to-Many",
				"manyToOne": "Many-to-One",
				"oneToOneRight": "One-to-One Right",
				"oneToOneLeft": "One-to-One Left",
				"parentToChildren": "Parent-to-Children",
				"childrenToParent": "Children-to-Parent"
			},
			"sortDirection": {
				"asc": "Ascending",
				"desc": "Descending"
			}
		},
		"messages": {
			"entityCreated": "Entity has been created",
			"linkAlreadyExists": "Link name conflict.",
			"linkConflict": "Name conflict: link or field with the same name already exists."
		},
		"tooltips": {
			"statusField": "Updates of this field are logged in stream.",
			"textFilterFields": "Fields used by text search.",
			"stream": "Whether entity has a Stream.",
			"disabled": "Check if you don't need this entity in your system.",
			"linkAudited": "Creating related record and linking with existing record will be logged in Stream.",
			"linkMultipleField": "Link Multiple field provides a handy way to edit relations. Don't use it if you can have a large number of related records.",
			"entityType": "Base Plus - has Activities, History and Tasks panels.\n\nEvent - available in Calendar and Activities panel.",
			"countDisabled": "Total number won't be displayed on the list view. Can decrease loading time when the DB table is big.",
			"fullTextSearch": "Running rebuild is required."
		}
	},
	"Export": {
		"fields": {
			"exportAllFields": "Export all fields",
			"fieldList": "Field List",
			"format": "Format"
		},
		"options": {
			"format": {
				"csv": "CSV",
				"xlsx": "XLSX (Excel)"
			}
		}
	},
	"Extension": {
		"fields": {
			"name": "Name",
			"version": "Version",
			"description": "Description",
			"isInstalled": "Installed",
			"checkVersionUrl": "An URL for checking new versions"
		},
		"labels": {
			"Uninstall": "Uninstall",
			"Install": "Install"
		},
		"messages": {
			"uninstalled": "Extension {name} has been uninstalled"
		}
	},
	"ExternalAccount": {
		"labels": {
			"Connect": "Connect",
			"Disconnect": "Disconnect",
			"Disconnected": "Disconnected",
			"Connected": "Connected"
		},
		"help": []
	},
	"FieldManager": {
		"labels": {
			"Dynamic Logic": "Dynamic Logic",
			"Name": "Name",
			"Label": "Label",
			"Type": "Type"
		},
		"options": {
			"dateTimeDefault": {
				"": "None",
				"javascript: return this.dateTime.getNow(1);": "Now",
				"javascript: return this.dateTime.getNow(5);": "Now (5m)",
				"javascript: return this.dateTime.getNow(15);": "Now (15m)",
				"javascript: return this.dateTime.getNow(30);": "Now (30m)",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(1, 'hours', 15);": "+1 hour",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(2, 'hours', 15);": "+2 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(3, 'hours', 15);": "+3 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(4, 'hours', 15);": "+4 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(5, 'hours', 15);": "+5 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(6, 'hours', 15);": "+6 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(7, 'hours', 15);": "+7 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(8, 'hours', 15);": "+8 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(9, 'hours', 15);": "+9 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(10, 'hours', 15);": "+10 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(11, 'hours', 15);": "+11 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(12, 'hours', 15);": "+12 hours",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(1, 'days', 15);": "+1 day",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(2, 'days', 15);": "+2 days",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(3, 'days', 15);": "+3 days",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(4, 'days', 15);": "+4 days",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(5, 'days', 15);": "+5 days",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(6, 'days', 15);": "+6 days",
				"javascript: return this.dateTime.getDateTimeShiftedFromNow(1, 'week', 15);": "+1 week"
			},
			"dateDefault": {
				"": "None",
				"javascript: return this.dateTime.getToday();": "Today",
				"javascript: return this.dateTime.getDateShiftedFromToday(1, 'days');": "+1 day",
				"javascript: return this.dateTime.getDateShiftedFromToday(2, 'days');": "+2 days",
				"javascript: return this.dateTime.getDateShiftedFromToday(3, 'days');": "+3 days",
				"javascript: return this.dateTime.getDateShiftedFromToday(4, 'days');": "+4 days",
				"javascript: return this.dateTime.getDateShiftedFromToday(5, 'days');": "+5 days",
				"javascript: return this.dateTime.getDateShiftedFromToday(6, 'days');": "+6 days",
				"javascript: return this.dateTime.getDateShiftedFromToday(7, 'days');": "+7 days",
				"javascript: return this.dateTime.getDateShiftedFromToday(8, 'days');": "+8 days",
				"javascript: return this.dateTime.getDateShiftedFromToday(9, 'days');": "+9 days",
				"javascript: return this.dateTime.getDateShiftedFromToday(10, 'days');": "+10 days",
				"javascript: return this.dateTime.getDateShiftedFromToday(1, 'weeks');": "+1 week",
				"javascript: return this.dateTime.getDateShiftedFromToday(2, 'weeks');": "+2 weeks",
				"javascript: return this.dateTime.getDateShiftedFromToday(3, 'weeks');": "+3 weeks",
				"javascript: return this.dateTime.getDateShiftedFromToday(1, 'months');": "+1 month",
				"javascript: return this.dateTime.getDateShiftedFromToday(2, 'months');": "+2 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(3, 'months');": "+3 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(4, 'months');": "+4 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(5, 'months');": "+5 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(6, 'months');": "+6 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(7, 'months');": "+7 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(8, 'months');": "+8 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(9, 'months');": "+9 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(10, 'months');": "+10 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(11, 'months');": "+11 months",
				"javascript: return this.dateTime.getDateShiftedFromToday(1, 'year');": "+1 year"
			},
			"barcodeType": {
				"EAN13": "EAN-13",
				"EAN8": "EAN-8",
				"EAN5": "EAN-5",
				"EAN2": "EAN-2",
				"UPC": "UPC (A)",
				"UPCE": "UPC (E)",
				"pharmacode": "Pharmacode",
				"QRcode": "QR code"
			}
		},
		"tooltips": {
			"audited": "Updates will be logged in stream.",
			"required": "Field will be mandatory. Can't be left empty.",
			"default": "Value will be set by default upon creating.",
			"min": "Min acceptable value.",
			"max": "Max acceptable value.",
			"seeMoreDisabled": "If not checked then long texts will be shortened.",
			"lengthOfCut": "How long text can be before it will be cut.",
			"maxLength": "Max acceptable length of text.",
			"before": "The date value should be before the date value of the specified field.",
			"after": "The date value should be after the date value of the specified field.",
			"readOnly": "Field value can't be specified by user. But can be calculated by formula.",
			"fileAccept": "Which file types to accept. It's possible to add custom items.",
			"barcodeLastChar": "For EAN-13 type.",
			"maxFileSize": "If empty or 0 then no limit."
		},
		"fieldParts": {
			"address": {
				"street": "Street",
				"city": "City",
				"state": "State",
				"country": "Country",
				"postalCode": "Postal Code",
				"map": "Map"
			},
			"personName": {
				"salutation": "Salutation",
				"first": "First",
				"middle": "Middle",
				"last": "Last"
			},
			"currency": {
				"converted": "(Converted)",
				"currency": "(Currency)"
			},
			"datetimeOptional": {
				"date": "Date"
			}
		},
		"fieldInfo": {
			"varchar": "A single-line text.",
			"enum": "Selectbox, only one value can be selected.",
			"text": "A multiline text with markdown support.",
			"date": "Date w\/o time.",
			"datetime": "Date and time",
			"currency": "A currency value. A float number with a currency code.",
			"int": "A whole number.",
			"float": "A number with a decimal part.",
			"bool": "A checkbox. Two possible values: true and false.",
			"multiEnum": "A list of values, multiple values can be selected. The list is ordered.",
			"checklist": "A list of checkboxes.",
			"array": "A list of values, similar to Multi-Enum field.",
			"address": "An address with street, city, state, postal code and country.",
			"url": "For storing links.",
			"wysiwyg": "A text with HTML support.",
			"file": "For file uploading.",
			"image": "For image uploading.",
			"attachmentMultiple": "Allows to upload multiple files.",
			"number": "An auto-incrementing number of string type with a possible prefix and specific length.",
			"autoincrement": "A generated read-only auto-incrementing integer number.",
			"barcode": "A barcode. Can be printed to PDF.",
			"email": "A set of email addresses with their parameters: Opted-out, Invalid, Primary.",
			"phone": "A set of phone numbers with their parameters: Type, Opted-out, Invalid, Primary.",
			"foreign": "A field of a related record. Read-only.",
			"link": "A record related through Belongs-To (many-to-one or one-to-one) relationship.",
			"linkParent": "A record related through Belongs-To-Parent relationship. Can be of different entity types.",
			"linkMultiple": "A set of records related through Has-Many (many-to-many or one-to-many) relationship. Not all relatioships have their link-multiple fields. Only those do, where Link-Multiple parameter(s) is enabled."
		}
	},
	"Global": {
		"scopeNames": {
			"Email": "Email",
			"User": "User",
			"Team": "Team",
			"Role": "Role",
			"EmailTemplate": "Email Template",
			"EmailTemplateCategory": "Email Template Categories",
			"EmailAccount": "Personal Email Account",
			"EmailAccountScope": "Personal Email Account",
			"OutboundEmail": "Outbound Email",
			"ScheduledJob": "Scheduled Job",
			"ExternalAccount": "External Account",
			"Extension": "Extension",
			"Dashboard": "Dashboard",
			"InboundEmail": "Group Email Account",
			"Stream": "Stream",
			"Import": "Import",
			"Template": "Template",
			"Job": "Job",
			"EmailFilter": "Email Filter",
			"Portal": "Portal",
			"PortalRole": "Portal Role",
			"Attachment": "Attachment",
			"EmailFolder": "Email Folder",
			"PortalUser": "Portal User",
			"ApiUser": "API User",
			"ScheduledJobLogRecord": "Scheduled Job Log Record",
			"PasswordChangeRequest": "Password Change Request",
			"ActionHistoryRecord": "Action History Record",
			"AuthToken": "Auth Token",
			"UniqueId": "Unique ID",
			"LastViewed": "Last Viewed",
			"Settings": "Settings",
			"FieldManager": "Field Manager",
			"Integration": "Integration",
			"LayoutManager": "Layout Manager",
			"EntityManager": "Entity Manager",
			"Export": "Export",
			"DynamicLogic": "Dynamic Logic",
			"DashletOptions": "Dashlet Options",
			"Admin": "Admin",
			"Global": "Global",
			"Preferences": "Preferences",
			"EmailAddress": "Email Address",
			"PhoneNumber": "Phone Number",
			"AuthLogRecord": "Auth Log Record",
			"AuthFailLogRecord": "Auth Fail Log Record",
			"LeadCapture": "Lead Capture Entry Point",
			"LeadCaptureLogRecord": "Lead Capture Log Record",
			"ArrayValue": "Array Value",
			"DashboardTemplate": "Dashboard Template",
			"Currency": "Currency",
			"LayoutSet": "Layout Set",
			"Webhook": "Webhook",
			"Account": "Account",
			"Contact": "Customer",
			"Lead": "Lead",
			"Target": "Target",
			"Opportunity": "Opportunity",
			"Meeting": "Meeting",
			"Calendar": "Calendar",
			"Call": "Reminder",
			"Task": "Task",
			"Case": "Ticket",
			"Document": "Document",
			"DocumentFolder": "Document Folder",
			"Campaign": "Campaign",
			"TargetList": "Target List",
			"MassEmail": "Mass Email",
			"EmailQueueItem": "Email Queue Item",
			"CampaignTrackingUrl": "Tracking URL",
			"Activities": "Activities",
			"KnowledgeBaseArticle": "Knowledge Base Article",
			"KnowledgeBaseCategory": "Knowledge Base Category",
			"CampaignLogRecord": "Campaign Log Record",
			"Workflow": "Workflow",
			"Report": "Report",
			"ReportCategory": "Report Category",
			"WorkflowLogRecord": "Workflow Log Record",
			"BpmnFlowchart": "Process Flowchart",
			"BpmnProcess": "Process",
			"BpmnUserTask": "Process User Task",
			"ReportFilter": "Report Filter",
			"ReportPanel": "Report Panel",
			"PhoneCall": "PhoneCall",
			"SMS": "Message",
			"NotificationPush": "Notification Push",
			"QuizQuestion": "Quiz Question",
			"Screenshot": "Screenshot",
			"Group": "Group",
			"CannedMessage": "Canned Message",
			"Chat": "Chat"
		},
		"scopeNamesPlural": {
			"Email": "Emails",
			"User": "Users",
			"Team": "Teams",
			"Role": "Roles",
			"EmailTemplate": "Email Templates",
			"EmailTemplateCategory": "Email Template Categories",
			"EmailAccount": "Personal Email Accounts",
			"EmailAccountScope": "Personal Email Accounts",
			"OutboundEmail": "Outbound Emails",
			"ScheduledJob": "Scheduled Jobs",
			"ExternalAccount": "External Accounts",
			"Extension": "Extensions",
			"Dashboard": "Dashboard",
			"InboundEmail": "Group Email Accounts",
			"EmailAddress": "Email Addresses",
			"PhoneNumber": "Phone Numbers",
			"Stream": "Stream",
			"Import": "Import",
			"Template": "Templates",
			"Job": "Jobs",
			"EmailFilter": "Email Filters",
			"Portal": "Portals",
			"PortalRole": "Portal Roles",
			"Attachment": "Attachments",
			"EmailFolder": "Email Folders",
			"PortalUser": "Portal Users",
			"ApiUser": "API Users",
			"ScheduledJobLogRecord": "Scheduled Job Log Records",
			"PasswordChangeRequest": "Password Change Requests",
			"ActionHistoryRecord": "Action History",
			"AuthToken": "Auth Tokens",
			"UniqueId": "Unique IDs",
			"LastViewed": "Last Viewed",
			"AuthLogRecord": "Auth Log",
			"AuthFailLogRecord": "Auth Fail Log",
			"LeadCapture": "Lead Capture",
			"LeadCaptureLogRecord": "Lead Capture Log",
			"ArrayValue": "Array Values",
			"DashboardTemplate": "Dashboard Templates",
			"Currency": "Currency",
			"LayoutSet": "Layout Sets",
			"Webhook": "Webhooks",
			"Account": "Accounts",
			"Contact": "Customers",
			"Lead": "Leads",
			"Target": "Targets",
			"Opportunity": "Opportunities",
			"Meeting": "Meetings",
			"Calendar": "Calendar",
			"Call": "Reminders",
			"Task": "Tasks",
			"Case": "Tickets",
			"Document": "Documents",
			"DocumentFolder": "Document Folders",
			"Campaign": "Campaigns",
			"TargetList": "Target Lists",
			"MassEmail": "Mass Emails",
			"EmailQueueItem": "Email Queue Items",
			"CampaignTrackingUrl": "Tracking URLs",
			"Activities": "Activities",
			"KnowledgeBaseArticle": "Knowledge Base",
			"KnowledgeBaseCategory": "Knowledge Base Categories",
			"CampaignLogRecord": "Campaign Log Records",
			"Workflow": "Workflows",
			"Report": "Reports",
			"ReportCategory": "Report Categories",
			"WorkflowLogRecord": "Workflows Log",
			"BpmnFlowchart": "Process Flowcharts",
			"BpmnProcess": "Processes",
			"BpmnUserTask": "Process User Tasks",
			"ReportFilter": "Report Filters",
			"ReportPanel": "Report Panels",
			"PhoneCall": "PhoneCalls",
			"SMS": "Messages",
			"NotificationPush": "NotificationPushs",
			"QuizQuestion": "Quiz Questions",
			"Screenshot": "Screenshots",
			"Group": "Groups",
			"CannedMessage": "Canned Messages",
			"Chat": "Chats"
		},
		"labels": {
			"Misc": "Misc",
			"Merge": "Merge",
			"None": "None",
			"Home": "Home",
			"by": "by",
			"Proceed": "Proceed",
			"Saved": "Saved",
			"Error": "Error",
			"Select": "Select",
			"Not valid": "Not valid",
			"Please wait...": "Please wait...",
			"Please wait": "Please wait",
			"Attached": "Attached",
			"Loading...": "Loading...",
			"Uploading...": "Uploading...",
			"Sending...": "Sending...",
			"Merging...": "Merging...",
			"Merged": "Merged",
			"Removed": "Removed",
			"Posted": "Posted",
			"Linked": "Linked",
			"Unlinked": "Unlinked",
			"Done": "Done",
			"Access denied": "Access denied",
			"Not found": "Not found",
			"Access": "Access",
			"Are you sure?": "Are you sure?",
			"Record has been removed": "Record has been removed",
			"Wrong username\/password": "Wrong username\/password",
			"Post cannot be empty": "Post cannot be empty",
			"Removing...": "Removing...",
			"Unlinking...": "Unlinking...",
			"Posting...": "Posting...",
			"Username can not be empty!": "Username can not be empty!",
			"Cache is not enabled": "Cache is not enabled",
			"Cache has been cleared": "Cache has been cleared",
			"Rebuild has been done": "Rebuild has been done",
			"Return to Application": "Return to Application",
			"Saving...": "Saving...",
			"Modified": "Modified",
			"Created": "Created",
			"Create": "Create",
			"create": "create",
			"Overview": "Overview",
			"Details": "Details",
			"Add Field": "Add Field",
			"Add Dashlet": "Add Dashlet",
			"Filter": "Filter",
			"Edit Dashboard": "Edit Dashboard",
			"Add": "Add",
			"Add Item": "Add Item",
			"Reset": "Reset",
			"Menu": "Menu",
			"More": "More",
			"Search": "Search",
			"Only My": "Only My",
			"Open": "Open",
			"Admin": "Admin",
			"About": "About",
			"Refresh": "Refresh",
			"Remove": "Remove",
			"Restore": "Restore",
			"Options": "Options",
			"Username": "Username",
			"Password": "Password",
			"Login": "Login",
			"Log Out": "Log Out",
			"Preferences": "Preferences",
			"State": "State",
			"Street": "Street",
			"Country": "Country",
			"City": "City",
			"PostalCode": "Postal Code",
			"Followed": "Followed",
			"Follow": "Follow",
			"Followers": "Followers",
			"Clear Local Cache": "Clear Local Cache",
			"Actions": "Actions",
			"Delete": "Delete",
			"Update": "Update",
			"Save": "Save",
			"Edit": "Edit",
			"View": "View",
			"Cancel": "Cancel",
			"Apply": "Apply",
			"Unlink": "Unlink",
			"Mass Update": "Mass Update",
			"Export": "Export",
			"No Data": "No Data",
			"No Access": "No Access",
			"All": "All",
			"Active": "Active",
			"Inactive": "Inactive",
			"Write your comment here": "Write your comment here",
			"Post": "Post",
			"Stream": "Stream",
			"Show more": "Show more",
			"Dashlet Options": "Dashlet Options",
			"Full Form": "Full Form",
			"Insert": "Insert",
			"Person": "Person",
			"First Name": "First Name",
			"Last Name": "Last Name",
			"Middle Name": "Middle Name",
			"Original": "Original",
			"You": "You",
			"you": "you",
			"change": "change",
			"Change": "Change",
			"Primary": "Primary",
			"Save Filter": "Save Filter",
			"Administration": "Administration",
			"Run Import": "Run Import",
			"Duplicate": "Duplicate",
			"Notifications": "Notifications",
			"Mark all read": "Mark all read",
			"See more": "See more",
			"Today": "Today",
			"Tomorrow": "Tomorrow",
			"Yesterday": "Yesterday",
			"Submit": "Submit",
			"Close": "Close",
			"Yes": "Yes",
			"No": "No",
			"Select All Results": "Select All Results",
			"Value": "Value",
			"Current version": "Current version",
			"List View": "List View",
			"Tree View": "Tree View",
			"Unlink All": "Unlink All",
			"Total": "Total",
			"Print to PDF": "Print to PDF",
			"Default": "Default",
			"Number": "Number",
			"From": "From",
			"To": "To",
			"Create Post": "Create Post",
			"Previous Entry": "Previous Entry",
			"Next Entry": "Next Entry",
			"View List": "View List",
			"Attach File": "Attach File",
			"Skip": "Skip",
			"Attribute": "Attribute",
			"Function": "Function",
			"Self-Assign": "Self-Assign",
			"Self-Assigned": "Self-Assigned",
			"Expand": "Expand",
			"Collapse": "Collapse",
			"New notifications": "New notifications",
			"Manage Categories": "Manage Categories",
			"Manage Folders": "Manage Folders",
			"Convert to": "Convert to",
			"View Personal Data": "View Personal Data",
			"Personal Data": "Personal Data",
			"Erase": "Erase",
			"View Followers": "View Followers",
			"Convert Currency": "Convert Currency",
			"View on Map": "View on Map",
			"Preview": "Preview",
			"Move Over": "Move Over",
			"Scheduler": "Scheduler",
			"Create InboundEmail": "Create Inbound Email",
			"Activities": "Activities",
			"History": "History",
			"Attendees": "Attendees",
			"Schedule Meeting": "Schedule Meeting",
			"Schedule Call": "Schedule Call",
			"Compose Email": "Compose Email",
			"Log Meeting": "Log Meeting",
			"Log Call": "Log Call",
			"Archive Email": "Archive Email",
			"Create Task": "Create Task",
			"Tasks": "Tasks",
			"Start Process": "Start Process"
		},
		"messages": {
			"pleaseWait": "Please wait...",
			"posting": "Posting...",
			"loading": "Loading...",
			"saving": "Saving...",
			"confirmLeaveOutMessage": "Are you sure you want to leave the form?",
			"notModified": "You have not modified the record",
			"duplicate": "The record you are creating might already exist",
			"dropToAttach": "Drop to attach",
			"fieldIsRequired": "{field} is required",
			"fieldShouldBeEmail": "{field} should be a valid email",
			"fieldShouldBeFloat": "{field} should be a valid float",
			"fieldShouldBeInt": "{field} should be a valid integer",
			"fieldShouldBeDate": "{field} should be a valid date",
			"fieldShouldBeDatetime": "{field} should be a valid date\/time",
			"fieldShouldAfter": "{field} should be after {otherField}",
			"fieldShouldBefore": "{field} should be before {otherField}",
			"fieldShouldBeBetween": "{field} should be between {min} and {max}",
			"fieldShouldBeLess": "{field} shouldn't be greater then {value}",
			"fieldShouldBeGreater": "{field} shouldn't be less then {value}",
			"fieldBadPasswordConfirm": "{field} not confirmed properly",
			"fieldMaxFileSizeError": "File should not exceed {max} Mb",
			"fieldValueDuplicate": "Duplicate value",
			"fieldIsUploading": "Uploading in progress",
			"fieldExceedsMaxCount": "Count exceeds max allowed {maxCount}",
			"resetPreferencesDone": "Preferences has been reset to defaults",
			"confirmation": "Are you sure?",
			"unlinkAllConfirmation": "Are you sure you want to unlink all related records?",
			"resetPreferencesConfirmation": "Are you sure you want to reset preferences to defaults?",
			"removeRecordConfirmation": "Are you sure you want to remove the record?",
			"unlinkRecordConfirmation": "Are you sure you want to unlink the related record?",
			"removeSelectedRecordsConfirmation": "Are you sure you want to remove selected records?",
			"unlinkSelectedRecordsConfirmation": "Are you sure you want to unlink selected records?",
			"massUpdateResult": "{count} records have been updated",
			"massUpdateResultSingle": "{count} record has been updated",
			"recalculateFormulaConfirmation": "Are you sure you want to recalculate formula for selected records?",
			"noRecordsUpdated": "No records were updated",
			"massRemoveResult": "{count} records have been removed",
			"massRemoveResultSingle": "{count} record has been removed",
			"noRecordsRemoved": "No records were removed",
			"clickToRefresh": "Click to refresh",
			"writeYourCommentHere": "Write your comment here",
			"writeMessageToUser": "Write a message to {user}",
			"writeMessageToSelf": "Write a message on your stream",
			"typeAndPressEnter": "Type & press enter",
			"checkForNewNotifications": "Check for new notifications",
			"checkForNewNotes": "Check for stream updates",
			"internalPost": "Post will be seen only by internal users",
			"internalPostTitle": "Post is seen only by internal users",
			"done": "Done",
			"notUpdated": "Not updated",
			"confirmMassFollow": "Are you sure you want to follow selected records?",
			"confirmMassUnfollow": "Are you sure you want to unfollow selected records?",
			"massFollowResult": "{count} records now are followed",
			"massUnfollowResult": "{count} records now are not followed",
			"massFollowResultSingle": "{count} record now is followed",
			"massUnfollowResultSingle": "{count} record now is not followed",
			"massFollowZeroResult": "Nothing got followed",
			"massUnfollowZeroResult": "Nothing got unfollowed",
			"erasePersonalDataConfirmation": "Checked fields will be erased permanently. Are you sure?",
			"maintenanceMode": "The application currently is in maintenance mode. Only admin users have access.\n\nMaintenance mode can be disabled at Administration \u2192 Settings.",
			"massPrintPdfMaxCountError": "Can't print more that {maxCount} records."
		},
		"boolFilters": {
			"onlyMy": "Only My",
			"onlyMyTeam": "My Team",
			"followed": "Followed"
		},
		"presetFilters": {
			"followed": "Followed",
			"all": "All"
		},
		"massActions": {
			"remove": "Remove",
			"merge": "Merge",
			"massUpdate": "Mass Update",
			"unlink": "Unlink",
			"export": "Export",
			"follow": "Follow",
			"unfollow": "Unfollow",
			"convertCurrency": "Convert Currency",
			"recalculateFormula": "Recalculate Formula",
			"printPdf": "Print to PDF"
		},
		"fields": {
			"name": "Name",
			"firstName": "First Name",
			"lastName": "Last Name",
			"middleName": "Middle Name",
			"salutationName": "Salutation",
			"assignedUser": "Assigned User",
			"assignedUsers": "Assigned Users",
			"emailAddress": "Email",
			"emailAddressData": "Email Address Data",
			"emailAddressIsOptedOut": "Email Address is Opted-Out",
			"assignedUserName": "Assigned User Name",
			"teams": "Teams",
			"createdAt": "Created At",
			"modifiedAt": "Modified At",
			"createdBy": "Created By",
			"modifiedBy": "Modified By",
			"description": "Description",
			"address": "Address",
			"phoneNumber": "Phone",
			"phoneNumberMobile": "Phone (Mobile)",
			"phoneNumberHome": "Phone (Home)",
			"phoneNumberFax": "Phone (Fax)",
			"phoneNumberOffice": "Phone (Office)",
			"phoneNumberOther": "Phone (Other)",
			"phoneNumberData": "Phone Number Data",
			"phoneNumberIsOptedOut": "Phone Number is Opted-Out",
			"order": "Order",
			"parent": "Parent",
			"children": "Children",
			"id": "ID",
			"ids": "IDs",
			"type": "Type",
			"names": "Names",
			"types": "Types",
			"targetListIsOptedOut": "Is Opted Out (Target List)",
			"billingAddressCity": "City",
			"billingAddressCountry": "Country",
			"billingAddressPostalCode": "Postal Code",
			"billingAddressState": "State",
			"billingAddressStreet": "Street",
			"billingAddressMap": "Map",
			"addressCity": "City",
			"addressStreet": "Street",
			"addressCountry": "Country",
			"addressState": "State",
			"addressPostalCode": "Postal Code",
			"addressMap": "Map",
			"shippingAddressCity": "City (Shipping)",
			"shippingAddressStreet": "Street (Shipping)",
			"shippingAddressCountry": "Country (Shipping)",
			"shippingAddressState": "State (Shipping)",
			"shippingAddressPostalCode": "Postal Code (Shipping)",
			"shippingAddressMap": "Map (Shipping)"
		},
		"links": {
			"assignedUser": "Assigned User",
			"createdBy": "Created By",
			"modifiedBy": "Modified By",
			"team": "Team",
			"roles": "Roles",
			"teams": "Teams",
			"users": "Users",
			"parent": "Parent",
			"children": "Children",
			"contacts": "Contacts",
			"opportunities": "Opportunities",
			"leads": "Leads",
			"meetings": "Meetings",
			"calls": "Calls",
			"tasks": "Tasks",
			"emails": "Emails",
			"accounts": "Accounts",
			"cases": "Cases",
			"documents": "Documents",
			"account": "Account",
			"opportunity": "Opportunity",
			"contact": "Contact"
		},
		"dashlets": {
			"Stream": "Stream",
			"Emails": "My Inbox",
			"Iframe": "Iframe",
			"Records": "Record List",
			"Leads": "My Leads",
			"Opportunities": "My Opportunities",
			"Tasks": "My Tasks",
			"Cases": "My Cases",
			"Calendar": "Calendar",
			"Calls": "My Calls",
			"Meetings": "My Meetings",
			"OpportunitiesByStage": "Opportunities by Stage",
			"OpportunitiesByLeadSource": "Opportunities by Lead Source",
			"SalesByMonth": "Sales by Month",
			"SalesPipeline": "Sales Pipeline",
			"Activities": "My Activities",
			"Report": "Report",
			"BpmnUserTasks": "Process User Tasks"
		},
		"notificationMessages": {
			"assign": "{entityType} {entity} has been assigned to you",
			"emailReceived": "Email received from {from}",
			"entityRemoved": "{user} removed {entityType} {entity}"
		},
		"streamMessages": {
			"post": "{user} posted on {entityType} {entity}",
			"attach": "{user} attached on {entityType} {entity}",
			"status": "{user} updated {field} of {entityType} {entity}",
			"update": "{user} updated {entityType} {entity}",
			"postTargetTeam": "{user} posted to team {target}",
			"postTargetTeams": "{user} posted to teams {target}",
			"postTargetPortal": "{user} posted to portal {target}",
			"postTargetPortals": "{user} posted to portals {target}",
			"postTarget": "{user} posted to {target}",
			"postTargetYou": "{user} posted to you",
			"postTargetYouAndOthers": "{user} posted to {target} and you",
			"postTargetAll": "{user} posted to all",
			"postTargetSelf": "{user} self-posted",
			"postTargetSelfAndOthers": "{user} posted to {target} and themself",
			"mentionInPost": "{user} mentioned {mentioned} in {entityType} {entity}",
			"mentionYouInPost": "{user} mentioned you in {entityType} {entity}",
			"mentionInPostTarget": "{user} mentioned {mentioned} in post",
			"mentionYouInPostTarget": "{user} mentioned you in post to {target}",
			"mentionYouInPostTargetAll": "{user} mentioned you in post to all",
			"mentionYouInPostTargetNoTarget": "{user} mentioned you in post",
			"create": "{user} created {entityType} {entity}",
			"createThis": "{user} created this {entityType}",
			"createAssignedThis": "{user} created this {entityType} assigned to {assignee}",
			"createAssigned": "{user} created {entityType} {entity} assigned to {assignee}",
			"createAssignedYou": "{user} created {entityType} {entity} assigned to you",
			"createAssignedThisSelf": "{user} created this {entityType} self-assigned",
			"createAssignedSelf": "{user} created {entityType} {entity} self-assigned",
			"assign": "{user} assigned {entityType} {entity} to {assignee}",
			"assignThis": "{user} assigned this {entityType} to {assignee}",
			"assignYou": "{user} assigned {entityType} {entity} to you",
			"assignThisVoid": "{user} unassigned this {entityType}",
			"assignVoid": "{user} unassigned {entityType} {entity}",
			"assignThisSelf": "{user} self-assigned this {entityType}",
			"assignSelf": "{user} self-assigned {entityType} {entity}",
			"postThis": "{user} posted",
			"attachThis": "{user} attached",
			"statusThis": "{user} updated {field}",
			"updateThis": "{user} updated this {entityType}",
			"createRelatedThis": "{user} created {relatedEntityType} {relatedEntity} related to this {entityType}",
			"createRelated": "{user} created {relatedEntityType} {relatedEntity} related to {entityType} {entity}",
			"relate": "{user} linked {relatedEntityType} {relatedEntity} with {entityType} {entity}",
			"relateThis": "{user} linked {relatedEntityType} {relatedEntity} with this {entityType}",
			"emailReceivedFromThis": "Email received from {from}",
			"emailReceivedInitialFromThis": "Email received from {from}, this {entityType} created",
			"emailReceivedThis": "Email received",
			"emailReceivedInitialThis": "Email received, this {entityType} created",
			"emailReceivedFrom": "Email received from {from}, related to {entityType} {entity}",
			"emailReceivedFromInitial": "Email received from {from}, {entityType} {entity} created",
			"emailReceived": "Email received related to {entityType} {entity}",
			"emailReceivedInitial": "Email received: {entityType} {entity} created",
			"emailReceivedInitialFrom": "Email received from {from}, {entityType} {entity} created",
			"emailSent": "{by} sent email related to {entityType} {entity}",
			"emailSentThis": "{by} sent email"
		},
		"streamMessagesMale": {
			"postTargetSelfAndOthers": "{user} posted to {target} and himself"
		},
		"streamMessagesFemale": {
			"postTargetSelfAndOthers": "{user} posted to {target} and herself"
		},
		"lists": {
			"monthNames": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			"monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
			"dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
			"dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
			"dayNamesMin": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
		},
		"durationUnits": {
			"d": "d",
			"h": "h",
			"m": "m",
			"s": "s"
		},
		"options": {
			"salutationName": {
				"Mr.": "Mr.",
				"Mrs.": "Mrs.",
				"Ms.": "Ms.",
				"Dr.": "Dr."
			},
			"language": {
				"af_ZA": "Afrikaans",
				"az_AZ": "Azerbaijani",
				"be_BY": "Belarusian",
				"bg_BG": "Bulgarian",
				"bn_IN": "Bengali",
				"bs_BA": "Bosnian",
				"ca_ES": "Catalan",
				"cs_CZ": "Czech",
				"cy_GB": "Welsh",
				"da_DK": "Danish",
				"de_DE": "German",
				"el_GR": "Greek",
				"en_GB": "English (UK)",
				"es_MX": "Spanish (Mexico)",
				"en_US": "English (US)",
				"es_ES": "Spanish (Spain)",
				"et_EE": "Estonian",
				"eu_ES": "Basque",
				"fa_IR": "Persian",
				"fi_FI": "Finnish",
				"fo_FO": "Faroese",
				"fr_CA": "French (Canada)",
				"fr_FR": "French (France)",
				"ga_IE": "Irish",
				"gl_ES": "Galician",
				"gn_PY": "Guarani",
				"he_IL": "Hebrew",
				"hi_IN": "Hindi",
				"hr_HR": "Croatian",
				"hu_HU": "Hungarian",
				"hy_AM": "Armenian",
				"id_ID": "Indonesian",
				"is_IS": "Icelandic",
				"it_IT": "Italian",
				"ja_JP": "Japanese",
				"ka_GE": "Georgian",
				"km_KH": "Khmer",
				"ko_KR": "Korean",
				"ku_TR": "Kurdish",
				"lt_LT": "Lithuanian",
				"lv_LV": "Latvian",
				"mk_MK": "Macedonian",
				"ml_IN": "Malayalam",
				"ms_MY": "Malay",
				"nb_NO": "Norwegian Bokm\u00e5l",
				"nn_NO": "Norwegian Nynorsk",
				"ne_NP": "Nepali",
				"nl_NL": "Dutch",
				"pa_IN": "Punjabi",
				"pl_PL": "Polish",
				"ps_AF": "Pashto",
				"pt_BR": "Portuguese (Brazil)",
				"pt_PT": "Portuguese (Portugal)",
				"ro_RO": "Romanian",
				"ru_RU": "Russian",
				"sk_SK": "Slovak",
				"sl_SI": "Slovene",
				"sq_AL": "Albanian",
				"sr_RS": "Serbian",
				"sv_SE": "Swedish",
				"sw_KE": "Swahili",
				"ta_IN": "Tamil",
				"te_IN": "Telugu",
				"th_TH": "Thai",
				"tl_PH": "Tagalog",
				"tr_TR": "Turkish",
				"uk_UA": "Ukrainian",
				"ur_PK": "Urdu",
				"vi_VN": "Vietnamese",
				"zh_CN": "Simplified Chinese (China)",
				"zh_HK": "Traditional Chinese (Hong Kong)",
				"zh_TW": "Traditional Chinese (Taiwan)"
			},
			"dateSearchRanges": {
				"on": "On",
				"notOn": "Not On",
				"after": "After",
				"before": "Before",
				"between": "Between",
				"today": "Today",
				"past": "Past",
				"future": "Future",
				"currentMonth": "Current Month",
				"lastMonth": "Last Month",
				"nextMonth": "Next Month",
				"currentQuarter": "Current Quarter",
				"lastQuarter": "Last Quarter",
				"currentYear": "Current Year",
				"lastYear": "Last Year",
				"lastSevenDays": "Last 7 Days",
				"lastXDays": "Last X Days",
				"nextXDays": "Next X Days",
				"ever": "Ever",
				"isEmpty": "Is Empty",
				"olderThanXDays": "Older Than X Days",
				"afterXDays": "After X Days",
				"currentFiscalYear": "Current Fiscal Year",
				"lastFiscalYear": "Last Fiscal Year",
				"currentFiscalQuarter": "Current Fiscal Quarter",
				"lastFiscalQuarter": "Last Fiscal Quarter"
			},
			"searchRanges": {
				"is": "Is",
				"isEmpty": "Is Empty",
				"isNotEmpty": "Is Not Empty",
				"isOneOf": "Any Of",
				"isFromTeams": "Is From Team",
				"isNot": "Is Not",
				"isNotOneOf": "None Of",
				"anyOf": "Any Of",
				"allOf": "All Of",
				"noneOf": "None Of",
				"any": "Any"
			},
			"varcharSearchRanges": {
				"equals": "Equals",
				"like": "Is Like (%)",
				"notLike": "Is Not Like (%)",
				"startsWith": "Starts With",
				"endsWith": "Ends With",
				"contains": "Contains",
				"notContains": "Not Contains",
				"isEmpty": "Is Empty",
				"isNotEmpty": "Is Not Empty",
				"notEquals": "Not Equals"
			},
			"intSearchRanges": {
				"equals": "Equals",
				"notEquals": "Not Equals",
				"greaterThan": "Greater Than",
				"lessThan": "Less Than",
				"greaterThanOrEquals": "Greater Than or Equals",
				"lessThanOrEquals": "Less Than or Equals",
				"between": "Between",
				"isEmpty": "Is Empty",
				"isNotEmpty": "Is Not Empty"
			},
			"autorefreshInterval": {
				"0": "None",
				"0.5": "30 seconds",
				"1": "1 minute",
				"2": "2 minutes",
				"5": "5 minutes",
				"10": "10 minutes"
			},
			"phoneNumber": {
				"Mobile": "Mobile",
				"Office": "Office",
				"Fax": "Fax",
				"Home": "Home",
				"Other": "Other"
			},
			"reminderTypes": {
				"Popup": "Popup",
				"Email": "Email"
			}
		},
		"sets": {
			"summernote": {
				"NOTICE": "You can find translation here: https:\/\/github.com\/HackerWins\/summernote\/tree\/master\/lang",
				"font": {
					"bold": "Bold",
					"italic": "Italic",
					"underline": "Underline",
					"strike": "Strike",
					"clear": "Remove Font Style",
					"height": "Line Height",
					"name": "Font Family",
					"size": "Font Size"
				},
				"image": {
					"image": "Picture",
					"insert": "Insert Image",
					"resizeFull": "Resize Full",
					"resizeHalf": "Resize Half",
					"resizeQuarter": "Resize Quarter",
					"floatLeft": "Float Left",
					"floatRight": "Float Right",
					"floatNone": "Float None",
					"dragImageHere": "Drag an image here",
					"selectFromFiles": "Select from files",
					"url": "Image URL",
					"remove": "Remove Image"
				},
				"link": {
					"link": "Link",
					"insert": "Insert Link",
					"unlink": "Unlink",
					"edit": "Edit",
					"textToDisplay": "Text to display",
					"url": "To what URL should this link go?",
					"openInNewWindow": "Open in new window"
				},
				"video": {
					"video": "Video",
					"videoLink": "Video Link",
					"insert": "Insert Video",
					"url": "Video URL?",
					"providers": "(YouTube, Vimeo, Vine, Instagram, or DailyMotion)"
				},
				"table": {
					"table": "Table"
				},
				"hr": {
					"insert": "Insert Horizontal Rule"
				},
				"style": {
					"style": "Style",
					"normal": "Normal",
					"blockquote": "Quote",
					"pre": "Code",
					"h1": "Header 1",
					"h2": "Header 2",
					"h3": "Header 3",
					"h4": "Header 4",
					"h5": "Header 5",
					"h6": "Header 6"
				},
				"lists": {
					"unordered": "Unordered list",
					"ordered": "Ordered list"
				},
				"options": {
					"help": "Help",
					"fullscreen": "Full Screen",
					"codeview": "Code View"
				},
				"paragraph": {
					"paragraph": "Paragraph",
					"outdent": "Outdent",
					"indent": "Indent",
					"left": "Align left",
					"center": "Align center",
					"right": "Align right",
					"justify": "Justify full"
				},
				"color": {
					"recent": "Recent Color",
					"more": "More Color",
					"background": "BackColor",
					"foreground": "FontColor",
					"transparent": "Transparent",
					"setTransparent": "Set transparent",
					"reset": "Reset",
					"resetToDefault": "Reset to default"
				},
				"shortcut": {
					"shortcuts": "Keyboard shortcuts",
					"close": "Close",
					"textFormatting": "Text formatting",
					"action": "Action",
					"paragraphFormatting": "Paragraph formatting",
					"documentStyle": "Document Style"
				},
				"history": {
					"undo": "Undo",
					"redo": "Redo"
				}
			}
		},
		"listViewModes": {
			"list": "List",
			"kanban": "Kanban"
		},
		"themes": {
			"Espo": "Classic Espo",
			"EspoRtl": "RTL Espo",
			"Sakura": "Classic Sakura",
			"EspoVertical": "Vertical Espo",
			"SakuraVertical": "Vertical Sakura",
			"Violet": "Classic Violet",
			"VioletVertical": "Vertical Violet",
			"Hazyblue": "Classic Hazyblue",
			"HazyblueVertical": "Vertical Hazyblue"
		}
	},
	"Import": {
		"labels": {
			"Revert Import": "Revert Import",
			"Return to Import": "Return to Import",
			"Run Import": "Run Import",
			"Back": "Back",
			"Field Mapping": "Field Mapping",
			"Default Values": "Default Values",
			"Add Field": "Add Field",
			"Created": "Created",
			"Updated": "Updated",
			"Result": "Result",
			"Show records": "Show records",
			"Remove Duplicates": "Remove Duplicates",
			"importedCount": "Imported (count)",
			"duplicateCount": "Duplicates (count)",
			"updatedCount": "Updated (count)",
			"Create Only": "Create Only",
			"Create and Update": "Create & Update",
			"Update Only": "Update Only",
			"Update by": "Update by",
			"Set as Not Duplicate": "Set as Not Duplicate",
			"File (CSV)": "File (CSV)",
			"First Row Value": "First Row Value",
			"Skip": "Skip",
			"Header Row Value": "Header Row Value",
			"Field": "Field",
			"What to Import?": "What to Import?",
			"Entity Type": "Entity Type",
			"What to do?": "What to do?",
			"Properties": "Properties",
			"Header Row": "Header Row",
			"Person Name Format": "Person Name Format",
			"John Smith": "John Smith",
			"Smith John": "Smith John",
			"Smith, John": "Smith, John",
			"Field Delimiter": "Field Delimiter",
			"Date Format": "Date Format",
			"Decimal Mark": "Decimal Mark",
			"Text Qualifier": "Text Qualifier",
			"Time Format": "Time Format",
			"Currency": "Currency",
			"Preview": "Preview",
			"Next": "Next",
			"Step 1": "Step 1",
			"Step 2": "Step 2",
			"Double Quote": "Double Quote",
			"Single Quote": "Single Quote",
			"Imported": "Imported",
			"Duplicates": "Duplicates",
			"Skip searching for duplicates": "Skip searching for duplicates",
			"Timezone": "Timezone",
			"Remove Import Log": "Remove Import Log",
			"New Import": "New Import",
			"Import Results": "Import Results",
			"Silent Mode": "Silent Mode"
		},
		"messages": {
			"utf8": "Should be UTF-8 encoded",
			"duplicatesRemoved": "Duplicates removed",
			"inIdle": "Execute in idle (for big data; via cron)",
			"revert": "This will remove all imported records permanently.",
			"removeDuplicates": "This will permanently remove all imported records that were recognized as duplicates.",
			"confirmRevert": "This will remove all imported records permanently. Are you sure?",
			"confirmRemoveDuplicates": "This will permanently remove all imported records that were recognized as duplicates. Are you sure?",
			"confirmRemoveImportLog": "This will remove the import log. All imported records will be kept. You won't be able to revert import results. Are you sure you?",
			"removeImportLog": "This will remove the import log. All imported records will be kept. Use it if you are sure that import is fine."
		},
		"fields": {
			"file": "File",
			"entityType": "Entity Type",
			"imported": "Imported Records",
			"duplicates": "Duplicate Records",
			"updated": "Updated Records",
			"status": "Status"
		},
		"options": {
			"status": {
				"Failed": "Failed",
				"In Process": "In Process",
				"Complete": "Complete"
			},
			"personNameFormat": {
				"f l": "First Last",
				"l f": "Last First",
				"f m l": "First Middle Last",
				"l f m": "Last First Middle",
				"l, f": "Last, First"
			}
		},
		"strings": {
			"saveAsDefault": "Save as default"
		},
		"tooltips": {
			"silentMode": "A majority of after-save scripts will be skipped, stream notes won't be created. Import will run faster."
		}
	},
	"InboundEmail": {
		"fields": {
			"name": "Name",
			"emailAddress": "Email Address",
			"team": "Target Team",
			"status": "Status",
			"assignToUser": "Assign to User",
			"host": "Host",
			"username": "Username",
			"password": "Password",
			"port": "Port",
			"monitoredFolders": "Monitored Folders",
			"trashFolder": "Trash Folder",
			"ssl": "SSL",
			"createCase": "Create Case",
			"reply": "Auto-Reply",
			"caseDistribution": "Case Distribution",
			"replyEmailTemplate": "Reply Email Template",
			"replyFromAddress": "Reply From Address",
			"replyToAddress": "Reply To Address",
			"replyFromName": "Reply From Name",
			"targetUserPosition": "Target User Position",
			"fetchSince": "Fetch Since",
			"addAllTeamUsers": "For all team users",
			"teams": "Teams",
			"sentFolder": "Sent Folder",
			"storeSentEmails": "Store Sent Emails",
			"keepFetchedEmailsUnread": "Keep Fetched Emails Unread",
			"useImap": "Fetch Emails",
			"useSmtp": "Use SMTP",
			"smtpHost": "SMTP Host",
			"smtpPort": "SMTP Port",
			"smtpAuth": "SMTP Auth",
			"smtpSecurity": "SMTP Security",
			"smtpAuthMechanism": "SMTP Auth Mechanism",
			"smtpUsername": "SMTP Username",
			"smtpPassword": "SMTP Password",
			"fromName": "From Name",
			"smtpIsShared": "SMTP Is Shared",
			"smtpIsForMassEmail": "SMTP Is for Mass Email"
		},
		"tooltips": {
			"useSmtp": "The ability to send emails.",
			"reply": "Notify email senders that their emails has been received.\n\n Only one email will be sent to a particular recipient during some period of time to prevent looping.",
			"createCase": "Automatically create case from incoming emails.",
			"replyToAddress": "Specify email address of this mailbox to make responses come here.",
			"caseDistribution": "How cases will be assigned to. Assigned directly to the user or among the team.",
			"assignToUser": "User cases will be assigned to.",
			"team": "Team cases will be assigned to.",
			"teams": "Teams emails will be assigned to.",
			"targetUserPosition": "Users with specified position will be distributed with cases.",
			"addAllTeamUsers": "Emails will be appearing in Inbox of all users of specified teams.",
			"monitoredFolders": "Multiple folders should be separated by comma.",
			"smtpIsShared": "If checked then users will be able to send emails using this SMTP. Availability is controlled by Roles through the Group Email Account permission.",
			"smtpIsForMassEmail": "If checked then SMTP will be available for Mass Email.",
			"storeSentEmails": "Sent emails will be stored on the IMAP server."
		},
		"links": {
			"filters": "Filters",
			"emails": "Emails",
			"assignToUser": "Assign to User"
		},
		"options": {
			"status": {
				"Active": "Active",
				"Inactive": "Inactive"
			},
			"caseDistribution": {
				"": "None",
				"Direct-Assignment": "Direct-Assignment",
				"Round-Robin": "Round-Robin",
				"Least-Busy": "Least-Busy"
			},
			"smtpAuthMechanism": {
				"plain": "PLAIN",
				"login": "LOGIN",
				"crammd5": "CRAM-MD5"
			}
		},
		"labels": {
			"Create InboundEmail": "Create Email Account",
			"IMAP": "IMAP",
			"Actions": "Actions",
			"Main": "Main"
		},
		"messages": {
			"couldNotConnectToImap": "Could not connect to IMAP server"
		}
	},
	"Integration": {
		"fields": {
			"enabled": "Enabled",
			"clientId": "Client ID",
			"clientSecret": "Client Secret",
			"redirectUri": "Redirect URI",
			"apiKey": "API Key"
		},
		"titles": {
			"GoogleMaps": "Google Maps"
		},
		"messages": {
			"selectIntegration": "Select an integration from menu.",
			"noIntegrations": "No Integrations is available."
		},
		"help": {
			"Google": "**Obtain OAuth 2.0 credentials from the Google Developers Console.**\n\nVisit [Google Developers Console](https:\/\/console.developers.google.com\/project) to obtain OAuth 2.0 credentials such as a Client ID and Client Secret that are known to both Google and EspoCRM application.",
			"GoogleMaps": "Obtain API key [here](https:\/\/developers.google.com\/maps\/documentation\/javascript\/get-api-key)."
		}
	},
	"Job": {
		"fields": {
			"status": "Status",
			"executeTime": "Execute At",
			"executedAt": "Executed At",
			"startedAt": "Started At",
			"attempts": "Attempts Left",
			"failedAttempts": "Failed Attempts",
			"serviceName": "Service",
			"method": "Method (deprecated)",
			"methodName": "Method",
			"scheduledJob": "Scheduled Job",
			"scheduledJobJob": "Scheduled Job Name",
			"data": "Data",
			"targetType": "Target Type",
			"targetId": "Target ID",
			"number": "Number",
			"queue": "Queue",
			"job": "Job"
		},
		"options": {
			"status": {
				"Pending": "Pending",
				"Success": "Success",
				"Running": "Running",
				"Failed": "Failed"
			}
		}
	},
	"LayoutManager": {
		"fields": {
			"width": "Width (%)",
			"link": "Link",
			"notSortable": "Not Sortable",
			"align": "Align",
			"panelName": "Panel Name",
			"style": "Style",
			"sticked": "Sticked",
			"isLarge": "Large font size",
			"hidden": "Hidden",
			"dynamicLogicVisible": "Conditions making panel visible"
		},
		"options": {
			"align": {
				"left": "Left",
				"right": "Right"
			},
			"style": {
				"default": "Default",
				"success": "Success",
				"danger": "Danger",
				"info": "Info",
				"warning": "Warning",
				"primary": "Primary"
			}
		},
		"labels": {
			"New panel": "New panel",
			"Layout": "Layout"
		},
		"tooltips": {
			"hiddenPanel": "Need to click 'show more' to see the panel.",
			"link": "If checked, then a field value will be displayed as a link pointing to the detail view of the record. Usually it is used for *Name* fields."
		}
	},
	"LayoutSet": {
		"fields": {
			"layoutList": "Layouts"
		},
		"labels": {
			"Create LayoutSet": "Create Layout Set",
			"Edit Layouts": "Edit Layouts"
		},
		"tooltips": []
	},
	"LeadCapture": {
		"fields": {
			"name": "Name",
			"campaign": "Campaign",
			"isActive": "Is Active",
			"subscribeToTargetList": "Subscribe to Target List",
			"subscribeContactToTargetList": "Subscribe Contact if exists",
			"targetList": "Target List",
			"fieldList": "Payload Fields",
			"optInConfirmation": "Double Opt-In",
			"optInConfirmationEmailTemplate": "Opt-in confirmation email template",
			"optInConfirmationLifetime": "Opt-in confirmation lifetime (hours)",
			"optInConfirmationSuccessMessage": "Text to show after opt-in confirmation",
			"leadSource": "Lead Source",
			"apiKey": "API Key",
			"targetTeam": "Target Team",
			"exampleRequestMethod": "Method",
			"exampleRequestUrl": "URL",
			"exampleRequestPayload": "Payload",
			"createLeadBeforeOptInConfirmation": "Create Lead before confirmation",
			"skipOptInConfirmationIfSubscribed": "Skip confirmation if lead is already in target list",
			"smtpAccount": "SMTP Account",
			"inboundEmail": "Group Email Account",
			"duplicateCheck": "Duplicate Check"
		},
		"links": {
			"targetList": "Target List",
			"campaign": "Campaign",
			"optInConfirmationEmailTemplate": "Opt-in confirmation email template",
			"targetTeam": "Target Team",
			"inboundEmail": "Group Email Account",
			"logRecords": "Log"
		},
		"labels": {
			"Create LeadCapture": "Create Entry Point",
			"Generate New API Key": "Generate New API Key",
			"Request": "Request",
			"Confirm Opt-In": "Confirm Opt-In"
		},
		"messages": {
			"generateApiKey": "Create new API Key",
			"optInConfirmationExpired": "Opt-in confirmation link is expired.",
			"optInIsConfirmed": "Opt-in is confirmed."
		},
		"tooltips": {
			"optInConfirmationSuccessMessage": "Markdown is supported."
		}
	},
	"LeadCaptureLogRecord": {
		"fields": {
			"number": "Number",
			"data": "Data",
			"target": "Target",
			"leadCapture": "Lead Capture",
			"createdAt": "Entered At",
			"isCreated": "Is Lead Created"
		},
		"links": {
			"leadCapture": "Lead Capture",
			"target": "Target"
		}
	},
	"Note": {
		"fields": {
			"post": "Post",
			"attachments": "Attachments",
			"targetType": "Target",
			"teams": "Teams",
			"users": "Users",
			"portals": "Portals",
			"type": "Type",
			"isGlobal": "Is Global",
			"isInternal": "Is Internal (for internal users)",
			"related": "Related",
			"createdByGender": "Created By Gender",
			"data": "Data",
			"number": "Number"
		},
		"filters": {
			"all": "All",
			"posts": "Posts",
			"updates": "Updates"
		},
		"options": {
			"targetType": {
				"self": "to myself",
				"users": "to particular user(s)",
				"teams": "to particular team(s)",
				"all": "to all internal users",
				"portals": "to portal users"
			},
			"type": {
				"Post": "Post"
			}
		},
		"messages": {
			"writeMessage": "Write your message here"
		},
		"links": {
			"superParent": "Super Parent",
			"related": "Related"
		}
	},
	"PhoneNumber": {
		"fields": {
			"type": "Type",
			"optOut": "Opted Out",
			"invalid": "Invalid"
		},
		"presetFilters": {
			"orphan": "Orphan"
		}
	},
	"Portal": {
		"fields": {
			"name": "Name",
			"logo": "Logo",
			"url": "URL",
			"portalRoles": "Roles",
			"isActive": "Is Active",
			"isDefault": "Is Default",
			"tabList": "Tab List",
			"quickCreateList": "Quick Create List",
			"companyLogo": "Logo",
			"theme": "Theme",
			"language": "Language",
			"dashboardLayout": "Dashboard Layout",
			"dateFormat": "Date Format",
			"timeFormat": "Time Format",
			"timeZone": "Time Zone",
			"weekStart": "First Day of Week",
			"defaultCurrency": "Default Currency",
			"layoutSet": "Layout Set",
			"customUrl": "Custom URL",
			"customId": "Custom ID"
		},
		"links": {
			"users": "Users",
			"portalRoles": "Roles",
			"layoutSet": "Layout Set",
			"notes": "Notes",
			"articles": "Knowledge Base Articles"
		},
		"tooltips": {
			"layoutSet": "Provides the ability to have layouts that differ from standard ones.",
			"portalRoles": "Specified Portal Roles will be applied to all users of this portal."
		},
		"labels": {
			"Create Portal": "Create Portal",
			"User Interface": "User Interface",
			"General": "General",
			"Settings": "Settings"
		}
	},
	"PortalRole": {
		"fields": {
			"exportPermission": "Export Permission",
			"massUpdatePermission": "Mass Update Permission"
		},
		"links": {
			"users": "Users"
		},
		"tooltips": {
			"exportPermission": "Defines whether portal users have an ability to export records.",
			"massUpdatePermission": "Defines whether portal users have an ability to do mass update of records."
		},
		"labels": {
			"Access": "Access",
			"Create PortalRole": "Create Portal Role",
			"Scope Level": "Scope Level",
			"Field Level": "Field Level"
		}
	},
	"PortalUser": {
		"labels": {
			"Create PortalUser": "Create Portal User"
		}
	},
	"Preferences": {
		"fields": {
			"dateFormat": "Date Format",
			"timeFormat": "Time Format",
			"timeZone": "Time Zone",
			"weekStart": "First Day of Week",
			"thousandSeparator": "Thousand Separator",
			"decimalMark": "Decimal Mark",
			"defaultCurrency": "Default Currency",
			"currencyList": "Currency List",
			"language": "Language",
			"smtpServer": "Server",
			"smtpPort": "Port",
			"smtpAuth": "Auth",
			"smtpSecurity": "Security",
			"smtpUsername": "Username",
			"emailAddress": "Email",
			"smtpPassword": "Password",
			"smtpEmailAddress": "Email Address",
			"exportDelimiter": "Export Delimiter",
			"receiveAssignmentEmailNotifications": "Email notifications upon assignment",
			"receiveMentionEmailNotifications": "Email notifications about mentions in posts",
			"receiveStreamEmailNotifications": "Email notifications about posts and status updates",
			"assignmentNotificationsIgnoreEntityTypeList": "In-app assignment notifications",
			"assignmentEmailNotificationsIgnoreEntityTypeList": "Email assignment notifications",
			"autoFollowEntityTypeList": "Global Auto-Follow",
			"signature": "Email Signature",
			"dashboardTabList": "Tab List",
			"defaultReminders": "Default Reminders",
			"theme": "Theme",
			"useCustomTabList": "Custom Tab List",
			"tabList": "Tab List",
			"emailReplyToAllByDefault": "Email Reply to all by default",
			"dashboardLayout": "Dashboard Layout",
			"emailReplyForceHtml": "Email Reply in HTML",
			"doNotFillAssignedUserIfNotRequired": "Do not pre-fill assigned user on record creation",
			"followEntityOnStreamPost": "Auto-follow record after posting in Stream",
			"followCreatedEntities": "Auto-follow created records",
			"followCreatedEntityTypeList": "Auto-follow created records of specific entity types",
			"emailUseExternalClient": "Use an external email client",
			"scopeColorsDisabled": "Disable scope colors",
			"tabColorsDisabled": "Disable tab colors"
		},
		"links": [],
		"options": {
			"weekStart": ["Sunday", "Monday"]
		},
		"labels": {
			"Notifications": "Notifications",
			"User Interface": "User Interface",
			"SMTP": "SMTP",
			"Misc": "Misc",
			"Locale": "Locale",
			"Reset Dashboard to Default": "Reset Dashboard to Default"
		},
		"tooltips": {
			"autoFollowEntityTypeList": "Automatically follow ALL new records (created by any user) of the selected entity types. To be able to see information in the stream and receive notifications about all records in the system.",
			"doNotFillAssignedUserIfNotRequired": "When create record assigned user won't be filled with own user unless the field is required.",
			"followCreatedEntities": "When create new records, they will be automatically followed even if assigned to another user.",
			"followCreatedEntityTypeList": "When create new records of selected entity types, they will be followed automatically even if assigned to another user."
		}
	},
	"Role": {
		"fields": {
			"name": "Name",
			"roles": "Roles",
			"assignmentPermission": "Assignment Permission",
			"userPermission": "User Permission",
			"portalPermission": "Portal Permission",
			"groupEmailAccountPermission": "Group Email Account Permission",
			"exportPermission": "Export Permission",
			"massUpdatePermission": "Mass Update Permission",
			"dataPrivacyPermission": "Data Privacy Permission"
		},
		"links": {
			"users": "Users",
			"teams": "Teams"
		},
		"tooltips": {
			"assignmentPermission": "Allows to restrict an ability to assign records and post messages to other users.\n\nall - no restriction\n\nteam - can assign and post only to teammates\n\nno - can assign and post only to self",
			"userPermission": "Allows to restrict an ability for users to view activities, calendar and stream of other users.\n\nall - can view all\n\nteam - can view activities of teammates only\n\nno - can't view",
			"portalPermission": "Defines an access to portal information, ability to post messages to portal users.",
			"groupEmailAccountPermission": "Defines an access to group email accounts, an ability to send emails from group SMTP.",
			"exportPermission": "Defines whether users have an ability to export records.",
			"massUpdatePermission": "Defines whether users have an ability to do mass update of records.",
			"dataPrivacyPermission": "Allows to view and erase personal data."
		},
		"labels": {
			"Access": "Access",
			"Create Role": "Create Role",
			"Scope Level": "Scope Level",
			"Field Level": "Field Level"
		},
		"options": {
			"accessList": {
				"not-set": "not-set",
				"enabled": "enabled",
				"disabled": "disabled"
			},
			"levelList": {
				"all": "all",
				"team": "team",
				"account": "account",
				"contact": "contact",
				"own": "own",
				"no": "no",
				"yes": "yes",
				"not-set": "not-set"
			}
		},
		"actions": {
			"read": "Read",
			"edit": "Edit",
			"delete": "Delete",
			"stream": "Stream",
			"create": "Create"
		},
		"messages": {
			"changesAfterClearCache": "All changes in an access control will be applied after cache is cleared."
		}
	},
	"ScheduledJob": {
		"fields": {
			"name": "Name",
			"status": "Status",
			"job": "Job",
			"scheduling": "Scheduling"
		},
		"links": {
			"log": "Log"
		},
		"labels": {
			"Create ScheduledJob": "Create Scheduled Job"
		},
		"options": {
			"job": {
				"Cleanup": "Clean-up",
				"CheckInboundEmails": "Check Group Email Accounts",
				"CheckEmailAccounts": "Check Personal Email Accounts",
				"SendEmailReminders": "Send Email Reminders",
				"AuthTokenControl": "Auth Token Control",
				"SendEmailNotifications": "Send Email Notifications",
				"CheckNewVersion": "Check for New Version",
				"ProcessWebhookQueue": "Process Webhook Queue",
				"ProcessMassEmail": "Send Mass Emails",
				"ControlKnowledgeBaseArticleStatus": "Control Knowledge Base Article Status",
				"ReportTargetListSync": "Sync Target Lists with Reports",
				"ScheduleReportSending": "Schedule Report Sending",
				"RunScheduledWorkflows": "Run Scheduled Workflows",
				"ProcessPendingProcessFlows": "Process Pending Flows"
			},
			"cronSetup": {
				"linux": "Note: Add this line to the crontab file to run Espo Scheduled Jobs:",
				"mac": "Note: Add this line to the crontab file to run Espo Scheduled Jobs:",
				"windows": "Note: Create a batch file with the following commands to run Espo Scheduled Jobs using Windows Scheduled Tasks:",
				"default": "Note: Add this command to Cron Job (Scheduled Task):"
			},
			"status": {
				"Active": "Active",
				"Inactive": "Inactive"
			}
		},
		"tooltips": {
			"scheduling": "Crontab notation. Defines frequency of job runs.\n\n`*\/5 * * * *` - every 5 minutes\n\n`0 *\/2 * * *` - every 2 hours\n\n`30 1 * * *` - at 01:30 once a day\n\n`0 0 1 * *` - on the first day of the month"
		}
	},
	"ScheduledJobLogRecord": {
		"fields": {
			"status": "Status",
			"executionTime": "Execution Time",
			"target": "Target"
		}
	},
	"Settings": {
		"fields": {
			"useCache": "Use Cache",
			"dateFormat": "Date Format",
			"timeFormat": "Time Format",
			"timeZone": "Time Zone",
			"weekStart": "First Day of Week",
			"thousandSeparator": "Thousand Separator",
			"decimalMark": "Decimal Mark",
			"defaultCurrency": "Default Currency",
			"baseCurrency": "Base Currency",
			"currencyRates": "Rate Values",
			"currencyList": "Currency List",
			"language": "Language",
			"companyLogo": "Company Logo",
			"smtpServer": "Server",
			"smtpPort": "Port",
			"smtpAuth": "Auth",
			"smtpSecurity": "Security",
			"smtpUsername": "Username",
			"emailAddress": "Email",
			"smtpPassword": "Password",
			"outboundEmailFromName": "From Name",
			"outboundEmailFromAddress": "From Address",
			"outboundEmailIsShared": "Is Shared",
			"emailAddressLookupEntityTypeList": "Email address look-up scopes",
			"recordsPerPage": "Records Per Page",
			"recordsPerPageSmall": "Records Per Page (Small)",
			"tabList": "Tab List",
			"quickCreateList": "Quick Create List",
			"exportDelimiter": "Export Delimiter",
			"globalSearchEntityList": "Global Search Entity List",
			"authenticationMethod": "Authentication Method",
			"ldapHost": "Host",
			"ldapPort": "Port",
			"ldapAuth": "Auth",
			"ldapUsername": "Full User DN",
			"ldapPassword": "Password",
			"ldapBindRequiresDn": "Bind Requires DN",
			"ldapBaseDn": "Base DN",
			"ldapAccountCanonicalForm": "Account Canonical Form",
			"ldapAccountDomainName": "Account Domain Name",
			"ldapTryUsernameSplit": "Try Username Split",
			"ldapPortalUserLdapAuth": "Use LDAP Authentication for Portal Users",
			"ldapCreateEspoUser": "Create User in EspoCRM",
			"ldapSecurity": "Security",
			"ldapUserLoginFilter": "User Login Filter",
			"ldapAccountDomainNameShort": "Account Domain Name Short",
			"ldapOptReferrals": "Opt Referrals",
			"ldapUserNameAttribute": "Username Attribute",
			"ldapUserObjectClass": "User ObjectClass",
			"ldapUserTitleAttribute": "User Title Attribute",
			"ldapUserFirstNameAttribute": "User First Name Attribute",
			"ldapUserLastNameAttribute": "User Last Name Attribute",
			"ldapUserEmailAddressAttribute": "User Email Address Attribute",
			"ldapUserTeams": "User Teams",
			"ldapUserDefaultTeam": "User Default Team",
			"ldapUserPhoneNumberAttribute": "User Phone Number Attribute",
			"ldapPortalUserPortals": "Default Portals for a Portal User",
			"ldapPortalUserRoles": "Default Roles for a Portal User",
			"exportDisabled": "Disable Export (only admin is allowed)",
			"assignmentNotificationsEntityList": "Entities to notify about upon assignment",
			"assignmentEmailNotifications": "Notifications upon assignment",
			"assignmentEmailNotificationsEntityList": "Assignment email notifications scopes",
			"streamEmailNotifications": "Notifications about updates in Stream for internal users",
			"portalStreamEmailNotifications": "Notifications about updates in Stream for portal users",
			"streamEmailNotificationsEntityList": "Stream email notifications scopes",
			"streamEmailNotificationsTypeList": "What to notify about",
			"emailNotificationsDelay": "Delay of email notifications (in seconds)",
			"b2cMode": "B2C Mode",
			"avatarsDisabled": "Disable Avatars",
			"followCreatedEntities": "Follow created records",
			"displayListViewRecordCount": "Display Total Count (on List View)",
			"theme": "Theme",
			"userThemesDisabled": "Disable User Themes",
			"emailMessageMaxSize": "Email Max Size (Mb)",
			"massEmailMaxPerHourCount": "Max number of emails sent per hour",
			"personalEmailMaxPortionSize": "Max email portion size for personal account fetching",
			"inboundEmailMaxPortionSize": "Max email portion size for group account fetching",
			"maxEmailAccountCount": "Max number of personal email accounts per user",
			"authTokenLifetime": "Auth Token Lifetime (hours)",
			"authTokenMaxIdleTime": "Auth Token Max Idle Time (hours)",
			"dashboardLayout": "Dashboard Layout (default)",
			"siteUrl": "Site URL",
			"addressPreview": "Address Preview",
			"addressFormat": "Address Format",
			"personNameFormat": "Person Name Format",
			"notificationSoundsDisabled": "Disable Notification Sounds",
			"newNotificationCountInTitle": "Display new notification number in page title",
			"applicationName": "Application Name",
			"calendarEntityList": "Calendar Entity List",
			"busyRangesEntityList": "Free\/Busy Entity List",
			"mentionEmailNotifications": "Send email notifications about mentions in posts",
			"massEmailDisableMandatoryOptOutLink": "Disable mandatory opt-out link",
			"massEmailOpenTracking": "Email Open Tracking",
			"massEmailVerp": "Use VERP",
			"activitiesEntityList": "Activities Entity List",
			"historyEntityList": "History Entity List",
			"currencyFormat": "Currency Format",
			"currencyDecimalPlaces": "Currency Decimal Places",
			"aclStrictMode": "ACL Strict Mode",
			"aclAllowDeleteCreated": "Allow to remove created records",
			"adminNotifications": "System notifications in administration panel",
			"adminNotificationsNewVersion": "Show notification when new EspoCRM version is available",
			"adminNotificationsNewExtensionVersion": "Show notification when new versions of extensions are available",
			"textFilterUseContainsForVarchar": "Use 'contains' operator when filtering varchar fields",
			"authTokenPreventConcurrent": "Only one auth token per user",
			"scopeColorsDisabled": "Disable scope colors",
			"tabColorsDisabled": "Disable tab colors",
			"tabIconsDisabled": "Disable tab icons",
			"emailAddressIsOptedOutByDefault": "Mark new email addresses as opted-out",
			"outboundEmailBccAddress": "BCC address for external clients",
			"cleanupDeletedRecords": "Clean up deleted records",
			"addressCountryList": "Address Country Autocomplete List",
			"addressCityList": "Address City Autocomplete List",
			"addressStateList": "Address State Autocomplete List",
			"fiscalYearShift": "Fiscal Year Start",
			"jobRunInParallel": "Jobs Run in Parallel",
			"jobMaxPortion": "Jobs Max Portion",
			"jobPoolConcurrencyNumber": "Jobs Pool Concurrency Number",
			"daemonInterval": "Daemon Interval",
			"daemonMaxProcessNumber": "Daemon Max Process Number",
			"daemonProcessTimeout": "Daemon Process Timeout",
			"cronDisabled": "Disable Cron",
			"maintenanceMode": "Maintenance Mode",
			"useWebSocket": "Use WebSocket",
			"passwordRecoveryDisabled": "Disable password recovery",
			"passwordRecoveryForAdminDisabled": "Disable password recovery for admin users",
			"passwordGenerateLength": "Length of generated passwords",
			"passwordStrengthLength": "Minimum password length",
			"passwordStrengthLetterCount": "Number of letters required in password",
			"passwordStrengthNumberCount": "Number of digits required in password",
			"passwordStrengthBothCases": "Password must contain letters of both upper and lower case",
			"auth2FA": "Enable 2-Factor Authentication",
			"auth2FAForced": "Force regular users to set up 2FA",
			"auth2FAMethodList": "Available 2FA methods"
		},
		"options": {
			"weekStart": ["Sunday", "Monday"],
			"currencyFormat": {
				"1": "10 USD",
				"2": "$10"
			},
			"personNameFormat": {
				"firstLast": "First Last",
				"lastFirst": "Last First",
				"firstMiddleLast": "First Middle Last",
				"lastFirstMiddle": "Last First Middle"
			},
			"streamEmailNotificationsTypeList": {
				"Post": "Posts",
				"Status": "Status updates",
				"EmailReceived": "Received emails"
			},
			"auth2FAMethodList": {
				"Totp": "TOTP"
			}
		},
		"tooltips": {
			"emailAddressLookupEntityTypeList": "For email address autocomplete.",
			"emailNotificationsDelay": "A message can be edited within the specified timeframe before the notification is sent.",
			"outboundEmailFromAddress": "The system email address.",
			"smtpServer": "If empty, then Group Email Account with the corresponding email address will be used.",
			"busyRangesEntityList": "What will be taken into account when showing busy time ranges in scheduler & timeline.",
			"massEmailVerp": "Variable envelope return path. For better handling of bounced messages. Make sure that your SMTP provider supports it.",
			"recordsPerPage": "Number of records initially displayed in list views.",
			"recordsPerPageSmall": "Number of records initially displayed in relationship panels.",
			"outboundEmailIsShared": "Allow users to send emails from this address.",
			"followCreatedEntities": "Users will automatically follow records they created.",
			"emailMessageMaxSize": "All inbound emails exceeding a specified size will be fetched w\/o body and attachments.",
			"authTokenLifetime": "Defines how long tokens can exist.\n0 - means no expiration.",
			"authTokenMaxIdleTime": "Defines how long since the last access tokens can exist.\n0 - means no expiration.",
			"userThemesDisabled": "If checked then users won't be able to select another theme.",
			"ldapUsername": "The full system user DN which allows to search other users. E.g. \"CN=LDAP System User,OU=users,OU=espocrm, DC=test,DC=lan\".",
			"ldapPassword": "The password to access to LDAP server.",
			"ldapAuth": "Access credentials for the LDAP server.",
			"ldapUserNameAttribute": "The\u00a0attribute to identify the user. \nE.g. \"userPrincipalName\" or \"sAMAccountName\" for Active Directory, \"uid\" for OpenLDAP.",
			"ldapUserObjectClass": "ObjectClass attribute for searching users. E.g. \"person\" for AD, \"inetOrgPerson\" for OpenLDAP.",
			"ldapAccountCanonicalForm": "The type of your\u00a0account canonical form. There are 4 options:\n\n- 'Dn' - the form in the format 'CN=tester,OU=espocrm,DC=test, DC=lan'.\n\n- 'Username' - the form 'tester'.\n\n- 'Backslash' - the form 'COMPANY\\tester'.\n\n- 'Principal' - the form 'tester@company.com'.",
			"ldapBindRequiresDn": "The option to format\u00a0the username in the DN form.",
			"ldapBaseDn": "The\u00a0default base DN used for searching users. E.g. \"OU=users,OU=espocrm,DC=test, DC=lan\".",
			"ldapTryUsernameSplit": "The option to split a username with the domain.",
			"ldapOptReferrals": "if\u00a0referrals should be followed to the LDAP client.",
			"ldapPortalUserLdapAuth": "Allow portal users to use LDAP authentication instead of Espo authentication.",
			"ldapCreateEspoUser": "This option allows EspoCRM\u00a0to create a user from the LDAP.",
			"ldapUserFirstNameAttribute": "LDAP attribute which is used to determine the user first name. E.g. \"givenname\".",
			"ldapUserLastNameAttribute": "LDAP attribute which is used to determine the user last name. E.g. \"sn\".",
			"ldapUserTitleAttribute": "LDAP attribute which is used to determine the user title. E.g. \"title\".",
			"ldapUserEmailAddressAttribute": "LDAP attribute which is used to determine the user email address. E.g. \"mail\".",
			"ldapUserPhoneNumberAttribute": "LDAP attribute which is used to determine the user phone number. E.g. \"telephoneNumber\".",
			"ldapUserLoginFilter": "The filter which allows to restrict users who able to use EspoCRM. E.g. \"memberOf=CN=espoGroup, OU=groups,OU=espocrm, DC=test,DC=lan\".",
			"ldapAccountDomainName": "The domain which is used for authorization to LDAP server.",
			"ldapAccountDomainNameShort": "The short domain which is used for authorization to LDAP server.",
			"ldapUserTeams": "Teams for created user. For more, see user profile.",
			"ldapUserDefaultTeam": "Default team for created user. For more, see user profile.",
			"ldapPortalUserPortals": "Default Portals for created Portal User",
			"ldapPortalUserRoles": "Default Roles for created Portal User",
			"b2cMode": "By default EspoCRM is adapted for B2B. You can switch it to B2C.",
			"currencyDecimalPlaces": "Number of decimal places. If empty then all nonempty decimal places will be displayed.",
			"aclStrictMode": "Enabled: Access to scopes will be forbidden if it's not specified in roles.\n\nDisabled: Access to scopes will be allowed if it's not specified in roles.",
			"aclAllowDeleteCreated": "Users will be able to remove records they created even if they don't have a delete access.",
			"textFilterUseContainsForVarchar": "If not checked then 'starts with' operator is used. You can use the wildcard '%'.",
			"streamEmailNotificationsEntityList": "Email notifications about stream updates of followed records. Users will receive email notifications only for specified entity types.",
			"authTokenPreventConcurrent": "Users won't be able to be logged in on multiple devices simultaneously.",
			"emailAddressIsOptedOutByDefault": "When creating new record email addess will be marked as opted-out.",
			"cleanupDeletedRecords": "Removed records will be deleted from database after a while.",
			"jobRunInParallel": "Jobs will be executed in parallel processes.",
			"jobPoolConcurrencyNumber": "Max number of processes run simultaneously.",
			"jobMaxPortion": "Max number of jobs processed per one execution.",
			"daemonInterval": "Interval between process cron runs in seconds.",
			"daemonMaxProcessNumber": "Max number of cron processes run simultaneously.",
			"daemonProcessTimeout": "Max execution time (in seconds) allocated for a single cron process.",
			"cronDisabled": "Cron will not run.",
			"maintenanceMode": "Only administrators will have access to the system."
		},
		"labels": {
			"System": "System",
			"Locale": "Locale",
			"Search": "Search",
			"Misc": "Misc",
			"SMTP": "SMTP",
			"Configuration": "Configuration",
			"In-app Notifications": "In-app Notifications",
			"Email Notifications": "Email Notifications",
			"Currency Settings": "Currency Settings",
			"Currency Rates": "Currency Rates",
			"Mass Email": "Mass Email",
			"Test Connection": "Test Connection",
			"Connecting": "Connecting...",
			"Activities": "Activities",
			"Admin Notifications": "Admin Notifications",
			"Passwords": "Passwords",
			"2-Factor Authentication": "2-Factor Authentication"
		},
		"messages": {
			"ldapTestConnection": "The connection successfully established."
		}
	},
	"Stream": {
		"messages": {
			"infoMention": "Type **@username** to mention user in the post.",
			"infoSyntax": "Available markdown syntax"
		},
		"syntaxItems": {
			"code": "code",
			"multilineCode": "multiline code",
			"strongText": "strong text",
			"emphasizedText": "emphasized text",
			"deletedText": "deleted text",
			"blockquote": "blockquote",
			"link": "link"
		}
	},
	"Team": {
		"fields": {
			"name": "Name",
			"roles": "Roles",
			"layoutSet": "Layout Set",
			"positionList": "Position List"
		},
		"links": {
			"users": "Users",
			"notes": "Notes",
			"roles": "Roles",
			"layoutSet": "Layout Set",
			"inboundEmails": "Group Email Accounts"
		},
		"tooltips": {
			"layoutSet": "Provides the ability to have layouts that differ from standard ones. Layout Set will be applied to users who have this team set as Default Team.",
			"roles": "Access Roles. Users of this team obtain access control level from selected roles.",
			"positionList": "Available positions in this team. E.g. Salesperson, Manager."
		},
		"labels": {
			"Create Team": "Create Team"
		}
	},
	"Template": {
		"fields": {
			"name": "Name",
			"body": "Body",
			"entityType": "Entity Type",
			"header": "Header",
			"footer": "Footer",
			"leftMargin": "Left Margin",
			"topMargin": "Top Margin",
			"rightMargin": "Right Margin",
			"bottomMargin": "Bottom Margin",
			"printFooter": "Print Footer",
			"printHeader": "Print Header on each page",
			"footerPosition": "Footer Position",
			"headerPosition": "Header Position",
			"variables": "Available Placeholders",
			"pageOrientation": "Page Orientation",
			"pageFormat": "Paper Format",
			"pageWidth": "Page Width (mm)",
			"pageHeight": "Page Height (mm)",
			"fontFace": "Font"
		},
		"links": [],
		"labels": {
			"Create Template": "Create Template"
		},
		"options": {
			"pageOrientation": {
				"Portrait": "Portrait",
				"Landscape": "Landscape"
			},
			"pageFormat": {
				"Custom": "Custom"
			},
			"placeholders": {
				"pagebreak": "Page break",
				"today": "Today (date)",
				"now": "Now (date-time)"
			},
			"fontFace": {
				"aealarabiya": "AlArabiya",
				"aefurat": "Aefurat",
				"cid0cs": "CID-0 cs",
				"cid0ct": "CID-0 ct",
				"cid0jp": "CID-0 jp",
				"cid0kr": "CID-0 kr",
				"courier": "Courier",
				"dejavusans": "DejaVu Sans",
				"dejavusanscondensed": "DejaVu Sans Condensed",
				"dejavusansextralight": "DejaVu Sans ExtraLight",
				"dejavusansmono": "DejaVu Sans Mono",
				"dejavuserif": "DejaVu Serif",
				"dejavuserifcondensed": "DejaVu Serif Condensed",
				"freemono": "FreeMono",
				"freesans": "FreeSans",
				"freeserif": "FreeSerif",
				"helvetica": "Helvetica",
				"hysmyeongjostdmedium": "Hysmyeongjostd Medium",
				"kozgopromedium": "Kozgo Pro Medium",
				"kozminproregular": "Kozmin Pro Regular",
				"msungstdlight": "Msung Std Light",
				"pdfacourier": "PDFA Courier",
				"pdfahelvetica": "PDFA Helvetica",
				"pdfasymbol": "PDFA Symbol",
				"pdfatimes": "PDFA Times",
				"stsongstdlight": "STSong Std Light",
				"symbol": "Symbol",
				"times": "Times"
			}
		},
		"tooltips": {
			"footer": "Use {pageNumber} to print page number.",
			"variables": "Copy-paste needed placeholder to Header, Body or Footer."
		}
	},
	"User": {
		"fields": {
			"name": "Name",
			"userName": "User Name",
			"title": "Title",
			"type": "Type",
			"isAdmin": "Is Admin",
			"defaultTeam": "Default Team",
			"emailAddress": "Email",
			"phoneNumber": "Phone",
			"roles": "Roles",
			"portals": "Portals",
			"portalRoles": "Portal Roles",
			"teamRole": "Position",
			"currentPassword": "Current Password",
			"newPassword": "New Password",
			"newPasswordConfirm": "Confirm New Password",
			"yourPassword": "Your current password",
			"avatar": "Avatar",
			"isActive": "Is Active",
			"isPortalUser": "Is Portal User",
			"contact": "Contact",
			"accounts": "Accounts",
			"account": "Account (Primary)",
			"sendAccessInfo": "Send Email with Access Info to User",
			"portal": "Portal",
			"gender": "Gender",
			"position": "Position in Team",
			"ipAddress": "IP Address",
			"passwordPreview": "Password Preview",
			"isSuperAdmin": "Is Super Admin",
			"lastAccess": "Last Access",
			"apiKey": "API Key",
			"secretKey": "Secret Key",
			"dashboardTemplate": "Dashboard Template",
			"auth2FA": "2FA",
			"authMethod": "Authentication Method",
			"auth2FAEnable": "Enable 2-Factor Authentication",
			"auth2FAMethod": "2FA Method",
			"auth2FATotpSecret": "2FA TOTP Secret",
			"acceptanceStatus": "Acceptance Status",
			"acceptanceStatusMeetings": "Acceptance Status (Meetings)",
			"acceptanceStatusCalls": "Acceptance Status (Calls)",
			"accountLogo": "Account Logo",
			"defaultAccount": "Default Account",
			"phoneCalls": "Phone Calls",
			"sipDisplayName": "SIP Display Name",
			"sipPassword": "SIP Password",
			"sipUser": "SIP User",
			"workSite": "Work Site",
			"sMSs": "SMS",
			"sms": "SMS",
			"haofang": "HaoFang",
			"tasksAssigned": "Tasks Assigned",
			"phoneCall": "Phone Call",
			"onlineStatus": "Online Status",
			"onlineStatusTime": "Online Status Time",
			"enableSetOnlineStatus": "Enable Set Online Status",
			"lastSMSRequestTime": "Last SMS Request Time",
			"employmentId": "Employment Id",
			"screenshots": "Screenshots",
			"groups": "Groups",
			"password": "Password",
			"passwordConfirm": "Confirm Password"
		},
		"links": {
			"defaultTeam": "Default Team",
			"teams": "Teams",
			"roles": "Roles",
			"notes": "Notes",
			"portals": "Portals",
			"portalRoles": "Portal Roles",
			"contact": "Contact",
			"accounts": "Accounts",
			"account": "Account (Primary)",
			"tasks": "Tasks",
			"dashboardTemplate": "Dashboard Template",
			"targetLists": "Target Lists",
			"defaultAccount": "Default Account",
			"phoneCalls": "Phone Calls",
			"sMSs": "SMS",
			"tasksAssigned": "Tasks Assigned",
			"phoneCall": "Phone Call",
			"screenshots": "Screenshots",
			"groups": "Groups"
		},
		"labels": {
			"Create User": "Create User",
			"Generate": "Generate",
			"Access": "Access",
			"Preferences": "Preferences",
			"Change Password": "Change Password",
			"Teams and Access Control": "Teams and Access Control",
			"Forgot Password?": "Forgot Password?",
			"Password Change Request": "Password Change Request",
			"Email Address": "Email Address",
			"External Accounts": "External Accounts",
			"Email Accounts": "Email Accounts",
			"Portal": "Portal",
			"Create Portal User": "Create Portal User",
			"Proceed w\/o Contact": "Proceed w\/o Contact",
			"Generate New API Key": "Generate New API Key",
			"Generate New Password": "Generate New Password",
			"Code": "Code",
			"Back to login form": "Back to login form",
			"Requirements": "Requirements",
			"Security": "Security",
			"Reset 2FA": "Reset 2FA",
			"Secret": "Secret"
		},
		"tooltips": {
			"defaultTeam": "All records created by this user will be related to this team by default.",
			"userName": "Letters a-z, numbers 0-9, dots, hyphens, @-signs and underscores are allowed.",
			"isAdmin": "Admin user can access everything.",
			"isActive": "If unchecked then user won't be able to login.",
			"teams": "Teams which this user belongs to. Access control level is inherited from team's roles.",
			"roles": "Additional access roles. Use it if user doesn't belong to any team or you need to extend access control level exclusively for this user.",
			"portalRoles": "Additional portal roles. Use it to extend access control level exclusively for this user.",
			"portals": "Portals which this user has access to."
		},
		"messages": {
			"passwordStrengthLength": "Must be at least {length} characters long.",
			"passwordStrengthLetterCount": "Must contain at least {count} letter(s).",
			"passwordStrengthNumberCount": "Must contain at least {count} digit(s).",
			"passwordStrengthBothCases": "Must contain letters of both upper and lower case.",
			"passwordWillBeSent": "Password will be sent to user's email address.",
			"passwordChanged": "Password has been changed",
			"userCantBeEmpty": "Username can not be empty",
			"wrongUsernamePasword": "Wrong username\/password",
			"emailAddressCantBeEmpty": "Email Address can not be empty",
			"userNameEmailAddressNotFound": "Username\/Email Address not found",
			"forbidden": "Forbidden, please try later",
			"uniqueLinkHasBeenSent": "The unique URL has been sent to the specified email address.",
			"passwordChangedByRequest": "Password has been changed.",
			"setupSmtpBefore": "You need to setup [SMTP settings]({url}) to make the system be able to send password in email.",
			"userNameExists": "User Name already exists",
			"wrongCode": "Wrong code",
			"codeIsRequired": "Code is required",
			"enterTotpCode": "Enter a code from your authenticator app.",
			"verifyTotpCode": "Scan the QR-code with your mobile authenticator app. If you have a trouble with scanning, you can enter the secret manually. After that you will see a 6-digit code in your application. Enter this code in the field below.",
			"generateAndSendNewPassword": "A new password will be generated and sent to the user's email address.",
			"security2FaResetConfimation": "Are you sure you want to reset the current 2FA settings?",
			"auth2FARequiredHeader": "2 factor authentication required",
			"auth2FARequired": "You need to set up 2 factor authentication. Use an authenticator application on your mobile phone (e.g. Google Authenticator).",
			"ldapUserInEspoNotFound": "User is not found in EspoCRM. Contact your administrator to create the user."
		},
		"options": {
			"gender": {
				"": "Not Set",
				"Male": "Male",
				"Female": "Female",
				"Neutral": "Neutral"
			},
			"type": {
				"regular": "Regular",
				"admin": "Admin",
				"portal": "Portal",
				"system": "System",
				"super-admin": "Super-Admin",
				"api": "API"
			},
			"authMethod": {
				"ApiKey": "API Key",
				"Hmac": "HMAC"
			},
			"workSite": [],
			"onlineStatus": {
				"Online": "Online",
				"Meal": "Meal",
				"Breaktime": "Breaktime",
				"Offline": "Offline"
			}
		},
		"boolFilters": {
			"onlyMyTeam": "Only My Team"
		},
		"presetFilters": {
			"active": "Active",
			"activePortal": "Portal Active",
			"activeApi": "API Active"
		}
	},
	"Webhook": {
		"labels": {
			"Create Webhook": "Create Webhook"
		},
		"fields": {
			"event": "Event",
			"url": "URL",
			"isActive": "Is Active",
			"user": "API User",
			"entityType": "Entity Type",
			"field": "Field",
			"secretKey": "Secret Key"
		},
		"links": {
			"user": "User"
		}
	},
	"Account": {
		"fields": {
			"name": "Name",
			"emailAddress": "Email",
			"website": "Website",
			"phoneNumber": "Phone",
			"billingAddress": "Billing Address",
			"shippingAddress": "Shipping Address",
			"description": "Description",
			"sicCode": "Sic Code",
			"industry": "Industry",
			"type": "Type",
			"contactRole": "Title",
			"contactIsInactive": "Inactive",
			"campaign": "Campaign",
			"targetLists": "Target Lists",
			"targetList": "Target List",
			"originalLead": "Original Lead",
			"users": "Users",
			"phoneCalls": "Phone Calls",
			"servicePhoneNumber": "Service Phone Number",
			"pbxQueue": "PBX Queue",
			"waitTime": "Wait Time",
			"asteriskServer": "Asterisk Server",
			"asteriskWSSURL": "Asterisk WSS URL",
			"timezone": "Timezone",
			"workdays": "Work Days",
			"holidays": "Holidays",
			"agentTemporaryLeave": "Agent Temporary Leave",
			"startTimeHour": "Start Time Hour",
			"startTimeMin": "StartTimeMin",
			"endTimeHour": "End Time Hour",
			"endTimeMin": "End Time Min",
			"language": "Language",
			"voice": "Voice",
			"offTimeIVR": "Off Time IVR",
			"holidaysIVR": "Holidays IVR",
			"autopilot": "Autopilot Task",
			"greetIVR": "Greet IVR",
			"foundCaseIVR": "Found Case IVR",
			"agentUnavailableIVR": "Agent Unavailable IVR",
			"invalidRatingIVR": "Invalid Rating IVR",
			"openGreetingIVR": "Open Greeting IVR",
			"requestRatingIVR": "Request Rating IVR",
			"thankYouIVR": "Thank You IVR",
			"waitInQueueIVR": "WaitIn Queue IVR",
			"rocketChatDepartmentID": "RocketChatDepartmentID",
			"smsPhoneNumber": "Sms Phone Number",
			"latestSMSRequestBy": "Latest SMS Request By",
			"screenshots": "Screenshots",
			"latestSMSRequestByTime": "LatestSMSRequestByTime",
			"asteriskMode": "Asterisk Mode",
			"autoTicket": "Auto Ticket",
			"cannedMessages": "Canned Messages",
			"sMSs": "SMSs"
		},
		"links": {
			"contacts": "Contacts",
			"opportunities": "Opportunities",
			"cases": "Cases",
			"documents": "Documents",
			"meetingsPrimary": "Meetings (expanded)",
			"callsPrimary": "Calls (expanded)",
			"tasksPrimary": "Tasks (expanded)",
			"emailsPrimary": "Emails (expanded)",
			"targetLists": "Target Lists",
			"campaignLogRecords": "Campaign Log",
			"campaign": "Campaign",
			"portalUsers": "Portal Users",
			"originalLead": "Original Lead",
			"users": "Users",
			"phoneCalls": "Phone Calls",
			"screenshots": "Screenshots",
			"cannedMessages": "Canned Messages",
			"sMSs": "SMSs"
		},
		"options": {
			"type": {
				"Customer": "Customer",
				"Investor": "Investor",
				"Partner": "Partner",
				"Reseller": "Reseller"
			},
			"industry": {
				"Aerospace": "Aerospace",
				"Agriculture": "Agriculture",
				"Advertising": "Advertising",
				"Apparel & Accessories": "Apparel & Accessories",
				"Architecture": "Architecture",
				"Automotive": "Automotive",
				"Banking": "Banking",
				"Biotechnology": "Biotechnology",
				"Building Materials & Equipment": "Building Materials & Equipment",
				"Chemical": "Chemical",
				"Construction": "Construction",
				"Computer": "Computer",
				"Defense": "Defense",
				"Creative": "Creative",
				"Culture": "Culture",
				"Consulting": "Consulting",
				"Education": "Education",
				"Electronics": "Electronics",
				"Electric Power": "Electric Power",
				"Energy": "Energy",
				"Entertainment & Leisure": "Entertainment & Leisure",
				"Finance": "Finance",
				"Food & Beverage": "Food & Beverage",
				"Grocery": "Grocery",
				"Hospitality": "Hospitality",
				"Healthcare": "Healthcare",
				"Insurance": "Insurance",
				"Legal": "Legal",
				"Manufacturing": "Manufacturing",
				"Mass Media": "Mass Media",
				"Mining": "Mining",
				"Music": "Music",
				"Marketing": "Marketing",
				"Publishing": "Publishing",
				"Petroleum": "Petroleum",
				"Real Estate": "Real Estate",
				"Retail": "Retail",
				"Shipping": "Shipping",
				"Service": "Service",
				"Support": "Support",
				"Sports": "Sports",
				"Software": "Software",
				"Technology": "Technology",
				"Telecommunications": "Telecommunications",
				"Television": "Television",
				"Testing, Inspection & Certification": "Testing, Inspection & Certification",
				"Transportation": "Transportation",
				"Travel": "Travel",
				"Venture Capital": "Venture Capital",
				"Wholesale": "Wholesale",
				"Water": "Water"
			},
			"workdays": {
				"Monday": "Monday",
				"Tuesday": "Tuesday",
				"Wednesday": "Wednesday",
				"Thursday": "Thursday",
				"Friday": "Friday",
				"Saturday": "Saturday",
				"Sunday": "Sunday"
			},
			"holidays": {
				"New Year's Day": "New Year's Day",
				"Martin Luther King Jr. Day": "Martin Luther King Jr. Day"
			},
			"agentTemporaryLeave": [],
			"language": {
				"en-US": "en-US",
				"ja-JP": "ja-JP",
				"de-DE": "de-DE",
				"zh-CN": "zh-CN",
				"zh-HK": "zh-HK",
				"zh-TW": "zh-TW",
				"ru-RU": "ru-RU"
			},
			"voice": {
				"Polly.Joanna": "Polly.Joanna",
				"Polly.Matthew": "Polly.Matthew",
				"Polly.Mizuki": "Polly.Mizuki",
				"Polly.Takumi": "Polly.Takumi",
				"Polly.Hans": "Polly.Hans",
				"Polly.Vicki": "Polly.Vicki",
				"Polly.Zhiyu": "Polly.Zhiyu",
				"Polly.Maxim": "Polly.Maxim",
				"Polly.Tatyana": "Polly.Tatyana",
				"default": "default"
			},
			"autopilot": {
				"greeting": "greeting",
				"annke": "annke"
			},
			"asteriskMode": {
				"twilio": "twilio",
				"standalone": "standalone"
			}
		},
		"labels": {
			"Create Account": "Create Account",
			"Copy Billing": "Copy Billing",
			"Set Primary": "Set Primary"
		},
		"presetFilters": {
			"customers": "Customers",
			"partners": "Partners",
			"recentlyCreated": "Recently Created"
		}
	},
	"Calendar": {
		"modes": {
			"month": "Month",
			"week": "Week",
			"day": "Day",
			"agendaWeek": "Week",
			"agendaDay": "Day",
			"timeline": "Timeline"
		},
		"labels": {
			"Today": "Today",
			"Create": "Create",
			"Shared": "Shared",
			"Add User": "Add User",
			"current": "current",
			"time": "time",
			"User List": "User List",
			"Manage Users": "Manage Users",
			"View Calendar": "View Calendar",
			"Create Shared View": "Create Shared View"
		}
	},
	"Call": {
		"fields": {
			"name": "Name",
			"parent": "Parent",
			"status": "Status",
			"dateStart": "Date Start",
			"dateEnd": "Date End",
			"direction": "Direction",
			"duration": "Duration",
			"description": "Description",
			"users": "Users",
			"contacts": "Contacts",
			"leads": "Leads",
			"reminders": "Reminders",
			"account": "Account",
			"acceptanceStatus": "Acceptance Status",
			"link": "Link"
		},
		"links": [],
		"options": {
			"status": {
				"Planned": "Planned",
				"Held": "Held",
				"Not Held": "Not Held"
			},
			"direction": {
				"Outbound": "Outbound",
				"Inbound": "Inbound"
			},
			"acceptanceStatus": {
				"None": "None",
				"Accepted": "Accepted",
				"Declined": "Declined",
				"Tentative": "Tentative"
			}
		},
		"massActions": {
			"setHeld": "Set Held",
			"setNotHeld": "Set Not Held"
		},
		"labels": {
			"Create Call": "Create Reminder",
			"Set Held": "Set Held",
			"Set Not Held": "Set Not Held",
			"Send Invitations": "Send Invitations"
		},
		"presetFilters": {
			"planned": "Planned",
			"held": "Held",
			"todays": "Today's"
		}
	},
	"Campaign": {
		"fields": {
			"name": "Name",
			"description": "Description",
			"status": "Status",
			"type": "Type",
			"startDate": "Start Date",
			"endDate": "End Date",
			"targetLists": "Target Lists",
			"excludingTargetLists": "Excluding Target Lists",
			"sentCount": "Sent",
			"openedCount": "Opened",
			"clickedCount": "Clicked",
			"optedOutCount": "Opted Out",
			"bouncedCount": "Bounced",
			"optedInCount": "Opted In",
			"hardBouncedCount": "Hard Bounced",
			"softBouncedCount": "Soft Bounced",
			"leadCreatedCount": "Leads Created",
			"revenue": "Revenue",
			"revenueConverted": "Revenue (converted)",
			"budget": "Budget",
			"budgetConverted": "Budget (converted)",
			"budgetCurrency": "Budget Currency",
			"contactsTemplate": "Contacts Template",
			"leadsTemplate": "Leads Template",
			"accountsTemplate": "Accounts Template",
			"usersTemplate": "Users Template",
			"mailMergeOnlyWithAddress": "Skip records w\/o filled address"
		},
		"links": {
			"targetLists": "Target Lists",
			"excludingTargetLists": "Excluding Target Lists",
			"accounts": "Accounts",
			"contacts": "Contacts",
			"leads": "Leads",
			"opportunities": "Opportunities",
			"campaignLogRecords": "Log",
			"massEmails": "Mass Emails",
			"trackingUrls": "Tracking URLs",
			"contactsTemplate": "Contacts Template",
			"leadsTemplate": "Leads Template",
			"accountsTemplate": "Accounts Template",
			"usersTemplate": "Users Template"
		},
		"options": {
			"type": {
				"Email": "Email",
				"Web": "Web",
				"Television": "Television",
				"Radio": "Radio",
				"Newsletter": "Newsletter",
				"Mail": "Mail"
			},
			"status": {
				"Planning": "Planning",
				"Active": "Active",
				"Inactive": "Inactive",
				"Complete": "Complete"
			}
		},
		"labels": {
			"Create Campaign": "Create Campaign",
			"Target Lists": "Target Lists",
			"Statistics": "Statistics",
			"hard": "hard",
			"soft": "soft",
			"Unsubscribe": "Unsubscribe",
			"Mass Emails": "Mass Emails",
			"Email Templates": "Email Templates",
			"Unsubscribe again": "Unsubscribe again",
			"Subscribe again": "Subscribe again",
			"Create Target List": "Create Target List",
			"Mail Merge": "Mail Merge",
			"Generate Mail Merge PDF": "Generate Mail Merge PDF"
		},
		"presetFilters": {
			"active": "Active"
		},
		"messages": {
			"unsubscribed": "You have been unsubscribed from our mailing list.",
			"subscribedAgain": "You are subscribed again."
		},
		"tooltips": {
			"targetLists": "Targets that should receive messages.",
			"excludingTargetLists": "Targets that should not receive messages."
		}
	},
	"CampaignLogRecord": {
		"fields": {
			"action": "Action",
			"actionDate": "Date",
			"data": "Data",
			"campaign": "Campaign",
			"parent": "Target",
			"object": "Object",
			"application": "Application",
			"queueItem": "Queue Item",
			"stringData": "String Data",
			"stringAdditionalData": "String Additional Data",
			"isTest": "Is Test"
		},
		"links": {
			"queueItem": "Queue Item",
			"parent": "Parent",
			"object": "Object",
			"campaign": "Campaign"
		},
		"options": {
			"action": {
				"Sent": "Sent",
				"Opened": "Opened",
				"Opted Out": "Opted Out",
				"Bounced": "Bounced",
				"Clicked": "Clicked",
				"Lead Created": "Lead Created",
				"Opted In": "Opted In"
			}
		},
		"labels": {
			"All": "All"
		},
		"presetFilters": {
			"sent": "Sent",
			"opened": "Opened",
			"optedOut": "Opted Out",
			"optedIn": "Opted In",
			"bounced": "Bounced",
			"clicked": "Clicked",
			"leadCreated": "Lead Created"
		}
	},
	"CampaignTrackingUrl": {
		"fields": {
			"url": "URL",
			"action": "Action",
			"urlToUse": "Code to insert instead of URL",
			"message": "Message",
			"campaign": "Campaign"
		},
		"links": {
			"campaign": "Campaign"
		},
		"labels": {
			"Create CampaignTrackingUrl": "Create Tracking URL"
		},
		"options": {
			"action": {
				"Redirect": "Redirect",
				"Show Message": "Show Message"
			}
		},
		"tooltips": {
			"url": "The recipient will be redirected to this location after they follow the link.",
			"message": "The message will be shown to the recipient after they follow the link. Markdown is supported."
		}
	},
	"Case": {
		"fields": {
			"name": "Name",
			"number": "Number",
			"status": "Status",
			"account": "Account",
			"contact": "Contact",
			"contacts": "Contacts",
			"priority": "Priority",
			"type": "Type",
			"description": "Description",
			"inboundEmail": "Group Email Account",
			"lead": "Lead",
			"attachments": "Attachments",
			"phoneCalls": "Phone Calls",
			"sms": "SMS",
			"sMS": "SMS",
			"test1": "Test1",
			"chats": "Chats"
		},
		"links": {
			"inboundEmail": "Group Email Account",
			"account": "Account",
			"contact": "Contact (Primary)",
			"Contacts": "Contacts",
			"meetings": "Meetings",
			"calls": "Calls",
			"tasks": "Tasks",
			"emails": "Emails",
			"articles": "Knowledge Base Articles",
			"lead": "Lead",
			"attachments": "Attachments",
			"phoneCalls": "Phone Calls",
			"sMS": "SMS",
			"chats": "Chats"
		},
		"options": {
			"status": {
				"New": "New",
				"Assigned": "Assigned",
				"Pending": "Pending",
				"Closed": "Closed",
				"Rejected": "Rejected",
				"Duplicate": "Duplicate",
				"In Progres": "In Progres"
			},
			"priority": {
				"Low": "Low",
				"Normal": "Normal",
				"High": "High",
				"Urgent": "Urgent"
			},
			"type": {
				"Question": "Question",
				"Incident": "Incident",
				"Problem": "Problem",
				"": "",
				"Return": "Return",
				"Refund": "Refund"
			},
			"sms": {
				"": "",
				"Open SMS Chat": "Open SMS Chat"
			},
			"test1": {
				"Q1. aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa": "Q1. aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
				"Q2. bbbbbbbbbbbbbbbbbbbbbbbbbbbbbb": "Q2. bbbbbbbbbbbbbbbbbbbbbbbbbbbbbb"
			}
		},
		"labels": {
			"Create Case": "Create Ticket",
			"Close": "Close",
			"Reject": "Reject",
			"Closed": "Closed",
			"Rejected": "Rejected"
		},
		"presetFilters": {
			"open": "Open",
			"closed": "Closed"
		}
	},
	"Contact": {
		"fields": {
			"name": "Name",
			"emailAddress": "Email",
			"title": "Account Title",
			"account": "Account",
			"accounts": "Accounts",
			"phoneNumber": "Phone",
			"accountType": "Account Type",
			"doNotCall": "Do Not Call",
			"address": "Address",
			"opportunityRole": "Opportunity Role",
			"accountRole": "Title",
			"description": "Description",
			"campaign": "Campaign",
			"targetLists": "Target Lists",
			"targetList": "Target List",
			"portalUser": "Portal User",
			"hasPortalUser": "Has Portal User",
			"originalLead": "Original Lead",
			"acceptanceStatus": "Acceptance Status",
			"accountIsInactive": "Account Inactive",
			"acceptanceStatusMeetings": "Acceptance Status (Meetings)",
			"acceptanceStatusCalls": "Acceptance Status (Calls)",
			"phoneCalls": "Phone Calls",
			"mobilePhone": "Mobile Phone",
			"sMS": "SMS",
			"addressStreet2": "Street2",
			"startSMS": "Start SMS",
			"dial": "Dial",
			"chats": "Chats"
		},
		"links": {
			"opportunities": "Opportunities",
			"cases": "Cases",
			"targetLists": "Target Lists",
			"campaignLogRecords": "Campaign Log",
			"campaign": "Campaign",
			"account": "Account (Primary)",
			"accounts": "Accounts",
			"casesPrimary": "Cases (Primary)",
			"tasksPrimary": "Tasks (expanded)",
			"opportunitiesPrimary": "Opportunities (Primary)",
			"portalUser": "Portal User",
			"originalLead": "Original Lead",
			"documents": "Documents",
			"phoneCalls": "Phone Calls",
			"sMS": "SMS",
			"chats": "Chats"
		},
		"labels": {
			"Create Contact": "Create Customer"
		},
		"options": {
			"opportunityRole": {
				"": "",
				"Decision Maker": "Decision Maker",
				"Evaluator": "Evaluator",
				"Influencer": "Influencer"
			},
			"startSMS": {
				"": "",
				"Start Now": "Start Now"
			}
		},
		"presetFilters": {
			"portalUsers": "Portal Users",
			"notPortalUsers": "Not Portal Users",
			"accountActive": "Active"
		}
	},
	"Document": {
		"labels": {
			"Create Document": "Create Document",
			"Details": "Details"
		},
		"fields": {
			"name": "Name",
			"status": "Status",
			"file": "File",
			"type": "Type",
			"publishDate": "Publish Date",
			"expirationDate": "Expiration Date",
			"description": "Description",
			"accounts": "Accounts",
			"folder": "Folder"
		},
		"links": {
			"accounts": "Accounts",
			"opportunities": "Opportunities",
			"folder": "Folder",
			"leads": "Leads",
			"contacts": "Contacts"
		},
		"options": {
			"status": {
				"Active": "Active",
				"Draft": "Draft",
				"Expired": "Expired",
				"Canceled": "Canceled"
			},
			"type": {
				"": "None",
				"Contract": "Contract",
				"NDA": "NDA",
				"EULA": "EULA",
				"License Agreement": "License Agreement"
			}
		},
		"presetFilters": {
			"active": "Active",
			"draft": "Draft"
		}
	},
	"DocumentFolder": {
		"labels": {
			"Create DocumentFolder": "Create Document Folder",
			"Manage Categories": "Manage Folders",
			"Documents": "Documents"
		},
		"links": {
			"documents": "Documents"
		}
	},
	"EmailQueueItem": {
		"fields": {
			"name": "Name",
			"status": "Status",
			"target": "Target",
			"sentAt": "Date Sent",
			"attemptCount": "Attempts",
			"emailAddress": "Email Address",
			"massEmail": "Mass Email",
			"isTest": "Is Test"
		},
		"links": {
			"target": "Target",
			"massEmail": "Mass Email"
		},
		"options": {
			"status": {
				"Pending": "Pending",
				"Sent": "Sent",
				"Failed": "Failed",
				"Sending": "Sending"
			}
		},
		"presetFilters": {
			"pending": "Pending",
			"sent": "Sent",
			"failed": "Failed"
		}
	},
	"KnowledgeBaseArticle": {
		"labels": {
			"Create KnowledgeBaseArticle": "Create Article",
			"Any": "Any",
			"Send in Email": "Send in Email",
			"Move Up": "Move Up",
			"Move Down": "Move Down",
			"Move to Top": "Move to Top",
			"Move to Bottom": "Move to Bottom"
		},
		"fields": {
			"name": "Name",
			"status": "Status",
			"type": "Type",
			"attachments": "Attachments",
			"publishDate": "Publish Date",
			"expirationDate": "Expiration Date",
			"description": "Description",
			"body": "Body",
			"categories": "Categories",
			"language": "Language",
			"portals": "Portals"
		},
		"links": {
			"cases": "Cases",
			"opportunities": "Opportunities",
			"categories": "Categories",
			"portals": "Portals"
		},
		"options": {
			"status": {
				"In Review": "In Review",
				"Draft": "Draft",
				"Archived": "Archived",
				"Published": "Published"
			},
			"type": {
				"Article": "Article"
			}
		},
		"tooltips": {
			"portals": "Article will be available only in specified portals."
		},
		"presetFilters": {
			"published": "Published"
		}
	},
	"KnowledgeBaseCategory": {
		"labels": {
			"Create KnowledgeBaseCategory": "Create Category",
			"Manage Categories": "Manage Categories",
			"Articles": "Articles"
		},
		"links": {
			"articles": "Articles"
		}
	},
	"Lead": {
		"labels": {
			"Converted To": "Converted To",
			"Create Lead": "Create Lead",
			"Convert": "Convert",
			"convert": "convert"
		},
		"fields": {
			"name": "Name",
			"emailAddress": "Email",
			"title": "Title",
			"website": "Website",
			"phoneNumber": "Phone",
			"accountName": "Account Name",
			"doNotCall": "Do Not Call",
			"address": "Address",
			"status": "Status",
			"source": "Source",
			"opportunityAmount": "Opportunity Amount",
			"opportunityAmountConverted": "Opportunity Amount (converted)",
			"description": "Description",
			"createdAccount": "Account",
			"createdContact": "Contact",
			"createdOpportunity": "Opportunity",
			"convertedAt": "Converted At",
			"campaign": "Campaign",
			"targetLists": "Target Lists",
			"targetList": "Target List",
			"industry": "Industry",
			"acceptanceStatus": "Acceptance Status",
			"opportunityAmountCurrency": "Opportunity Amount Currency",
			"acceptanceStatusMeetings": "Acceptance Status (Meetings)",
			"acceptanceStatusCalls": "Acceptance Status (Calls)"
		},
		"links": {
			"targetLists": "Target Lists",
			"campaignLogRecords": "Campaign Log",
			"campaign": "Campaign",
			"createdAccount": "Account",
			"createdContact": "Contact",
			"createdOpportunity": "Opportunity",
			"cases": "Cases",
			"documents": "Documents"
		},
		"options": {
			"status": {
				"New": "New",
				"Assigned": "Assigned",
				"In Process": "In Process",
				"Converted": "Converted",
				"Recycled": "Recycled",
				"Dead": "Dead"
			},
			"source": {
				"": "None",
				"Call": "Call",
				"Email": "Email",
				"Existing Customer": "Existing Customer",
				"Partner": "Partner",
				"Public Relations": "Public Relations",
				"Web Site": "Web Site",
				"Campaign": "Campaign",
				"Other": "Other"
			}
		},
		"presetFilters": {
			"active": "Active",
			"actual": "Actual",
			"converted": "Converted"
		}
	},
	"MassEmail": {
		"fields": {
			"name": "Name",
			"status": "Status",
			"storeSentEmails": "Store Sent Emails",
			"startAt": "Date Start",
			"fromAddress": "From Address",
			"fromName": "From Name",
			"replyToAddress": "Reply-to Address",
			"replyToName": "Reply-to Name",
			"campaign": "Campaign",
			"emailTemplate": "Email Template",
			"inboundEmail": "Email Account",
			"targetLists": "Target Lists",
			"excludingTargetLists": "Excluding Target Lists",
			"optOutEntirely": "Opt-Out Entirely",
			"smtpAccount": "SMTP Account"
		},
		"links": {
			"targetLists": "Target Lists",
			"excludingTargetLists": "Excluding Target Lists",
			"queueItems": "Queue Items",
			"campaign": "Campaign",
			"emailTemplate": "Email Template",
			"inboundEmail": "Email Account"
		},
		"options": {
			"status": {
				"Draft": "Draft",
				"Pending": "Pending",
				"In Process": "In Process",
				"Complete": "Complete",
				"Canceled": "Canceled",
				"Failed": "Failed"
			}
		},
		"labels": {
			"Create MassEmail": "Create Mass Email",
			"Send Test": "Send Test",
			"System SMTP": "System SMTP",
			"system": "system",
			"group": "group"
		},
		"messages": {
			"selectAtLeastOneTarget": "Select at least one target.",
			"testSent": "Test email(s) supposed to be sent"
		},
		"tooltips": {
			"optOutEntirely": "Email addresses of recipients that unsubscribed will be marked as opted out and they will not receive any mass emails anymore.",
			"targetLists": "Targets that should receive messages.",
			"excludingTargetLists": "Targets that should not receive messages.",
			"storeSentEmails": "Emails will be stored in CRM."
		},
		"presetFilters": {
			"actual": "Actual",
			"complete": "Complete"
		}
	},
	"Meeting": {
		"fields": {
			"name": "Name",
			"parent": "Parent",
			"status": "Status",
			"dateStart": "Date Start",
			"dateEnd": "Date End",
			"duration": "Duration",
			"description": "Description",
			"users": "Users",
			"contacts": "Contacts",
			"leads": "Leads",
			"reminders": "Reminders",
			"account": "Account",
			"acceptanceStatus": "Acceptance Status",
			"dateStartDate": "Date Start (all day)",
			"dateEndDate": "Date End (all day)",
			"isAllDay": "Is All-Day",
			"Acceptance": "Acceptance"
		},
		"links": [],
		"options": {
			"status": {
				"Planned": "Planned",
				"Held": "Held",
				"Not Held": "Not Held"
			},
			"acceptanceStatus": {
				"None": "None",
				"Accepted": "Accepted",
				"Declined": "Declined",
				"Tentative": "Tentative"
			}
		},
		"massActions": {
			"setHeld": "Set Held",
			"setNotHeld": "Set Not Held"
		},
		"labels": {
			"Create Meeting": "Create Meeting",
			"Set Held": "Set Held",
			"Set Not Held": "Set Not Held",
			"Send Invitations": "Send Invitations",
			"on time": "on time",
			"before": "before",
			"All-Day": "All-Day"
		},
		"presetFilters": {
			"planned": "Planned",
			"held": "Held",
			"todays": "Today's"
		},
		"messages": {
			"selectAcceptanceStatus": "Set your acceptance status.",
			"nothingHasBeenSent": "Nothing were sent"
		}
	},
	"Opportunity": {
		"fields": {
			"name": "Name",
			"account": "Account",
			"stage": "Stage",
			"amount": "Amount",
			"probability": "Probability, %",
			"leadSource": "Lead Source",
			"doNotCall": "Do Not Call",
			"closeDate": "Close Date",
			"contacts": "Contacts",
			"contact": "Contact (Primary)",
			"description": "Description",
			"amountConverted": "Amount (converted)",
			"amountWeightedConverted": "Amount Weighted",
			"campaign": "Campaign",
			"originalLead": "Original Lead",
			"amountCurrency": "Amount Currency",
			"contactRole": "Contact Role",
			"lastStage": "Last Stage"
		},
		"links": {
			"contacts": "Contacts",
			"contact": "Contact (Primary)",
			"documents": "Documents",
			"campaign": "Campaign",
			"originalLead": "Original Lead"
		},
		"options": {
			"stage": {
				"Prospecting": "Prospecting",
				"Qualification": "Qualification",
				"Proposal": "Proposal",
				"Negotiation": "Negotiation",
				"Needs Analysis": "Needs Analysis",
				"Value Proposition": "Value Proposition",
				"Id. Decision Makers": "Id. Decision Makers",
				"Perception Analysis": "Perception Analysis",
				"Proposal\/Price Quote": "Proposal\/Price Quote",
				"Negotiation\/Review": "Negotiation\/Review",
				"Closed Won": "Closed Won",
				"Closed Lost": "Closed Lost"
			}
		},
		"labels": {
			"Create Opportunity": "Create Opportunity"
		},
		"presetFilters": {
			"open": "Open",
			"won": "Won",
			"lost": "Lost"
		}
	},
	"TargetList": {
		"fields": {
			"name": "Name",
			"description": "Description",
			"entryCount": "Entry Count",
			"optedOutCount": "Opted Out Count",
			"campaigns": "Campaigns",
			"endDate": "End Date",
			"targetLists": "Target Lists",
			"includingActionList": "Including",
			"excludingActionList": "Excluding",
			"targetStatus": "Target Status",
			"isOptedOut": "Is Opted Out",
			"syncWithReports": "Reports",
			"syncWithReportsEnabled": "Enabled",
			"syncWithReportsUnlink": "Unlink"
		},
		"links": {
			"accounts": "Accounts",
			"contacts": "Contacts",
			"leads": "Leads",
			"campaigns": "Campaigns",
			"massEmails": "Mass Emails",
			"syncWithReports": "Sync with Reports"
		},
		"options": {
			"type": {
				"Email": "Email",
				"Web": "Web",
				"Television": "Television",
				"Radio": "Radio",
				"Newsletter": "Newsletter"
			},
			"targetStatus": {
				"Opted Out": "Opted Out",
				"Listed": "Listed"
			}
		},
		"labels": {
			"Create TargetList": "Create Target List",
			"Opted Out": "Opted Out",
			"Cancel Opt-Out": "Cancel Opt-Out",
			"Opt-Out": "Opt-Out",
			"Sync with Reports": "Sync with Reports"
		},
		"tooltips": {
			"syncWithReportsUnlink": "Entries which are not contained in report results will be unlinked from Target List.",
			"syncWithReports": "Target List will be synced with results of selected reports."
		}
	},
	"Task": {
		"fields": {
			"name": "Name",
			"parent": "Parent",
			"status": "Status",
			"dateStart": "Date Start",
			"dateEnd": "Date Due",
			"dateStartDate": "Date Start (all day)",
			"dateEndDate": "Date End (all day)",
			"priority": "Priority",
			"description": "Description",
			"isOverdue": "Is Overdue",
			"account": "Account",
			"dateCompleted": "Date Completed",
			"attachments": "Attachments",
			"reminders": "Reminders",
			"contact": "Contact",
			"assignedUsers": "Assigned Users"
		},
		"links": {
			"attachments": "Attachments",
			"account": "Account",
			"contact": "Contact",
			"assignedUsers": "Users"
		},
		"options": {
			"status": {
				"Not Started": "Not Started",
				"Started": "Started",
				"Completed": "Completed",
				"Canceled": "Canceled",
				"Deferred": "Deferred"
			},
			"priority": {
				"Low": "Low",
				"Normal": "Normal",
				"High": "High",
				"Urgent": "Urgent"
			}
		},
		"labels": {
			"Create Task": "Create Task",
			"Complete": "Complete"
		},
		"presetFilters": {
			"actual": "Actual",
			"completed": "Completed",
			"deferred": "Deferred",
			"todays": "Today's",
			"overdue": "Overdue"
		}
	},
	"BpmnFlowNode": {
		"labels": {
			"Reset": "Reset"
		},
		"fields": {
			"status": "Status",
			"processedAt": "Processed At",
			"elementType": "Element Type",
			"element": "Element"
		},
		"options": {
			"status": {
				"Created": "Created",
				"Pending": "Pending",
				"In Process": "In Process",
				"Standby": "Standby",
				"Processed": "Processed",
				"Rejected": "Rejected",
				"Failed": "Failed",
				"Interrupted": "Interrupted"
			}
		}
	},
	"BpmnFlowchart": {
		"labels": {
			"Create BpmnFlowchart": "Create Flowchart",
			"Hand tool": "Hand tool",
			"Create Event tool": "Create Event tool",
			"Create Gateway tool": "Create Event tool",
			"Create Activity tool": "Create Activity tool",
			"Connect tool": "Connect tool",
			"Erase tool": "Erase tool",
			"Full Screen": "Full Screen",
			"Processes": "Processes",
			"data": "Data",
			"Zoom In": "Zoom In",
			"Zoom Out": "Zoom Out",
			"Error": "Error"
		},
		"fields": {
			"isActive": "Is Active",
			"targetType": "Target Entity Type",
			"data": "Data",
			"hasNoneStartEvent": "Has Start Event of None type"
		},
		"links": {
			"processes": "Processes"
		},
		"elements": {
			"eventStartConditional": "Conditional Start Event",
			"eventStartTimer": "Timer Start Event",
			"eventStartError": "Error Start Event",
			"eventStartEscalation": "Escalation Start Event",
			"eventStartSignal": "Signal Start Event",
			"eventStart": "Start Event",
			"eventStartConditionalEventSubProcess": "Sub-Process Conditional Start Event",
			"eventStartTimerEventSubProcess": "Sub-Process Timer Start Event",
			"eventStartSignalEventSubProcess": "Sub-Process Signal Start Event",
			"eventIntermediateTimerCatch": "Timer Intermediate Event (Catching)",
			"eventIntermediateConditionalCatch": "Conditional Intermediate Event (Catching)",
			"eventIntermediateEscalationThrow": "Escalation Intermediate Event (Throwing)",
			"eventIntermediateSignalThrow": "Signal Intermediate Event (Throwing)",
			"eventIntermediateSignalCatch": "Signal Intermediate Event (Catching)",
			"eventIntermediateMessageCatch": "Message Intermediate Event (Catching)",
			"eventEnd": "End Event",
			"eventEndTerminate": "Terminate End Event",
			"eventEndError": "Error End Event",
			"eventEndEscalation": "Escalation End Event",
			"eventEndSignal": "Signal End Event",
			"eventIntermediateErrorBoundary": "Error Intermediate Event (Boundary)",
			"eventIntermediateTimerBoundary": "Timer Intermediate Event (Boundary)",
			"eventIntermediateConditionalBoundary": "Conditional Intermediate Event (Boundary)",
			"eventIntermediateEscalationBoundary": "Escalation Intermediate Event (Boundary)",
			"eventIntermediateSignalBoundary": "Signal Intermediate Event (Boundary)",
			"eventIntermediateMessageBoundary": "Message Intermediate Event (Boundary)",
			"gatewayExclusive": "Exclusive Gateway",
			"gatewayInclusive": "Inclusive Gateway",
			"gatewayParallel": "Parallel Gateway",
			"gatewayEventBased": "Event Based Gateway",
			"taskSendMessage": "Send Message Task",
			"taskScript": "Script Task",
			"taskBusinessRule": "Business Rule Task",
			"taskUser": "User Task",
			"task": "Task",
			"callActivity": "Call Activity",
			"subProcess": "Sub-Process",
			"eventSubProcess": "Event Sub-Process",
			"flow": "Sequence Flow"
		},
		"presetFilters": {
			"isManuallyStartable": "Manually Startable",
			"activeHasNoneStartEvent": "Active w\/ None Start Event",
			"active": "Active"
		}
	},
	"BpmnFlowchartElement": {
		"fields": {
			"text": "Text",
			"triggerType": "Trigger Type",
			"timer": "Timer Parameters",
			"defaultFlowId": "Default Flow",
			"from": "From",
			"to": "To",
			"replyTo": "Reply-To",
			"fromEmailAddress": "From Email Address",
			"toEmailAddress": "To Email Address",
			"replyToEmailAddress": "Reply-To Email Address",
			"toSpecifiedTeams": "To Teams",
			"toSpecifiedUsers": "To Users",
			"toSpecifiedContacts": "To Contacts",
			"emailTemplate": "Email Template",
			"doNotStore": "Do not store sent email",
			"actions": "Actions",
			"formula": "Formula (script)",
			"actionType": "Action Type",
			"targetUser": "Target User",
			"assignmentType": "Assignment",
			"targetTeam": "Target Team",
			"targetUserPosition": "Target User Position",
			"startDirection": "Start Direction",
			"targetReport": "Target Report",
			"scheduling": "Scheduling",
			"messageType": "Message Type",
			"canBeFailed": "Can be Failed",
			"target": "Target",
			"callableType": "Callable Type",
			"errorCode": "Error Code",
			"escalationCode": "Escalation Code",
			"cancelActivity": "Is Interrupting",
			"isInterrupting": "Is Interrupting",
			"targetType": "Target Entity Type",
			"flowchartVisualization": "Flowchart",
			"flowchart": "Flowchart",
			"signal": "Signal",
			"returnVariableList": "Return Variables",
			"repliedTo": "Reply To",
			"relatedTo": "Related To",
			"instructions": "Instructions",
			"conditionsFormula": "Conditions Formula",
			"optOutLink": "Opt-Out Link"
		},
		"labels": {
			"Conditions": "Conditions",
			"Actions": "Actions",
			"Field": "Field",
			"Flows Conditions": "Flows Conditions"
		},
		"tooltips": {
			"taskSendMessageEmailAddress": "Available placeholders:\n\n* `{$$variable}`",
			"targetReport": "Records from the list report will be passed to the new process.",
			"target": "Select which record will be used as a target.",
			"userTaskName": "A name of User Task record that will be created.\n\nAvailable placeholders:\n* `{$attribute}`\n* `{$$variable}`",
			"userTaskInstructions": "Instructions for a user. Markdown is supported.\n\nAvailable placeholders:\n* `{$attribute}`\n* `{$$variable}`",
			"returnVariableList": "Specify formula variables that will be copied from the sub-process to the parent process once the sub-process successfully ends.",
			"scheduling": "Crontab notation. Defines frequency. UTC timezone.\n\n*\/5 * * * * - every 5 minutes\n\n0 *\/2 * * * - every 2 hours\n\n30 1 * * * - at 01:30 once a day\n\n0 0 1 * * - on the first day of the month"
		},
		"options": {
			"emailAddress": {
				"system": "System",
				"currentUser": "Current User",
				"specifiedEmailAddress": "Specified Email Address",
				"assignedUser": "Assigned User",
				"followers": "Followers",
				"specifiedContacts": "Specified Contacts",
				"specifiedUsers": "Specified Users",
				"specifiedTeams": "Specified Teams",
				"followersExcludingAssignedUser": "Followers excluding Assigned User",
				"processAssignedUser": "User assigned to Process",
				"targetEntity": "Target Record",
				"": "None"
			},
			"triggerType": {
				"afterRecordCreated": "After record created",
				"afterRecordSaved": "After record saved",
				"sequential": "Sequential"
			},
			"timerShiftOperator": {
				"plus": "plus",
				"minus": "minus"
			},
			"timerShiftUnits": {
				"minutes": "minutes",
				"hours": "hours",
				"days": "days",
				"months": "months",
				"seconds": "seconds"
			},
			"timerBase": {
				"moment": "Moment when event triggered",
				"formula": "Calculated by formula"
			},
			"actionType": {
				"Approve": "Approve",
				"Review": "Review"
			},
			"assignmentType": {
				"": "None",
				"processAssignedUser": "User assigned to Process",
				"specifiedUser": "Specified User",
				"rule:Round-Robin": "Round-Robin",
				"rule:Least-Busy": "Least-Busy"
			},
			"startDirection": {
				"": "Auto",
				"r": "Right",
				"d": "Down",
				"u": "Up",
				"l": "Left"
			},
			"messageType": {
				"Email": "Email"
			}
		}
	},
	"BpmnProcess": {
		"labels": {
			"Create BpmnProcess": "Start Process",
			"Stop Process": "Stop Process",
			"User Tasks": "User Tasks",
			"Flowcharts": "Flowcharts",
			"Interrupt": "Interrupt",
			"Reject": "Reject",
			"Start flow from here": "Start flow from here"
		},
		"fields": {
			"status": "Status",
			"targetType": "Target Entity Type",
			"target": "Target",
			"createdEntitiesData": "Created Entities Data",
			"flowchartData": "Flowchart Data",
			"flowchart": "Flowchart",
			"flowchartVisualization": "Flowchart (visualization)",
			"flowchartElementsDataHash": "Flowchart Elements",
			"variables": "Variables",
			"endedAt": "Ended At",
			"startElementId": "Start Element",
			"parentProcess": "Parent Process",
			"parentProcessFlowNode": "Parent Process Flow Node"
		},
		"links": {
			"flowchart": "Flowchart",
			"target": "Target",
			"flowNodes": "Flow Log",
			"userTasks": "Process User Tasks",
			"childProcesses": "Child Processes",
			"parentProcess": "Parent Process",
			"parentProcessFlowNode": "Parent Process Flow Node"
		},
		"options": {
			"status": {
				"Created": "Created",
				"Started": "Started",
				"Ended": "Ended",
				"Paused": "Paused",
				"Stopped": "Stopped",
				"Interrupted": "Interrupted"
			}
		},
		"presetFilters": {
			"actual": "Actual",
			"ended": "Ended"
		}
	},
	"BpmnUserTask": {
		"labels": {
			"Resolve": "Resolve"
		},
		"fields": {
			"actionType": "Action Type",
			"resolution": "Resolution",
			"target": "Target",
			"process": "Process",
			"isResolved": "Is Resolved",
			"resolutionNote": "Resolution Note",
			"instructions": "Instructions",
			"isCanceled": "Is Canceled"
		},
		"links": {
			"process": "Process",
			"target": "Target",
			"flowNode": "Flow Node"
		},
		"options": {
			"actionType": {
				"Approve": "Approve",
				"Review": "Review",
				"Accomplish": "Accomplish"
			},
			"resolution": {
				"": "None",
				"Approved": "Approved",
				"Rejected": "Rejected",
				"Reviewed": "Reviewed",
				"Completed": "Completed",
				"Canceled": "Canceled"
			}
		},
		"presetFilters": {
			"actual": "Actual",
			"resolved": "Resolved",
			"canceled": "Canceled"
		}
	},
	"Report": {
		"labels": {
			"Create Report": "Create Report",
			"Run": "Run",
			"Total": "Total",
			"-Empty-": "-Empty-",
			"Parameters": "Parameters",
			"Filters": "Filters",
			"Chart": "Chart",
			"List Report": "List Report",
			"Grid Report": "Grid Report",
			"days": "days",
			"never": "never",
			"Get Csv": "Get Csv",
			"EmailSending": "Email Sending",
			"View Report": "View Report",
			"Report": "Report",
			"AND": "AND",
			"OR": "OR",
			"NOT": "NOT IN",
			"IN": "IN",
			"Complex expression": "Complex expression",
			"Having": "Having",
			"Add AND group": "Add AND group",
			"Add OR group": "Add OR group",
			"Add NOT group": "Add NOT group",
			"Add IN group": "Add IN group",
			"Add Having group": "Add Having group",
			"Add Complex expression": "Add Complex expression",
			"Columns": "Columns",
			"Send Email": "Send Email",
			"Results View": "Results View",
			"Create Joint Grid Report": "Create Joint Grid Report"
		},
		"fields": {
			"type": "Type",
			"entityType": "Entity Type",
			"description": "Description",
			"groupBy": "Group by",
			"columns": "Columns",
			"orderBy": "Order by",
			"filters": "Filters",
			"runtimeFilters": "Runtime Filters",
			"chartType": "Chart Type",
			"emailSendingInterval": "Interval",
			"emailSendingTime": "Time",
			"emailSendingUsers": "Users",
			"emailSendingSettingDay": "Day",
			"emailSendingSettingMonth": "Month",
			"emailSendingSettingWeekdays": "Days",
			"emailSendingDoNotSendEmptyReport": "Don't send if report is empty",
			"chartColorList": "Colors",
			"chartColor": "Color",
			"orderByList": "List Order",
			"column": "Column",
			"exportFormat": "Format",
			"category": "Category",
			"applyAcl": "Apply ACL",
			"portals": "Portals",
			"joinedReports": "Sub-Reports",
			"joinedReportLabel": "Sub-Report Label"
		},
		"tooltips": {
			"emailSendingUsers": "Users report result will be sent to",
			"chartColorList": "Custom colors for specific groups.",
			"applyAcl": "Report results will depend on the user's access.",
			"groupBy": "Data will be aggregated by one or two groups. If empty, then data will not be aggregated, only totals will be displayed.\n\n[Complex expressions](https:\/\/www.espocrm.com\/documentation\/user-guide\/complex-expressions\/) can be used.",
			"columns": "What data to display.",
			"runtimeFilters": "Additional filters that will be available on the report view.",
			"portals": "Report will be available only in specified portals."
		},
		"functions": {
			"COUNT": "Count",
			"SUM": "Sum",
			"AVG": "Avg",
			"MIN": "Min",
			"MAX": "Max",
			"YEAR": "Year",
			"QUARTER": "Quarter",
			"MONTH": "Month",
			"DAY": "Day",
			"WEEK": "Week",
			"YEAR_FISCAL": "Fiscal Year",
			"QUARTER_FISCAL": "Fiscal Quarter"
		},
		"orders": {
			"ASC": "ASC",
			"DESC": "DESC",
			"LIST": "LIST"
		},
		"options": {
			"chartType": {
				"BarHorizontal": "Bar (horizontal)",
				"BarVertical": "Bar (vertical)",
				"BarGroupedHorizontal": "Bar Grouped (horizontal)",
				"BarGroupedVertical": "Bar Grouped (vertical)",
				"Pie": "Pie",
				"Line": "Line"
			},
			"emailSendingInterval": {
				"": "None",
				"Daily": "Daily",
				"Weekly": "Weekly",
				"Monthly": "Monthly",
				"Yearly": "Yearly"
			},
			"emailSendingSettingDay": {
				"32": "Last day of month"
			},
			"type": {
				"Grid": "Grid",
				"List": "List",
				"JointGrid": "Joint Grid"
			},
			"function": {
				"": "No Function",
				"custom": "Expression",
				"customWithOperator": "Expression w\/ Operator",
				"DATE_NUMBER": "DATE",
				"MONTH_NUMBER": "MONTH",
				"YEAR_NUMBER": "YEAR",
				"QUARTER_NUMBER": "QUARTER",
				"DAYOFWEEK_NUMBER": "DAYOFWEEK",
				"HOUR_NUMBER": "HOUR",
				"MINUTE_NUMBER": "MINUTE",
				"LOWER": "LOWER",
				"UPPER": "UPPER",
				"TRIM": "TRIM",
				"LENGTH": "LENGTH",
				"WEEK_NUMBER_0": "WEEK (Sunday)",
				"WEEK_NUMBER_1": "WEEK (Monday)",
				"COUNT": "COUNT",
				"SUM": "SUM",
				"AVG": "AVG",
				"MAX": "MAX",
				"MIN": "MIN"
			},
			"operator": {
				"equals": "Equals",
				"notEquals": "Not Equals",
				"greaterThan": "Greater Than",
				"lessThan": "Less Than",
				"greaterThanOrEquals": "Greater Than or Equals",
				"lessThanOrEquals": "Less Than or Equals",
				"in": "In",
				"notIn": "Not In",
				"isTrue": "Is True",
				"isFalse": "Is False",
				"isNull": "Is Null",
				"isNotNull": "Is Not Null",
				"like": "Like"
			},
			"exportFormat": {
				"csv": "CSV",
				"xlsx": "XLSX (Excel)"
			},
			"layoutAlign": {
				"left": "Left",
				"right": "Right"
			}
		},
		"messages": {
			"validateMaxCount": "Count should not be greater than {maxCount}",
			"gridReportDescription": "Group by one or two columns and see summations. Can be displayed as a chart.",
			"listReportDescription": "Simple list of records which meet filters criteria."
		},
		"presetFilters": {
			"list": "List",
			"grid": "Grid",
			"listTargets": "List (Targets)",
			"listAccounts": "List (Accounts)",
			"listContacts": "List (Contacts)",
			"listLeads": "List (Leads)",
			"listUsers": "List (Users)"
		},
		"errorMessages": {
			"error": "Error",
			"noChart": "No chart selected for the report.",
			"selectReport": "Select a report in the dashlet options."
		},
		"filtersGroupTypes": {
			"or": "OR",
			"and": "AND",
			"not": "NOT IN",
			"subQueryIn": "IN",
			"having": "Having"
		},
		"layoutFields": {
			"link": "Link",
			"width": "Width",
			"notSortable": "Not Sortable",
			"exportOnly": "Export Only",
			"align": "Align"
		}
	},
	"ReportCategory": {
		"labels": {
			"Create ReportCategory": "Create Category",
			"Manage Categories": "Manage Categories",
			"Reports": "Reports"
		},
		"fields": {
			"order": "Order"
		},
		"links": {
			"reports": "Reports"
		}
	},
	"ReportFilter": {
		"labels": {
			"Create ReportFilter": "Create Filter",
			"Rebuild Filters": "Rebuild Filters"
		},
		"fields": {
			"order": "Order",
			"report": "Report",
			"entityType": "Entity Type",
			"isActive": "Is Enabled"
		},
		"links": {
			"report": "Report"
		},
		"tooltips": {
			"teams": "Teams the filter will be available for. If no teams specified then no restriction by team will be applied.",
			"report": "List Report that will be used for the filter."
		}
	},
	"ReportPanel": {
		"labels": {
			"Create ReportPanel": "Create Panel",
			"Rebuild Panels": "Rebuild Panels"
		},
		"fields": {
			"report": "Report",
			"entityType": "Entity Type",
			"isActive": "Is Enabled",
			"type": "Type",
			"reportType": "Report Type",
			"displayTotal": "Display Total",
			"displayOnlyTotal": "Display Only Total",
			"column": "Column",
			"reportEntityType": "Report Entity Type",
			"columnList": "Column List",
			"dynamicLogicVisible": "Conditions making panel visible",
			"order": "Order",
			"useSiMultiplier": "SI Multiplier"
		},
		"links": {
			"report": "Report"
		},
		"tooltips": {
			"teams": "Teams the panel will be displayed for. If no teams specified then no restriction by team will be applied.",
			"report": "Report that will be used for the panel.",
			"order": "[0..1] - before Stream panel;\n[3..4] - before relationship panels;\n[6..] - after relationship panels."
		},
		"options": {
			"type": {
				"side": "Side",
				"bottom": "Bottom"
			}
		}
	},
	"Workflow": {
		"fields": {
			"Name": "Name",
			"entityType": "Target Entity",
			"type": "Trigger Type",
			"isActive": "Active",
			"description": "Description",
			"usersToMakeToFollow": "Users to make to follow the record",
			"whatToFollow": "What to follow",
			"portalOnly": "Portal Only",
			"portal": "Portal",
			"targetReport": "Target Report",
			"scheduling": "Scheduling",
			"methodName": "Service Method",
			"assignmentRule": "Assignment Rule",
			"targetTeam": "Target Team",
			"targetUserPosition": "Target User Position",
			"listReport": "List Report",
			"linkList": "Link with Target Entity through relationships",
			"linkListShort": "Links",
			"target": "Target",
			"whoFollow": "Who make to follow",
			"signalName": "Signal",
			"requestType": "Request Type",
			"requestUrl": "URL",
			"requestContentType": "Content Type",
			"requestContent": "Payload",
			"optOutLink": "Opt-Out Link",
			"headers": "Headers"
		},
		"links": {
			"portal": "Portal",
			"targetReport": "Target Report",
			"workflowLogRecords": "Log"
		},
		"tooltips": {
			"requestHeaders": "Additional headers.\n\nFormat:\n```key: value```\n\nAvailable placeholders:\n* `{$attribute}`\n* `{$$variable}`",
			"requestContent": "In JSON format.\n\nAvailable placeholders:\n* `{$attribute}`\n* `{$$variable}`",
			"portalOnly": "If checked workflow will be triggered only in portal.",
			"portal": "Specific portal where workflow will be triggered. Leave empty if you need it to work in any portal.",
			"scheduling": "Crontab notation. Defines frequency of workflow rule runs. UTC timezone.\n\n*\/5 * * * * - every 5 minutes\n\n0 *\/2 * * * - every 2 hours\n\n30 1 * * * - at 01:30 once a day\n\n0 0 1 * * - on the first day of the month"
		},
		"labels": {
			"Create Workflow": "Create Rule",
			"General": "General",
			"Conditions": "Conditions",
			"Actions": "Actions",
			"All": "All",
			"Any": "Any",
			"Formula": "Formula",
			"Email Address": "Email Address",
			"Email Template": "Email Template",
			"From": "From",
			"To": "To",
			"immediately": "Immediately",
			"Reply-To": "Reply-To",
			"later": "Later",
			"today": "now",
			"plus": "plus",
			"minus": "minus",
			"days": "days",
			"hours": "hours",
			"months": "months",
			"minutes": "minutes",
			"Link": "Link",
			"Entity": "Entity",
			"Add Field": "Add Field",
			"equals": "equals",
			"wasEqual": "was equal",
			"notEquals": "not equals",
			"wasNotEqual": "was not equal",
			"changed": "changed",
			"notChanged": "not changed",
			"notEmpty": "not empty",
			"isEmpty": "empty",
			"value": "value",
			"field": "field",
			"true": "true",
			"false": "false",
			"greaterThan": "greater than",
			"lessThan": "less than",
			"greaterThanOrEquals": "greater than or equals",
			"lessThanOrEquals": "less than or equals",
			"between": "between",
			"on": "on",
			"before": "before",
			"after": "after",
			"beforeToday": "before today",
			"afterToday": "after today",
			"recipient": "Recipient",
			"has": "has",
			"notHas": "not has",
			"contains": "contains",
			"notContains": "not contains",
			"messageTemplate": "Message Template",
			"users": "Users",
			"Target Entity": "Target Entity",
			"Current": "Current",
			"Workflow": "Workflow",
			"Workflows Log": "Workflows Log",
			"methodName": "Service Method",
			"additionalParameters": "Additional Parameters (JSON format)",
			"doNotStore": "Do not store sent email",
			"Related": "Related",
			"Entity Type": "Entity Type",
			"Workflow Rule": "Workflow Rule",
			"Add Condition": "Add Condition",
			"Add Action": "Add Action",
			"Created": "Created",
			"Field": "Field",
			"Process": "Process"
		},
		"emailAddressOptions": {
			"": "None",
			"currentUser": "Current user",
			"specifiedEmailAddress": "Specified email address",
			"assignedUser": "Assigned user",
			"targetEntity": "Target record",
			"specifiedUsers": "Specified users",
			"specifiedContacts": "Specified contacts",
			"teamUsers": "Users of teams related to target record",
			"followers": "Followers of target record",
			"followersExcludingAssignedUser": "Followers excluding assigned user",
			"specifiedTeams": "Users of specified teams",
			"system": "System",
			"fromOrReplyTo": "From\/Reply-To address"
		},
		"options": {
			"type": {
				"afterRecordSaved": "After record saved (created or updated)",
				"afterRecordCreated": "After record created",
				"afterRecordUpdated": "After record updated",
				"scheduled": "Scheduled",
				"sequential": "Sequential",
				"signal": "Signal"
			},
			"subjectType": {
				"value": "value",
				"field": "field",
				"today": "today\/now",
				"typeOf": "type of"
			},
			"assignmentRule": {
				"Round-Robin": "Round-Robin",
				"Least-Busy": "Least-Busy"
			}
		},
		"actionTypes": {
			"sendEmail": "Send Email",
			"createEntity": "Create Record",
			"createRelatedEntity": "Create Related Record",
			"updateEntity": "Update Target Record",
			"updateRelatedEntity": "Update Related Record",
			"relateWithEntity": "Link with Another Record",
			"unrelateFromEntity": "Unlink from Another Record",
			"makeFollowed": "Make Followed",
			"createNotification": "Create Notification",
			"triggerWorkflow": "Trigger Another Workflow Rule",
			"runService": "Run Service Action",
			"applyAssignmentRule": "Apply Assignment Rule",
			"updateCreatedEntity": "Update Created Record",
			"updateProcessEntity": "Update Process Record",
			"startBpmnProcess": "Start BPM Process",
			"sendRequest": "Send HTTP Request"
		},
		"texts": {
			"allMustBeMet": "All must be met",
			"atLeastOneMustBeMet": "At least one must be met",
			"formulaInfo": "Conditions of any complexity in espo-formula language"
		},
		"messages": {
			"jsonInvalid": "Is not valid JSON.",
			"loopNotice": "Be careful about a possible looping through two or more workflow rules continuously.",
			"messageTemplateHelpText": "Available variables:\n{entity} - target record,\n{user} - current user."
		},
		"serviceActions": {
			"sendEventInvitations": "Send Invitations",
			"addQuoteItemList": "Add Quote Items",
			"addInvoiceItemList": "Add Invoice Items",
			"addSalesOrderItemList": "Add Sales Order Items",
			"convertCurrency": "Convert Currency",
			"sendInEmail": "Send In Email",
			"optOut": "Opt-out",
			"generateAndSendPassword": "Generate Password",
			"pbxAnsweredByServiceAction": "Query Asterisk PBX for extension which answered the call with the callSid."
		},
		"serviceActionsHelp": {
			"generateAndSendPassword": "A new password will be generated and sent to the user's email address.",
			"optOut": "Optional parameter: targetListId. If not specified, then marks an email address opted-out.\n\n Example:\n```{\"targetListId\": \"TARGET_LIST_ID\"}```",
			"convertCurrency": "Optional parameter: targetCurrency. If not specified, then it will convert to the default currency.",
			"sendInEmail": "Parameters:\n* templateId - ID of PDF template\n* emailTemplateId - ID of email template\n* to - recipient (optional parameter); by default will be sent to billing contact or account; example: `link:account.assignedUser`\n\nExample:\n\n```{\n \"templateId\": \"TEMPLATE_ID\",\n \"emailTemplateId\": \"EMAIL_TEMPLATE_ID\",\n \"to\": \"link:billingContact\"\n}```",
			"addQuoteItemList": "Example: \n\n```{\n \"itemList\": [\n {\n \"quantity\": 1, \"procuctId\": \"productId\", \"name\": \"Product Name\", \"listPrice\": 100, \"unitPrice\": 100\n }\n ]\n}```",
			"addInvoiceItemList": "Example: \n\n```{\n \"itemList\": [\n {\n \"quantity\": 1, \"procuctId\": \"productId\", \"name\": \"Product Name\", \"listPrice\": 100, \"unitPrice\": 100\n }\n ]\n}```",
			"addSalesOrderItemList": "Example: \n\n```{\n \"itemList\": [\n {\n \"quantity\": 1, \"procuctId\": \"productId\", \"name\": \"Product Name\", \"listPrice\": 100, \"unitPrice\": 100\n }\n ]\n}```"
		}
	},
	"WorkflowLogRecord": {
		"labels": [],
		"fields": {
			"target": "Target",
			"workflow": "Workflow"
		}
	},
	"CannedMessage": {
		"fields": {
			"shortcut": "Shortcut",
			"text": "Text",
			"account": "Account"
		},
		"links": {
			"account": "Account"
		},
		"labels": {
			"Create CannedMessage": "Create Canned Message"
		}
	},
	"Chat": {
		"fields": {
			"contact": "Contact",
			"case": "Case",
			"messages": "Messages",
			"messageQuantity": "Msg. Qty."
		},
		"links": {
			"contact": "Contact",
			"case": "Case",
			"messages": "Messages"
		},
		"labels": {
			"Create Chat": "Create Chat"
		}
	},
	"Group": {
		"fields": {
			"notificationPushs": "Announcements",
			"users": "Users"
		},
		"links": {
			"notificationPushs": "Notification Pushs",
			"users": "Users"
		},
		"labels": {
			"Create Group": "Create Group"
		}
	},
	"NotificationPush": {
		"fields": {
			"content": "Content",
			"isActive": "Is Active",
			"startTime": "Start Time",
			"endTime": "End Time",
			"groups": "Groups"
		},
		"links": {
			"groups": "Groups"
		},
		"labels": {
			"Create NotificationPush": "Create Notification Push"
		}
	},
	"NotificationPushs": {
		"fields": [],
		"links": [],
		"labels": {
			"Create NotificationPushs": "Create Announcement"
		}
	},
	"PhoneCall": {
		"fields": {
			"answerTime": "Answer Time",
			"answered": "Answered",
			"answeredByExtension": "Answered By Extension",
			"callDuration": "Call Length(sec)",
			"callNotes": "Call Notes",
			"callReason": "Call Reason",
			"callSid": "CallSid",
			"callerCountry": "Caller Country",
			"callerCity": "Caller City",
			"callerState": "Caller State",
			"callerZip": "Caller Zip",
			"direction": "Direction",
			"duration": "Duration",
			"from": "From",
			"hasCalledBack": "Taken Care Of",
			"intent": "Intent",
			"outboundResult": "Outbound Result",
			"pbxDialStatus": "Pbx Dial Status",
			"pbxEventType": "Pbx Event Type",
			"pbxVoicemail": "Pbx Voicemail",
			"phoneNumber": "Phone Number",
			"rating": "Rating",
			"recording": "Recording",
			"recordingDuration": "Recording Length(sec)",
			"recordingStatus": "Recording Status",
			"recordingType": "Recording Type",
			"status": "Status",
			"talkTime": "Talk Time (sec)",
			"talkTimeInMinutes": "Talk Time (min)",
			"to": "To",
			"transcript": "Transcript",
			"twilioCallStatus": "Twilio Call Status",
			"waitTime": "Wait Time (sec)",
			"workPeriod": "Work Period",
			"account": "Account",
			"case": "Ticket",
			"user": "Answered By",
			"contact": "Customer",
			"finishTime": "FinishTime",
			"warning": "Warning",
			"recordings": "Recordings",
			"street2": "Street2",
			"availableUsers": "Available Users",
			"dial": "Dial",
			"sipCallId": "Sip Caller Id"
		},
		"links": {
			"account": "Account",
			"case": "Case",
			"user": "User",
			"contact": "Contact",
			"availableUsers": "Available Users"
		},
		"labels": {
			"Create PhoneCall": "Create PhoneCall"
		},
		"options": {
			"answered": {
				"Yes": "Yes",
				"No": "No"
			},
			"callReason": {
				"Compatibles": "Compatibles",
				"Shippings": "Shippings",
				"DVR&NVR's App&CMS Connections": "DVR&NVR's App&CMS Connections",
				"Password Reset": "Password Reset",
				"Price Inquiries": "Price Inquiries",
				"Device Parameters": "Device Parameters",
				"Stuck Without Image&Stuck In LOGO": "Stuck Without Image&Stuck In LOGO"
			},
			"direction": {
				"Inbound": "Inbound",
				"Outbound": "Outbound"
			},
			"hasCalledBack": {
				"Yes": "Yes",
				"No": "No"
			},
			"intent": {
				"Order Return": "Order Return",
				"Refund": "Refund",
				"Product Question": "Product Question",
				"Order Tracking Or Cancellation": "Order Tracking Or Cancellation",
				"Other": "Other",
				"Pre Sales Consulting": "Pre Sales Consulting",
				"After Sales Service": "After Sales Service",
				"Coupon Request": "Coupon Request",
				"New Product Trials": "New Product Trials"
			},
			"outboundResult": {
				"": "",
				"Talked": "Talked",
				"Unreachable": "Unreachable"
			},
			"rating": {
				"1": "1",
				"2": "2",
				"3": "3",
				"4": "4",
				"5": "5",
				"": "",
				"0": "0"
			},
			"recordingType": {
				"Call": "Call",
				"Voicemail": "Voicemail"
			},
			"status": {
				"Hungup": "Hungup",
				"Missed": "Missed",
				"Completed": "Completed",
				"Voicemail": "Voicemail",
				"Calling": "Calling",
				"IVR": "IVR"
			},
			"workPeriod": {
				"Work Hour": "Work Hour",
				"Off Hour": "Off Hour"
			}
		},
		"presetFilters": {
			"reportFilter5f5e2fa47530ac781": "calls in last 4 days"
		}
	},
	"QuizQuestion": {
		"links": [],
		"labels": {
			"Create QuizQuestion": "Create Quiz Question"
		},
		"fields": {
			"question1": "Question #1",
			"q1atext": "Q1atext",
			"q1Answer": "Q1Answer",
			"q1image": "Q1image",
			"q1btext": "Q1btext",
			"q1ctxt": "Q1ctxt",
			"q1dtext": "Q1dtext"
		},
		"options": {
			"q1Answer": {
				"A": "A",
				"B": "B",
				"C": "C",
				"D": "D"
			}
		}
	},
	"SMS": {
		"fields": {
			"description": "Content",
			"from": "From",
			"to": "To",
			"rocketChatDepartmentID": "Rocket.Chat Department ID",
			"case": "Case",
			"contact": "Contact",
			"user": "User",
			"imageURL": "Image URL",
			"type": "Type",
			"rocketChatMessageID": "RocketChatMessageID",
			"twilioSMSID": "TwilioSMSID",
			"status": "Status",
			"rocketChatMessageToken": "RocketChatMessageToken",
			"account": "Account",
			"rocketChatVisitorID": "RocketChatVisitorID",
			"rocketChatVisitorName": "RocketChatVisitorName",
			"rocketChatVisitorEmail": "RocketChatVisitorEmail",
			"channel": "Channel",
			"chat": "Chat"
		},
		"links": {
			"case": "Case",
			"contact": "Contact",
			"user": "User",
			"account": "Account",
			"chat": "Chat"
		},
		"labels": {
			"Create SMS": "Create Message"
		},
		"options": {
			"type": {
				"Text": "Text",
				"Image": "Image"
			},
			"status": [],
			"channel": {
				"SMS": "SMS",
				"Web": "Web",
				"Facebook": "Facebook",
				"": ""
			}
		}
	},
	"Screenshot": {
		"fields": {
			"user": "User",
			"accounts": "Accounts",
			"userEmploymentId": "User Employment Id",
			"screenshotThumbnail": "Screenshot Thumbnail"
		},
		"links": {
			"user": "User",
			"accounts": "Accounts"
		},
		"labels": {
			"Create Screenshot": "Create Screenshot"
		}
	}
}