import request from '@/utils/request';

export async function getNotificationsBySize(maxSize) {
  return request(`/api/Notification?maxSize=${maxSize}&offset=0&orderBy=number&order=desc`);
}

export async function getNotifications() {
  return request('/api/Notification?maxSize=5&offset=0&orderBy=number&order=desc');
}

export async function getNotReadCount() {
  return request('/api/Notification/action/notReadCount');
}

export async function getNewNotifications(after) {
  return request(`/api/Notification?after=${after}&maxSize=&offset=0&orderBy=number&order=desc`);
}

export async function updateNotification(id, notification) {
  return request(`/api/Notification/${id}`, {
    method: 'PUT',
    data: notification,
  });
}

export async function removeNotification(id) {
  return request(`/api/Notification/${id}`, { method: 'DELETE' });
}

export async function markNotificationAsRead(id) {
  return updateNotification(id, { read: true });
}

export async function markAllNotificationAsRead() {
  return request('/api/Notification/action/markAllRead', { method: 'POST' });
}

export async function getPopNotifications() {
  return request('/api/Activities/action/popupNotifications');
}

export async function removePopNotification(id) {
  return request('/api/Activities/action/removePopupNotification', {
    method: 'POST',
    data: {
      id,
    }
  });
}

export function getNotificationAvatarUserId(notification){
  if (notification.type === 'Assign' && notification.data) {
    return   notification.data.userId;
  }
  if (notification.type === 'Note' && notification.noteData && notification.noteData.type === 'Assign') {
    return notification.noteData.modifiedById || notification.noteData.createdById;
  }
  return notification.userId;
}