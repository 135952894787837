import request from '@/utils/request';

export async function getReport(reportId, params) {
  return request.get(`/api/crmreport/${reportId}?${params ? params.toString() : ''}`);
}

export async function getReportDataList(reportId, params) {
  return request.get(`/api/crmreport/${reportId}?list=1&${params ? params.toString() : ''}`);
}

export async function getReportDataCount(reportId, params) {
  return request.get(`/api/crmreport/${reportId}?count=1&${params ? params.toString() : ''}`);
}
