import request from '@/utils/request';
import { Base64 } from 'js-base64';

export async function fakeAccountLogin(params) {
  const authStr = Base64.encode(`${params.userName}:${params.password}`);
  return request('/api/App/user', {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${authStr}`,
      'Espo-Authorization': Base64.encode(`${params.userName}:${params.password}`),
      'Espo-Authorization-By-Token': false,
      'Espo-Authorization-Create-Token-Secret': true,
    },
    errorHandler: (error) => {
      const { response } = error;
      return response;
    }
  });
}
export async function getFakeCaptcha(mobile) {
  return request(`/api/login/captcha?mobile=${mobile}`);
}
export async function destroyAuthToken() {
  const authToken = window.atob(localStorage.getItem('auth-token'));
  return request('/api/App/action/destroyAuthToken', {
    method: 'POST',
    data: {
      'token': authToken
    }
  });
}
