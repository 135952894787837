export default {
  'pages.layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  'pages.login.accountLogin.tab': '账户密码登录',
  'pages.login.accountLogin.errorMessage': '错误的用户名和密码（admin/ant.design)',
  'pages.login.username.placeholder': '用户名',
  'pages.login.username.required': '用户名是必填项！',
  'pages.login.password.placeholder': '密码',
  'pages.login.password.required': '密码是必填项！',
  'pages.login.phoneLogin.tab': '手机号登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.phoneNumber.placeholder': '请输入手机号！',
  'pages.login.phoneNumber.required': '手机号是必填项！',
  'pages.login.phoneNumber.invalid': '不合法的手机号！',
  'pages.login.captcha.placeholder': '请输入验证码！',
  'pages.login.captcha.required': '验证码是必填项！',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'pages.login.rememberMe': '自动登录',
  'pages.login.forgotPassword': '忘记密码 ?',
  'pages.login.submit': '提交',
  'pages.login.loginWith': '其他登录方式 :',
  'pages.login.registerAccount': '注册账户',
  'pages.welcome.advancedComponent': '高级表格',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.advancedLayout': '高级布局',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',
  'pages.searchTable.createForm.newRule': '新建规则',
  'pages.searchTable.updateForm.ruleConfig': '规则配置',
  'pages.searchTable.updateForm.basicConfig': '基本信息',
  'pages.searchTable.updateForm.ruleName.nameLabel': '规则名称',
  'pages.searchTable.updateForm.ruleName.nameRules': '请输入规则名称！',
  'pages.searchTable.updateForm.ruleDesc.descLabel': '规则描述',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': '请输入至少五个字符',
  'pages.searchTable.updateForm.ruleDesc.descRules': '请输入至少五个字符的规则描述！',
  'pages.searchTable.updateForm.ruleProps.title': '配置规则属性',
  'pages.searchTable.updateForm.object': '监控对象',
  'pages.searchTable.updateForm.ruleProps.templateLabel': '规则模板',
  'pages.searchTable.updateForm.ruleProps.typeLabel': '规则类型',
  'pages.searchTable.updateForm.schedulingPeriod.title': '设定调度周期',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': '开始时间',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': '请选择开始时间！',
  'pages.searchTable.titleDesc': '描述',
  'pages.searchTable.ruleName': '规则名称为必填项',
  'pages.searchTable.titleCallNo': '服务调用次数',
  'pages.searchTable.titleStatus': '状态',
  'pages.searchTable.nameStatus.default': '关闭',
  'pages.searchTable.nameStatus.running': '运行中',
  'pages.searchTable.nameStatus.online': '已上线',
  'pages.searchTable.nameStatus.abnormal': '异常',
  'pages.searchTable.titleUpdatedAt': '上次调度时间',
  'pages.searchTable.exception': '请输入异常原因！',
  'pages.searchTable.titleOption': '操作',
  'pages.searchTable.config': '配置',
  'pages.searchTable.subscribeAlert': '订阅警报',
  'pages.searchTable.title': '查询表格',
  'pages.searchTable.new': '新建',
  'pages.searchTable.chosen': '已选择',
  'pages.searchTable.item': '项',
  'pages.searchTable.totalServiceCalls': '服务调用次数总计',
  'pages.searchTable.tenThousand': '万',
  'pages.searchTable.batchDeletion': '批量删除',
  'pages.searchTable.batchApproval': '批量审批',
  'pages.commentRequired': '必须评论',
  'pages.dashboard.opentickets': '待处理工单',
  'pages.dashboard.ticketstoupdate': '更新工单',
  'pages.dashboard.callstoreturn': '待回复电话',
  'pages.dashboard.callsticket': '电话工单',
  'pages.dashboard.missedsms': '未处理短信',
  'pages.dashboard.missedWebchat': '未处理聊天',
  'pages.dashboard.missedfbmsges': '未处理脸书',
  'pages.dashboard.goal': '目标',
  'pages.dashboard.monthlychange': '月度变化',
  'pages.dashboard.csat': '客户满意度调查',
  'pages.dashboard.csatrating': '五分占比',
  'pages.dashboard.csatlowrating': '低分占比',
  'pages.dashboard.averagewaittimeange': '平均等待时间',
  'pages.dashboard.unexecutedunanswered': '无理由未接率',
  'pages.dashboard.csattip': '请在每次通话结束时询问评级  ',
  'pages.dashboard.csatratingtip': '提供最好的客户服务',
  'pages.dashboard.csatlowratingtip': '在困难的情况下茁壮成长',
  'pages.dashboard.averagewaittimeangetip': '快速接听电话',
  'pages.dashboard.unexecutedunansweredtip': '尽量不要错过任何电话',
  'pages.stats.overview': '预览',
  'pages.stats.callsTotal': '打电话总数',
  'pages.stats.callUnanswered': '未回复电话',
  'pages.stats.avgWait': '平均等待时长',
  'pages.stats.avgTalk': '平均交流时长',
  'pages.stats.chatsTotal': '聊天总数',
  'pages.stats.chatsMissed': '未聊天',
  'pages.stats.emailSent': '发送邮件数',
  'pages.stats.emailReceived': '收到邮件数',
  'pages.stats.ticketscreated': '创建工单',
  'pages.stats.ticketsclosed': '关闭工单',
  'pages.stats.ticketscreatedbyhour': '创建工单每小时',
  'pages.stats.phonecallanalytics': '电话分析',
  'pages.stats.inbound': '呼入',
  'pages.stats.inbounda': '接通率',
  'pages.stats.outbounda': '外呼接通率',
  'pages.stats.outbound': '呼出',
  'pages.stats.answeredvsunanswered': '接通情况',
  'pages.stats.inboundoutbound': '呼入和呼出',
  'pages.stats.rating': '评分',
  'pages.stats.inboundanswered': '接通数',
  'pages.stats.inboundunAnswered': '未接通数',
  'pages.stats.outboundanswered': '接通数',
  'pages.stats.outboundunanswered': '未接通数',
  'page.stats.answered': '接通率',
  'pages.stats.ratedtotal': '评分总和',
  'page.case.view.msg.success': '成功',
  'page.stats.overview.ticket': '工作量',
  'page.stats.overview.chat': '沟通量',
  'page.stats.overview.channel': '渠道',
  'page.stats.overview.respondedandmiss': '接通率和未接率',
  'page.stats.overview.workperiod': '工作周期',
  'page.stats.overview.missedduringwork': '工作时间接通率和未接率',
  'pages.stats.Yes': 'Yes',
  'pages.stats.No': 'No',
  'pages.stats.numberoftickets':'总票数',
  'pages.stats.creationdate':'创建日期',
  'pages.stats.day':'天',
  'pages.stats.month':'月',
  'pages.stats.Completed':'完成',
  'pages.stats.missed':'错过',
  'pages.stats.hungup':'挂断',
  'pages.stats.voicemail':'语音信箱',
  'pages.stats.busy':'忙碌',
  'pages.stats.talked':'交谈',
  'pages.stats.unreachable':'不可达',
  'pages.stats.inboundtaken':'入站没有回答 <br> 注意',
  'pages.stats.waittimesla':'等待适配器时间 (秒.)',
  'pages.stats.received':'收到',
  'pages.stats.sent':'发送',
  'pages.stats.ticketsbytypes':'门票类型',
  'pages.stats.type':'类型',
  'pages.stats.created':'创建',
  'pages.stats.closed':'关闭',
  'pages.stats.avg':'平均值. 交货时间(小时)',
  'pages.stats.inprogress':'正在进行',
  'pages.stats.directsolution':'直接解法',
  'pages.stats.avgresponsetimeHour':'平均响应时间(小时)',
  'pages.stats.emailvolume':'电子邮件体积',
  'pages.stats.emailresponsetime':'电子邮件响应时间(小时) SLA',
  'pages.stats.minute':'分钟',
  'pages.stats.second':'另外',
  'pages.stats.web':'网络',
  'pages.stats.avgchatduration':'平均聊天时间',
  'pages.stats.avgfirstresponsetime':'平均首次响应时间',
  'pages.stats.avgrectiontime':'平均反应时间',
  'pages.stats.avgresponsetime':'平均响应时间',
  'pages.stats.bestresponsetime':'最佳首次响应时间',
  'pages.stats.totalconversations':'总谈话',
  'pages.stats.totalmessages':'总信息',
  'pages.contectEdit.validPhone':'请输入有效电话'
};

