import component from './zh-CN/component';
import globalHeader from './zh-CN/globalHeader';
import menu from './zh-CN/menu';
import pwa from './zh-CN/pwa';
import settingDrawer from './zh-CN/settingDrawer';
import settings from './zh-CN/settings';
import pages from './zh-CN/pages';

export default {
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.preview.down.block': '下载此页面到本地项目',
  'app.welcome.link.fetch-blocks': '获取全部区块',
  'app.welcome.link.block-list': '基于 block 开发，快速构建标准页面',
  'search.info': `
   'search.info':优化您的搜索关键字，请参阅下面的例子:
   1. 如果不使用操作符，则基于OR进行搜索。
   下面是一个搜索记录与'退款'或'返回'关键字:退款返回

   2. 用+表示和+迈克尔·杰克逊

   3.使用-表示不:•迈克尔 - 杰克森

   4. 使用*表示部分关键字。电话16502329999的查册记录:1650232 *

   5. 使用"来搜索确切的短语:"broken package"`,

  'charts.tickets': '关闭工单',
  'charts.phoneCalls': '电话',
  'charts.emails': '邮箱',
  'charts.chats': '聊天',
  'charts.tooltip.overview':
    '根据通道和日期创建和关闭票据的概述。 您还可以查看通话、电子邮件和聊天活动的基本指标。 ',
  'charts.tooltip.callsTotal': '基于所有电话，包括未接电话和下班后的电话。',
  'charts.tooltip.callUnanswered':
    '基于所有未接电话，包括语音邮件、未接或过早挂断的电话。包括工作时间和下班时间的电话。',
  'charts.tooltip.avgWait':
    '基于由客服接听的呼入电话， 它不包括在达到最大等待时间后导致语音邮件的呼叫。  ',
  'charts.tooltip.avgTalk': '基于客服回答的呼入',
  'charts.tooltip.chatsTotal': '基于所有聊天记录，包括离线消息会话  ',
  'charts.tooltip.chatsMissed': '根据没有客服在线时留下的信息。  ',
  'charts.tooltip.email': '每天发送/接收的电子邮件和平均响应时间。  ',
  ...pages,
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
};
