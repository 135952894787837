import { convertFiltersToParams } from "@/utils/FilterUtil";
import request from '@/utils/request';

const appendSizeAndOrderByToParams = (params, maxSize = 5) => {
  params.append('maxSize', maxSize);
  params.append('offset', 0);
  params.append('orderBy', 'modifiedAt');
  params.append('order', 'desc');
}

const getMissedSms = async (channel) => {
  const params = convertFiltersToParams([
    { 
      condition: { field: 'status', type: 'in', value: ['Missed'] },
    },
    {
      condition: { field: 'takenCareOf', type: 'in', value: ['No'] },
    },
    {
      condition: { field: 'channel', type: 'in', value: [channel] },
    },
  ]);
  params.append('select', 'name,from,createdAt,type,status');
  appendSizeAndOrderByToParams(params, 1);

  return request(`/api/Chat?${params ? params.toString() : ''}`);
}

const getRecentChat = async (channel) => {
  const params = convertFiltersToParams([
    {
      condition: { field: 'channel', type: 'in', value: [channel] },
    },
  ]);
  params.append('select', 'contactId,contactName,messagesIds,messagesNames,numberOfMessages,status,takenCareOf,createdAt');
  appendSizeAndOrderByToParams(params, 5);

  return request(`/api/Chat?${params ? params.toString() : ''}`);
}

const DashboardApi = {
  getOpenTicketsFilters: () => {
    return [
      {
        field: 'status',
        attribute: 'status',
        type: 'noneOf',
        value: ['Closed'],
        condition: {
          type: 'or',
          value: [
            { field: 'status', type: 'isNull' },
            { field: 'status', type: 'notIn', value: ['Closed'] }
          ]
        }
      },
    ]
  },

  getOpenTickets: async () => {
    const params = convertFiltersToParams(DashboardApi.getOpenTicketsFilters());
    params.append('select', 'name,createdAt,createdByName,status');
    appendSizeAndOrderByToParams(params, 1);
  
    return request(`/api/Case?${params ? params.toString() : ''}`);
  },

  getTicketsToUpdateFilters: () => {
    return [
      { 
        field: 'name',
        attribute: 'name',
        type: 'equals',
        value: 'PLEASE UPDATE TITLE',
        condition: { field: 'name', type: 'equals', value: 'PLEASE UPDATE TITLE' }
      },
    ]
  },

  getTicketsToUpdate: async () => {
    const params = convertFiltersToParams(DashboardApi.getTicketsToUpdateFilters());
    params.append('select', 'name');
    appendSizeAndOrderByToParams(params, 1);
  
    return request(`/api/Case?${params ? params.toString() : ''}`);
  },

  getCallsToReturnFilters: () => {
    return [
      { 
        field: 'status',
        attribute: 'status',
        type: 'anyOf',
        value: ['Missed', 'Voicemail', 'Hungup'],
        condition: { field: 'status', type: 'in', value: ['Missed', 'Voicemail', 'Hungup'] }
      },
      {
        field: 'hasCalledBack',
        attribute: 'takenCareOf',
        type: 'anyOf',
        value: ['No'],
        condition: { attribute: 'hasCalledBack', type: 'in', value: ['No'] }
      }
    ];
  },

  getCallsToReturn: async () => {
    const params = convertFiltersToParams(DashboardApi.getCallsToReturnFilters());
    params.append('select', 'name,createdByName,createdAt,status');
    appendSizeAndOrderByToParams(params, 1);
  
    return request(`/api/PhoneCall?${params ? params.toString() : ''}`);
  },

  getCallsWithoutTicketFilters: () => {
    return [
      { 
        field: 'case',
        attribute: 'caseId',
        type: 'isEmpty',
        condition: { field: 'caseId', type: 'isNull' }
      },
    ];
  },

  getCallsWithoutTicket: async () => {
    const params = convertFiltersToParams(DashboardApi.getCallsWithoutTicketFilters());
    params.append('select', 'name,createdByName,createdAt,status');
    appendSizeAndOrderByToParams(params, 1);
  
    return request(`/api/PhoneCall?${params ? params.toString() : ''}`);
  },

  getUnreadEmailsFilters: () => {
    return [
      { 
        field: 'isRead',
        attribute: 'isRead',
        type: 'isFalse',
        condition: { field: 'isRead', type: 'isFalse' }
      },
    ];
  },

  getUnreadEmails: async () => {
    const params = convertFiltersToParams(DashboardApi.getUnreadEmailsFilters());
    params.append('select', 'subject,from,fromName,dateSent,status');
    appendSizeAndOrderByToParams(params, 1);
  
    return request(`/api/Email?${params ? params.toString() : ''}`);
  },

  getMissedMessagesFilters: (channel) => {
    return [
      {
        field: 'status',
        attribute: 'status',
        type: 'anyOf',
        value: ['Missed'],
        condition: { field: 'status', type: 'in', value: ['Missed'] },
      },
      {
        field: 'takenCareOf',
        attribute: 'takenCareOf',
        type: 'anyOf',
        value: ['No'],
        condition: { field: 'takenCareOf', type: 'in', value: ['No'] },
      },
      {
        field: 'channel',
        attribute: 'channel',
        type: 'anyOf',
        value: [channel],
        condition: { field: 'channel', type: 'in', value: [channel] },
      },
    ];
  },

  getMissedSmsMessage: async () => {
    return getMissedSms('SMS');
  },

  getMissedWebMessage: async () => {
    return getMissedSms('Web');
  },

  getMissedFacebookMessage: async () => {
    return getMissedSms('Facebook');
  },


  getRecentTickets: async () => {
    const params = new URLSearchParams();
    params.append('select', 'name,createdAt,createdByName,status');
    appendSizeAndOrderByToParams(params, 5);
  
    return request(`/api/Case?${params ? params.toString() : ''}`);
  },

  getRecentPhoneCalls: async () => {
    const params = new URLSearchParams();
    params.append('select', 'name,createdAt,createdByName,status');
    appendSizeAndOrderByToParams(params, 5);
  
    return request(`/api/PhoneCall?${params ? params.toString() : ''}`);
  },

  getRecentEmails: async () => {
    const params = new URLSearchParams();
    params.append('select', 'subject,from,fromName,dateSent,status');
    appendSizeAndOrderByToParams(params, 5);
  
    return request(`/api/Email?${params ? params.toString() : ''}`);
  },

  getRecentSmsMessages: async () => {
    return getRecentChat('SMS');
  },

  getRecentWebMessages: async () => {
    return getRecentChat('Web');
  },

  getRecentFacebookMessages: async () => {
    return getRecentChat('Facebook');
  },
}

export default DashboardApi;