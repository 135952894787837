import { history } from 'umi';
import { fakeAccountLogin, destroyAuthToken } from '@/services/login';
import { updateUserOnlineStatus } from '@/services/user';
// import { setAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';
import request from '@/utils/request';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import TimeApi from '@/services/time';
import moment from 'moment-timezone';
import { OpRoom, ChatRooms } from '@/services/chats';

const Model = {
  namespace: 'login',
  state: {
    status: undefined,
  },
  effects: {
    *login({ payload, callback }, { call, put }) {
      const argc = { userName: payload.userName.trim(), password: payload.password };
      const response = yield call(fakeAccountLogin, argc);

      if (response.token) {
        yield put({
          type: 'changeLoginStatus',
          payload: response,
        }); // Login successfully
        if(response.user.type !== 'admin'){
          if(response.user.chatServerAuthToken !== null){
            //登录后聊天室在线
            const authToken = response.user.chatServerAuthToken.split(" ");
            let paramLive = { message: `{"msg":"method","method":"livechat:changeLivechatStatus","params":[]}` };
            // ChatRoomss(authToken,{...params},'method.call/logout')
            ChatRooms(authToken, 'me').then((response) => {
              if (response) {
                if (response.statusLivechat !== 'available') {
                  OpRoom(authToken, { ...paramLive }, 'method.call/livechat.changeLivechatStatus')
                } 
              }
            });
          }
        }
        // 登录后将用户状态置为在线
        const res = yield call(updateUserOnlineStatus, response.user, {
          onlineStatus: 'Online',
        });
        sessionStorage.setItem('onlineStatus',res.onlineStatus)

        localStorage.removeItem('LoginPopupRead');

        // const account = yield call(getEntityDetailById, 'Account', response.user.defaultAccountId);
        // if (account && account.id) {
        //   // localStorage.setItem('crm.impu', `sip:${response.user.sipUser}@${account.asteriskServer}`);
        //   localStorage.setItem('crm.asteriskServer', account.asteriskServer);
        //   localStorage.setItem('crm.asteriskWSSURL', account.asteriskWSSURL);
        //   localStorage.setItem('crm.asteriskMode', account.asteriskMode);
        // }

        const urlParams = new URL(window.location.href);
        const params = getPageQuery();
        // message.success('🎉 🎉 🎉  登录成功！');
        let { redirect } = params;

        if (redirect) {
          const redirectUrlParams = new URL(redirect);

          if (redirectUrlParams.origin === urlParams.origin) {
            redirect = redirect.substr(urlParams.origin.length);

            if (redirect.match(/^\/.*#/)) {
              redirect = redirect.substr(redirect.indexOf('#') + 1);
            }
          } else {
            window.location.href = '/';
            return;
          }
        }
        if (payload.hasOwnProperty('types') && payload.types == 1) {
          // history.replace('/case/view/'+payload.tid || '/');
          history.replace(`/lnk/chats/${payload.tid}` || '/');
        } else {
          history.replace(response.user.homeUI == 'Checkin'|| response.user.checkInOutUponLogin == true ? '/checkinout' : '/Case'|| redirect);
        }
        //登录后客人聊天界面状态显示在线
        setTimeout(function(){
          if(response.user.onlineStatus){
              window.ROCKSockets.sendMessage(`{"msg":"method","method":"setUserStatus","params":["online",null],"id":"${window.ROCKSockets.msgId + 1}"}`)
            }
        },3000)
        TimeApi.getUtcTime().then(res => {
          const minutes = moment(new Date()).diff(res.utcTime);
          localStorage.setItem('diffTime', minutes);
        })

      } else {
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: 'error'
          }
        });
      }
    },

    *logout(_, { call }) {
      // const { redirect } = getPageQuery(); // Note: There may be security issues, please note

      yield call(destroyAuthToken, {});

      localStorage.setItem('isLogin', false);

      if (window.location.pathname !== '/login') {
        history.replace({
          pathname: '/login',
          // search: stringify({
          //   redirect: window.location.href,
          // }),
        });
      }
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      if (payload.token) {
        // setAuthority(payload.currentAuthority);
        localStorage.setItem('isLogin', true);
        localStorage.setItem('auth-token', window.btoa(payload.token));
        localStorage.setItem('auth-username', window.btoa(payload.user.userName));
        //Cookies.set('auth-token', payload.token, { domain: 'crm-qa.callnovo.net'});
       // Cookies.set('auth-username', payload.user.userName, { domain: 'crm-qa.callnovo.net'});
        Cookies.set('auth-token', payload.token);
        Cookies.set('auth-username', payload.user.userName);

        // save sip info:
        // localStorage.setItem('crm.sipDisplayName', payload.user.sipDisplayName);
        // localStorage.setItem('crm.sipUser', payload.user.sipUser);
        // localStorage.setItem('crm.sipPassword', payload.user.sipPassword);

        const token = `${payload.user.userName}:${payload.token}`;
        const encodedToken = Base64.encode(token);

        localStorage.setItem('Espo-Authorization', window.btoa(encodedToken));
        
        let language = 'en-US';
        switch(payload.language){
          case 'en_US':{
            language = 'en-US';
            break
          }
          case 'zh_CN':{
            language = 'zh-CN';
            break
          }
          case 'id_ID':{
            language = 'id-ID';
            break
          }
          case 'pt_BR':{
            language = 'pt-BR';
            break
          }
          case 'zh_TW':{
            language = 'zh-TW';
            break
          }
        }
        localStorage.setItem('umi_locale',language);
        request.extendOptions({
          headers: {
            Authorization: `Basic ${encodedToken}`,
            'Espo-Authorization': `${encodedToken}`,
            'Espo-Authorization-By-Token': true,
          }
        });
      }
      return { ...state, status: payload.status, type: payload.type };
    },
  },
};
export default Model;
