// const conditionTypeMapping = {
//   'anyOf': 'in',
//   'noneOf': 'notIn',
// };

// function getValue(key, value, isEntitySearch) {
//   if (!isEntitySearch || key !== 'type') {
//     return value;
//   }
//   return conditionTypeMapping[value] || value;
// }
function appendCondition(params, prefix, key, obj, isEntitySearch) {
  if (key === 'noType') {
    return;
  }
  if (obj?.dateTime && (obj?.type === 'on' || obj?.type === 'after' || obj?.type === 'before') && !obj?.value) {
    return;
  }

  if (obj?.flag && key === 'flag') {
    params.append(`${prefix}[value][]`, '');
    params.append(`${prefix}[value][]`, '');
    return;
  }

  if (obj[key] !== undefined && obj[key] !== null) {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((value, idx) => {
        if (typeof(value) === 'object' && value) {
          Object.keys(value).forEach((k) => {
            appendCondition(params, `${prefix}[${key}][${idx}]`, k, value, isEntitySearch);
          });
        } else {
          params.append(`${prefix}[${key}][]`, value);
          // params.append(`${prefix}[${key}][]`, getValue(key, value, isEntitySearch));
        }
      });
    } else if (typeof(obj[key]) === 'object') {
      Object.keys(obj[key]).forEach((k) => {
        appendCondition(params, `${prefix}[${key}]`, k, obj[key], isEntitySearch);
      });
    } else {
      params.append(`${prefix}[${key}]`, obj[key]);
      // params.append(`${prefix}[${key}]`, getValue(key, obj[key], isEntitySearch));
    }
  }
}
export function convertFiltersToParams(filters, isEntitySearch) {
  if (!filters) {
    return new URLSearchParams();
  }

  const params = new URLSearchParams();
  filters.forEach((filter, index) => {
    if (filter.type === 'primary' || filter.type === 'textFilter') {
      params.append(`where[${index}][type]`, filter.type);
      params.append(`where[${index}][value]`, filter.value);
    } else {
      Object.keys(filter.condition).forEach((key) => {
        appendCondition(params, `where[${index}]`, key, filter.condition, isEntitySearch);
      });
    }
  });

  return params;
}

export function getConditionTypeListForFieldType(i18n,fieldType) {
  switch (fieldType) {
    case 'bool':
      return [ 'isTrue', 'isFalse','any' ];
    case 'link':
      return ['equals', 'isEmpty', 'isNotEmpty', 'notEquals', 'anyOf', 'noneOf'];
    case 'linkMultiple':
      return [ 'anyOf', 'noneOf', 'isEmpty', 'isNotEmpty' ];
    case 'date':
    case 'datetime':
      return Object.keys(i18n.Global.options.dateSearchRanges);
    case 'int':
    case 'float':
    case 'currency':
    case 'autoincrement':
    case 'currencyConverted':
      return Object.keys(i18n.Global.options.intSearchRanges);
    case 'enum':
      return [ 'anyOf', 'noneOf', 'isEmpty', 'isNotEmpty' ];
    case 'phone':
    case 'email':
    case 'varchar':
    case 'personName':
    case 'text':
    case 'audio':
    case 'barcode':
    case 'dial':
    case 'foreign':
    case 'number':
    case 'url':
    case 'video':
    case 'wysiwyg':
    case 'screenshot':
      let arr = [ 'equals', 'contains', 'notContains', 'startsWith', 'endsWith', 'like', 'notLike', 'isEmpty', 'isNotEmpty' ];
      let type = ['audio','barcode','dial','foreign','number','url','video','wysiwyg','screenshot'];
      if(type.includes(fieldType)){
        arr.unshift('notEquals');
      }
      return arr;
    case 'multiEnum':
    case 'checklist':
      return [ 'anyOf', 'noneOf', 'allOf', 'isEmpty', 'isNotEmpty'  ];
    case 'attachmentMultiple':
    case 'file':
    case 'image':
      return ['isEmpty','isNotEmpty'];
    default:
      return [];
  }
}

const emptyConditionTypes = [ 'isEmpty', 'today', 'isNotEmpty' ,'isTrue','isFalse','any' ];
export function isShowFieldForConditionType(conditionType) {
  return emptyConditionTypes.indexOf(conditionType) === -1;
}

// function getConditionTypeFromPresetFilter(filter) {
//   let type;
//   if (filter.data && filter.data.type) {
//     type = filter.data.type;
//   } else {
//     type = filter.type;
//   }

//   if (type === 'isOneOf') {
//     type = 'anyOf';
//   }

//   return type;
// }

// function getValueFromPresetFilter(filter) {
//   if (filter.data && filter.data.value) {
//     return filter.data.value;
//   }

//   if (filter.data && filter.data.nameHash) {
//     return Object.keys(filter.data.nameHash).map(value => ({
//       value,
//       label: filter.data.nameHash[value],
//     }));
//   }

//   if (filter.data && filter.data.oneOfNameHash) {
//     return Object.keys(filter.data.oneOfNameHash).map(value => ({
//       value,
//       label: filter.data.oneOfNameHash[value],
//     }));
//   }

//   return null;
// }
// function getConditionForPresetFilter(filter, field) {
//   const condition = {
//     type: filter.type
//   };

//   if (filter.type !== 'or' && filter.type !== 'and') {
//     condition.attribute = filter.attribute || field;
//   }

//   let value;
//   if (Array.isArray(filter.value) && filter.value.length > 0) {
//     value = filter.value.map(item => {
//       if (typeof(item) === 'string' || typeof(item) === 'number') {
//         return item;
//       }
//       if (item.field) {
//         return {
//           type: item.type,
//           attribute: item.field,
//           value: item.value,
//         };
//       }
//       return item;
//     });
//   } else {
//     value = filter.value;
//   }

//   condition.value = value;

//   return condition;
// }

// export function convertUserPresetFilterToFilter(filter, field) {
//   const result = {
//     field,
//     attribute: filter.attribute || field,
//     type: getConditionTypeFromPresetFilter(filter),
//     condition: getConditionForPresetFilter(filter, field),
//   };

//   if (isShowFieldForConditionType(result.type)) {
//     result.value = getValueFromPresetFilter(filter);
//   }

//   return result;
// }

export function handleFilter(where,metadata,entity){
  if (!where) {
    return new URLSearchParams();
  }

  const params = new URLSearchParams();
  let indexs = 0;

  where.forEach((item,index)=> {
    // console.log(item.field,'item.field');
    if(item.type === 'primary' || item.type === 'textFilter'){
      params.append(`where[${index}][type]`, item.type);
      params.append(`where[${index}][value]`, item.value);
    }else if(!item?.field){
// field 
      Object.keys(item.condition).forEach((v,i)=> {
        params.append(`where[${index-indexs}][${v}]`,item.condition[v]);
      })
    }else {
      let type = metadata.entityDefs[entity].fields[item.field].type;
      switch(type){
        case 'address':{
          // console.log(item,'handleFilter',type);
          if(item.value){
            params.append(`where[${index-indexs}][type]`,item.condition.type);
            item.condition.value.forEach((v,i)=> {
              Object.keys(v).forEach((k,j)=> {
                params.append(`where[${index-indexs}][value][${i}][${k}]`,v[k]);
              })
            })
          }else {
            indexs++;
          }
          break;
        }

        case 'array':{
          // console.log(item,'handleFilter',type);
          params.append(`where[${index-indexs}][type]`,item.condition.value[0].type);
            params.append(`where[${index-indexs}][attribute]`,item.condition.value[0].attribute);
          if(item.type !== "isEmpty" && item.type !== "isNotEmpty"){
            let cond = item.condition;
            if(cond.value?.length > 0){
              cond.value?.forEach((v,i)=> {
                params.append(`where[${index-indexs}][value][]`,v);
              });
            }else {
              params.append(`where[${index-indexs}][value]`,'');
            }
          }
          break;
        }

        case 'attachmentMultiple':
        case 'file':
        case 'image':{
          // console.log(item,'handleFilter',type);
          let cond = item.condition;
          params.append(`where[${index-indexs}][type]`,cond.type);
          params.append(`where[${index-indexs}][attribute]`,cond.attribute);
          break;
        }
        // case 'audio':{
        //   break;
        // }

        case 'autoincrement':
        case 'int':
        case 'float':
        case 'currency':
        case 'currencyConverted':{
          // console.log(item,'handleFilter',type);
          if(item.type === "between"){
            params.append(`where[${index-indexs}][type]`,item.type);
            params.append(`where[${index-indexs}][attribute]`,item.attribute);
            // 数组没有数据
            let cond = item.condition
            if(!cond.value[0] && !cond.value[1]){
              cond.value.forEach((v,i)=> {
                params.append(`where[${index-indexs}][value][${i}]`,'');
              });
            }
            // 1 有 2 无
            if(cond.value[0] && !cond.value[1]){
              params.append(`where[${index-indexs}][value][]`,cond.value[0]);
              params.append(`where[${index-indexs}][value][1]`,'');
            }
            // 1 无 2 有
            if(!cond.value[0] && cond.value[1]){
              params.append(`where[${index-indexs}][value][0]`,'');
              params.append(`where[${index-indexs}][value][]`,cond.value[1]);
            }
            //1 , 2 都有
            if(cond.value[0] && cond.value[1]){
              cond.value.forEach((v,i)=> {
                params.append(`where[${index-indexs}][value][]`,v);
              })
            }
          }else if(item.type === "isEmpty" || item.type === "isNotEmpty"){
            params.append(`where[${index-indexs}][type]`,item.condition.value[0].type);
            params.append(`where[${index-indexs}][attribute]`,item.condition.value[0].attribute);
            // item.condition.value?.forEach((v,i)=> {
            //   Object.keys(v).forEach((k,j)=> {
            //     params.append(`where[${index}][${k}]`,v[k]);
            //   });
            // });
          }else {
            Object.keys(item.condition).forEach((k,i)=> {
              params.append(`where[${index-indexs}][${k}]`,item.condition[k]??'');
            });
          }
          break;
        }
        // case 'barcode':{
        //   break;
        // }

        case 'bool':{
          // console.log(item,'handleFilter',type);
          params.append(`where[${index-indexs}][attribute]`,item.attribute);
          if(item.type !== "any"){
            params.append(`where[${index-indexs}][type]`,item.type);
          }
          break;
        }
        // case 'checklist':{
        //   break;
        // }
        // case 'currency':{
        //   break;
        // }
        // case 'currencyConverted':{
        //   break;
        // }
        // case 'date':{
        //   break;
        // }

        case 'datetime':
        case 'date':{
          // 单select 类型 没有value可填
        let types = ['lastSevenDays' , 'ever' , 'isEmpty' , 'currentMonth' , 'lastMonth' , 'nextMonth' , 'currentQuarter' , 'lastQuarter' , 'currentYear' , 'lastYear' , 'today' , 'past' , 'future' ];
          // console.log(item,'handleFilter',type);
          let arr = ['on','after','before','between'];
          if(arr.includes(item?.type) && item.condition?.dateTime && !item?.value){
//什么都不传
            indexs++;
          }else if(types.includes(item.type)){
            let obj = {...item.condition};
            delete obj.value;
            Object.keys(obj).forEach((k,i)=> {
              params.append(`where[${index-indexs}][${k}]`,obj[k]);
            });
          }else {
            Object.keys(item.condition).forEach((k,i) => {
              params.append(`where[${index-indexs}][${k}]`,item.condition[k]);
            });
          }
          // if(item.type !== 'on' && item.value){

          // }
          break;
        }
        // case 'dial':{
        //   break;
        // }
        // case 'email':{
        //   break;
        // }

        case 'enum':{
          // console.log(item,'handleFilter',type);
          switch(item.type){
            case 'isEmpty':
              case 'isNotEmpty':{
                params.append(`where[${index-indexs}][type]`,item.condition.type);
                // params.append(`where[${index-indexs}][attribute]`,item.attribute);
                item.condition.value?.forEach((v,i)=> {
                  Object.keys(v).forEach((k,j)=> {
                    params.append(`where[${index-indexs}][value][${i}][${k}]`,v[k]);
                  })
                })
                break;
              }
            case 'anyOf':
              case 'noneOf':{
                params.append(`where[${index-indexs}][attribute]`,item.attribute);
                if(item.condition.value && item.condition.value?.length > 0 ){
                  params.append(`where[${index-indexs}][type]`,item.condition.type);
                  item.condition.value.forEach((v,i)=> {
                    params.append(`where[${index-indexs}][value][]`,v);
                  })
                }
                // else if(item.type === 'anyOf'){
                //   params.append(`wher[${index-indexs}][value]`,'');
                // }
              break;
              }
          }
          break;
        }

        // case 'file':{
        //   break;
        // }
        // case 'float':{
        //   break;
        // }
        // case 'foreign':{
        //   break;
        // }
        // case 'image':{
        //   break;
        // }
        // case 'int':{
        //   break;
        // }

        case 'multiEnum':
        case 'checklist':{
          // console.log(item,'handleFilter',type);
          if(item.type === "isEmpty" || item.type === "isNotEmpty"){
            params.append(`where[${index-indexs}][type]`,item.condition.value[0].type);
            params.append(`where[${index-indexs}][attribute]`,item.condition.value[0].attribute);
            // item.condition.value?.forEach((v,i)=> {
            //   Object.keys(v).forEach((k,j)=> {
            //     params.append(`where[${index}][${k}]`,v[k]);
            //   });
            // });
          }else {
            Object.keys(item.condition).forEach((key,i)=> {
              if(key !== 'value'){
                params.append(`where[${index-indexs}][${key}]`,item.condition[key]);
              }
            });
            if(item.value && item.value?.length > 0){
              item.value?.forEach((v,i)=> {
                params.append(`where[${index-indexs}][value][]`,v);
              });
            }else {
              params.append(`where[${index-indexs}][value]`,'');
            }
          }
          break;
        }
        
        // case 'number':{
        //   break;
        // }
        // case 'screenshot':{
        //   break;
        // }
        // case 'text':{

        //   break;
        // }
        // case 'url':{
        //   break;
        // }

        case 'varchar':
        case 'text':
        case 'email':
        case 'phone':
        case 'personName':
        case 'audio':
        case 'barcode':
        case 'dial':
        case 'foreign':
        case 'number':
        case 'url':
        case 'video':
        case 'wysiwyg':
        case 'screenshot':{
          // console.log(item,'handleFilter',type);
          if(item.type === "isEmpty" || item.type === "isNotEmpty"){
            params.append(`where[${index-indexs}][type]`,item.condition.type);
            // params.append(`where[${index-indexs}][attribute]`,item.condition.value[0].attribute);
            item.condition.value?.forEach((v,i)=> {
              Object.keys(v).forEach((k,j)=> {
                params.append(`where[${index}][value][${i}][${k}]`,v[k]);
              });
            });
          }else if(item.value){
            Object.keys(item.condition).forEach((key,i)=> {
              params.append(`where[${index-indexs}][${key}]`,item.condition[key]);
            });
          }else {
            //value无值，什么都不传
            indexs++;
          }
          break;
        }

        // case 'video':{
        //   break;
        // }

        // case 'wysiwyg':{
        //   break;
        // }

        case 'link':{
          // console.log(item,'handleFilter',type);
          if(item.type === "isEmpty" || item.type === "isNotEmpty"){
            params.append(`where[${index-indexs}][type]`,item.condition.value[0].type);
            params.append(`where[${index-indexs}][attribute]`,item.condition.value[0].attribute);
            // item.condition.value?.forEach((v,i)=> {
            //   Object.keys(v).forEach((k,j)=> {
            //     params.append(`where[${index}][${k}]`,v[k]);
            //   });
            // });
          }else if(item.value && item.value?.length > 0){
            Object.keys(item.condition).forEach((key,i)=> {
              if(key !== 'value'){
                params.append(`where[${index-indexs}][${key}]`,item.condition[key]);
              }
            });
            item.value?.forEach((v,i)=> {
              params.append(`where[${index-indexs}][value][]`,v.value);
            });
          }else {
            //value无值，什么都不传
            indexs++;
          }
          break;
        }
        
        case 'linkMultiple':{
          // console.log(item,'handleFilter',type);
          switch(item.type){
            case 'isEmpty':
              case 'isNotEmpty':{
                params.append(`where[${index-indexs}][type]`,item.condition.type);
                params.append(`where[${index-indexs}][attribute]`,item.attribute);
                break;
              }
            case 'anyOf':
              case 'noneOf':{
                params.append(`where[${index-indexs}][type]`,item.condition.type);
                params.append(`where[${index-indexs}][attribute]`,item.attribute);
                if(item.condition.value && item.condition.value?.length > 0 ){
                  item.condition.value.forEach((v,i)=> {
                    params.append(`where[${index-indexs}][value][]`,v);
                  })
                }else if(item.type === 'anyOf'){
                  params.append(`wher[${index-indexs}][value]`,'');
                }
              break;
              }
          }
          break;
        }
        default : {
          break;
        }
      }
    }
  });
  
  return { params, indexs };
}
