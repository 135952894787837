export default {
  'menu.sample': 'Contoh',
  'menu.welcome': 'Selamat Datang',
  'menu.more-blocks': 'Lebih banyak Block',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Hasil Register',
  'menu.dashboard': 'Dashboard',
  'menu.create': 'Buat',
  'menu.edit': 'Edit',
  'menu.view': 'Lihat',
  'menu.PhoneCalls': 'Panggilan Telepon',
  'menu.phoneCall.phoneCallEdit': 'Edit',
  'menu.phoneCall.phoneCallView': 'Lihat',
  'menu.phoneCallEdit': 'Edit',
  'menu.phoneCallView': 'Lihat',
  'menu.case': 'Tiket',
  'menu.caseEdit': 'Edit',
  'menu.caseView': 'Lihat',
  'menu.Emails': 'Email',
  'menu.EmailEdit': 'Edit',
  'menu.EmailView': 'Lihat',
  'menu.knowledgeBaseArticle': 'Pengetahuan Dasar',
  'menu.knowledgeBaseArticleEdit': 'Edit',
  'menu.knowledgeBaseArticleView': 'Lihat',
  'menu.SMS': 'Pesan',
  'menu.SMSEdit': 'Edit',
  'menu.SMSView': 'Lihat',
  'menu.Chat': 'Chat',
  'menu.contact': 'Customer',
  'menu.contactEdit': 'Edit',
  'menu.contactView': 'Lihat',
  'menu.report': 'Report',
  'menu.report.dashboard': 'Dashboard',
  'menu.notification': 'Notifikasi',
  'menu.report.sample': 'Contoh',
  'menu.dashboard.analysis': 'Analisis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Form Dasar',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(tulis informasi transfer)',
  'menu.form.step-form.confirm': 'Step Form(konfirmasi informasi transfer)',
  'menu.form.step-form.result': 'Step Form(selesai)',
  'menu.form.advanced-form': 'Form Advanced',
  'menu.list': 'List',
  'menu.list.table-list': 'Cari Tabel',
  'menu.list.basic-list': 'List Dasar',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Cari List',
  'menu.list.search-list.articles': 'Cari List(artikel)',
  'menu.list.search-list.projects': 'Cari List(proyek)',
  'menu.list.search-list.applications': 'Cari List(aplikasi)',
  'menu.profile': 'Profil',
  'menu.profile.basic': 'Profil Dasar',
  'menu.profile.advanced': 'Profil Advanced',
  'menu.result': 'Hasil',
  'menu.result.success': 'Sukses',
  'menu.result.fail': 'Gagal',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Akun',
  'menu.account.center': 'Pusat Akun',
  'menu.account.settings': 'Pengaturan Akun',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Editor Grafis',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.more': 'Lebih banyak',
  'menu.more.create': 'Buat',
  'menu.more.edit': 'Edit',
  'menu.more.view': 'Lihat',
  'menu.more.Timesheet': 'Timesheet',
  'menu.more.TimesheetEdit': 'Edit',
  'menu.more.TimesheetView': 'Lihat',
  'menu.more.AdminTimesheet': 'Timesheet Tracking',
  'menu.more.WriteUp': 'Write-ups',
  'menu.more.WriteUpEdit': 'Edit',
  'menu.more.WriteUpView': 'Lihat',
  'menu.more.AbsenceNote': 'Catatan Absensi',
  'menu.more.AbsenceNoteEdit': 'Edit',
  'menu.more.AbsenceNoteView': 'Lihat',
  'menu.more.AgentSurvey': 'Survei Agent',
  'menu.more.AgentSurveyEdit': 'Edit',
  'menu.more.AgentSurveyView': 'Lihat',
  'menu.more.Signature': 'Tanda Tangan',
  'menu.more.SignatureEdit': 'Edit',
  'menu.more.SignatureView': 'Lihat',
  'menu.fulfillment': 'Fulfillment',
  'menu.fulfillment.create': 'Buat',
  'menu.fulfillment.edit': 'Edit',
  'menu.fulfillment.view': 'Lihat',
  'menu.fulfillment.Order': 'Lainnya',
  'menu.fulfillment.OrderEdit': 'Edit',
  'menu.fulfillment.OrderView': 'Lihat',
  'menu.fulfillment.Product': 'Produk',
  'menu.fulfillment.ProductEdit': 'Edit',
  'menu.fulfillment.ProductView': 'Lihat',
  'menu.Order': 'Order',
  'menu.OrderEdit': 'Edit',
  'menu.OrderView': 'Lihat',
  'menu.Product': 'Produk',
  'menu.ProductEdit': 'Edit',
  'menu.ProductView': 'Lihat',
  'menu.Document': 'Dokumen',
  'menu.DocumentEdit': 'Edit',
  'menu.DocumentView': 'Lihat',
  'menu.more.Exam': 'Tes Ujian',
  'menu.more.ExamEdit': 'Edit',
  'menu.more.ExamView': 'Lihat',
  'menu.more.Quiz': 'Quiz',
  'menu.more.QuizEdit': 'Edit',
  'menu.more.QuizView': 'Lihat',
  'menu.more.CannedMessage': 'Canned Messages',
  'menu.more.CannedMessageEdit': 'Edit',
  'menu.more.CannedMessageView': 'Lihat',
  'menu.more.ScreenshotActivity': 'Slot Monitoring',
  'menu.more.ScreenshotActivityEdit': 'Edit',
  'menu.more.ScreenshotActivityView': 'Lihat',
  'menu.more.ScreenshotAppsUrls': 'Aplikasi Monitoring',
  'menu.more.ScreenshotAppsUrlsEdit': 'Edit',
  'menu.more.ScreenshotAppsUrlsView': 'view',
  'menu.more.ScreenshotReport': 'Ringkasan Monitoring',
  'menu.more.ScreenshotReportEdit': 'Edit',
  'menu.more.ScreenshotReportView': 'Lihat',
  'menu.more.ServiceRequest': 'Permintaan Layanan',
  'menu.more.ServiceRequestEdit': 'Edit',
  'menu.more.ServiceRequestView': 'Lihat',
  'menu.more.SpeedTest': 'Speed Test',
  'menu.more.SpeedTestEdit': 'Edit',
  'menu.more.SpeedTestView': 'Lihat',
  'menu.more.Screenshot': 'Screenshot Monitoring',
  'menu.more.ScreenshotEdit': 'Edit',
  'menu.more.ScreenshotView': 'Lihat',
  'menu.stats': 'Analitik',
  'menu.stats.Overview': 'Gambaran Umum',
  'menu.stats.AgentPerformance': 'Performa Agen',
  'menu.stats.PhoneCall': 'Panggilan Telepon',
  'menu.stats.Email': 'Email',
  'menu.stats.Chat': 'Chat',
  'menu.ChatsOpen': 'Pesan Chat dengan',
  'menu.agent.AvgReply': 'Rata-rata respon',
  'menu.agent.Chats': 'Chat',
  'menu.agent.AvgDuration': 'Rata-rata durasi',
  'menu.agent.Messages': 'Pesan',
  'menu.agent.AvgResponse': 'Rata-rata Respon Pertama',
  'menu.agent.BestResponse': 'Respon Pertama terbaik',
  'menu.agent.AvgResponses': 'Rata-rata Respon',
  'menu.agent.AvgReaction': 'Rata-rata Reaksi',
  'menu.agent.TalkTime': 'Waktu Bicara',
  'menu.agent.WaitTime': 'Waktu Tunggu',
  'menu.agent.Emalititle': 'Jumlah Email dan Waktu Respon',
  'menu.agent.ReceivedSent': 'Diterima vs. Dikirim',
  'menu.stats.Chattitle': 'Analitik Chat',
  'menu.stats.TimeMetrics': 'Metrik Waktu',
  'menu.case.action.open': 'Buka',
  'menu.case.action.quickview': 'Lihat Sekilas',
  'menu.case.action.delete': 'Hapus',
  'menu.case.view.stream2': 'Tulis catatan Anda disini...',
  'menu.case.view.webchat2': 'Ketik pesan Anda disini...',
  'menu.case.view.subject': 'Ketik judul email disini...',
  'menu.case.view.email': 'Tulis pesan email disini...',
  'menu.case.view.stream': 'Catatan',
  'menu.case.view.email2': 'Email',
  'menu.case.view.ChatRoom': 'Sesi Chat',
  'menu.case.view.call': 'Telepon',
  'menu.case.view.sms': 'SMS',
  'menu.case.view.webchat': 'LiveChat',
  'menu.case.view.facebook': 'Facebook',
  'menu.stats.Agent': 'Agen',
  'menu.stats.alert': ' kebawah untuk lihat lebih banyak',
  'menu.Channels':'Channel',
  'menu.Channels.PhoneCalls':'Panggilan Telepon',
  'menu.Channels.Chats':'Chat',
  'menu.Channels.Emails':'Email',
  'menu.sms.Online':'Pause',
  'menu.sms.Offline':' Paused',
  'menu.sms.typing':'sedang mengetik...',
  'menu.note.StandardDate':'Tanggal Standar',
  'menu.note.PrettyDate':'Tanggal Cantik',
  'menu.more.EmailTemplate': 'Template Email',
  'menu.Back':'Kembali',
  'menu.closeAll':'Tutup Semua',
  'menu.case.view.feature':'Fitur ini tidak dapat diaktifasi untuk Akun Anda.'
};
