import { getReport } from '@/services/report';

export default {
  namespace: 'reports',
  state: {},

  effects: {
    *fetchReport({ payload }, { call, put }) {
      const { optionId, reportId, params } = payload;
      const response = yield call(getReport, reportId, params);
      yield put({
        type: 'setReport',
        payload: {
          optionId,
          report: response,
        },
      });
    },
    *fetchReportCount({ payload, callback }, { call, put }) {
      const { optionId, reportId, params } = payload;
      const response = yield call(getReport, reportId, params);
      yield put({
        type: 'setReport',
        payload: {
          optionId,
          report: response,
        },
      });
      if (callback && typeof callback === 'function') {
        callback(response); // 返回结果
      }
    },
  },

  reducers: {
    setReport(state, { payload }) {
      const { optionId, report } = payload;
      return { ...state, [optionId]: report };
    },
  },
};
