// @ts-nocheck

import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import AppstoreAddOutlined from '@ant-design/icons/AppstoreAddOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import DatabaseOutlined from '@ant-design/icons/DatabaseOutlined';
import FolderOpenOutlined from '@ant-design/icons/FolderOpenOutlined';
import SoundOutlined from '@ant-design/icons/SoundOutlined';
import SnippetsOutlined from '@ant-design/icons/SnippetsOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined'

export default {
  DashboardOutlined,
TeamOutlined,
SolutionOutlined,
AppstoreAddOutlined,
PhoneOutlined,
MessageOutlined,
MailOutlined,
DatabaseOutlined,
FolderOpenOutlined,
SoundOutlined,
SnippetsOutlined,
ShopOutlined,
MoreOutlined
}
    