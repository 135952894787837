/*
 * @Author: wake.wu HUYpBVyzQMH5Xi55
 * @Date: 2023-02-13 13:28:23
 * @LastEditors: wake.wu HUYpBVyzQMH5Xi55
 * @LastEditTime: 2023-02-13 14:25:27
 * @FilePath: \espo-ui\src\services\chats.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request';
import espoConfig from "../../config/espoConfig";

export async function ChatRooms(params,query) {
  return request(`https://${espoConfig.RockChatUrl}/api/v1/`+query, {
        method: 'GET',
        headers: {
          'Host': espoConfig.RockChatUrl,
          'X-Auth-Token': params[1],
          'X-User-Id': params[0],
        },
        errorHandler: (error) => {
          const { response } = error;
          return response;
        }
  });
}

export async function Dialpad(params) {
  return request(`/api/clickToDial`, {
        method: 'POST',
    data: params
  });
}

export async function CreateRoom(params){
  return request(`/api/createRoom`, {
    method: 'POST',
    data: params
  });
}

//livechat/closeRoom
export async function OpRoom(params,payload,query,rid){
  return request(`https://${espoConfig.RockChatUrl}/api/v1/`+query, {
    method: 'POST',
    headers: {
      'Accept': '*/*' ,
      'Host': espoConfig.RockChatUrl,
      'X-Auth-Token': params[1],
      'X-User-Id': params[0],
    },
    data:payload,
    errorHandler: (error) => {
      const { response } = error;
      return response;
    }
  });
}
