export default {
  'menu.sample': '示例',
  'menu.welcome': '欢迎',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.admin.sub-page': '二级管理页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.create': '新增',
  'menu.edit': '编辑',
  'menu.view': '查看',
  'menu.dashboard': '仪表盘',
  'menu.PhoneCalls': '电话',
  'menu.phoneCall.phoneCallEdit': '编辑',
  'menu.phoneCall.phoneCallView': '查看',
  'menu.phoneCallEdit': '编辑',
  'menu.phoneCallView': '查看',
  'menu.case': '工单',
  'menu.caseEdit': '编辑',
  'menu.caseView': '查看',
  'menu.Emails': '邮件',
  'menu.EmailEdit': '编辑',
  'menu.EmailView': '查看',
  'menu.knowledgeBaseArticle': '知识库',
  'menu.knowledgeBaseArticleEdit': '编辑',
  'menu.knowledgeBaseArticleView': '查看',
  'menu.SMS': '信息',
  'menu.SMSEdit': '编辑',
  'menu.SMSView': '查看',
  'menu.Chat': '对话',
  'menu.contact': '客人',
  'menu.contactEdit': '编辑',
  'menu.contactView': '查看',
  'menu.report': '报表',
  'menu.report.dashboard': '仪表盘',
  'menu.notification': '通知',
  'menu.report.sample': '示例',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
  'menu.more': '更多',
  'menu.more.create': '新增',
  'menu.more.edit': '编辑',
  'menu.more.view': '查看',
  'menu.more.Timesheet': '工时表',
  'menu.more.TimesheetEdit': '编辑',
  'menu.more.TimesheetView': '查看',
  'menu.more.AdminTimesheet': '工时管控',
  'menu.more.SMTimesheet': '工时管理',
  'menu.more.WriteUp': '违规记录',
  'menu.more.WriteUpEdit': '编辑',
  'menu.more.WriteUpView': '查看',
  'menu.more.AbsenceNote': '请假条',
  'menu.more.AbsenceNoteEdit': '编辑',
  'menu.more.AbsenceNoteView': '查看',
  'menu.more.AgentSurvey': '月满意度',
  'menu.more.AgentSurveyEdit': '编辑',
  'menu.more.AgentSurveyView': '查看',
  'menu.more.Signature': '签名',
  'menu.more.SignatureEdit': '编辑',
  'menu.more.SignatureView': '查看',
  'menu.more.Account': '账户',
  'menu.more.Team': '团队',
  'menu.more.User': '用户',
  'menu.fulfillment': '实现',
  'menu.fulfillment.create': '新增',
  'menu.fulfillment.edit': '编辑',
  'menu.fulfillment.view': '查看',
  'menu.fulfillment.Order': '订单',
  'menu.fulfillment.OrderLine':'记录',
  'menu.fulfillment.OrderEdit': '编辑',
  'menu.fulfillment.OrderView': '查看',
  'menu.fulfillment.Product': '产品',
  'menu.fulfillment.ProductEdit': '编辑',
  'menu.fulfillment.ProductView': '查看',
  'menu.Order': '订单',
  'menu.OrderEdit': '编辑',
  'menu.OrderView': '查看',
  'menu.Product': '产品',
  'menu.ProductEdit': '编辑',
  'menu.ProductView': '查看',
  'menu.Document': '文件',
  'menu.DocumentEdit': '编辑',
  'menu.DocumentView': '查看',
  'menu.more.Exam': '测试记录',
  'menu.more.ExamEdit': '编辑',
  'menu.more.ExamView': '查看',
  'menu.more.Quiz': '软技能试题',
  'menu.more.QuizEdit': '编辑',
  'menu.more.QuizView': '查看',
  'menu.more.CannedMessage': '快捷消息',
  'menu.more.CannedMessageEdit': '编辑',
  'menu.more.CannedMessageView': '查看',
  'menu.more.ScreenshotActivity': '监测槽',
  'menu.more.ScreenshotActivityEdit': '编辑',
  'menu.more.ScreenshotActivityView': '查看',
  'menu.more.ScreenshotAppsUrls': '监控应用程序',
  'menu.more.ScreenshotAppsUrlsEdit': '编辑',
  'menu.more.ScreenshotAppsUrlsView': '查看',
  'menu.more.ScreenshotReport': '检测总结',
  'menu.more.ScreenshotReportEdit': '编辑',
  'menu.more.ScreenshotReportView': '查看',
  'menu.more.ServiceRequest': '服务请求',
  'menu.more.ServiceRequestEdit': '编辑',
  'menu.more.ServiceRequestView': '查看',
  'menu.more.SpeedTest': '速度测试',
  'menu.more.SpeedTestEdit': '编辑',
  'menu.more.SpeedTestView': '查看',
  'menu.more.Screenshot': '监控截图',
  'menu.more.ScreenshotEdit': '编辑',
  'menu.more.ScreenshotView': '查看',
  'menu.stats': '分析',
  'menu.stats.Overview': '预览',
  'menu.stats.Agent': '客服',
  'menu.stats.PhoneCall': '电话',
  'menu.stats.Email': '邮件',
  'menu.stats.Chat': '聊天',
  'menu.ChatsOpen': '开启聊天',
  'menu.stats.AgentPerformance': '客服绩效',
  'menu.agent.AvgReply': '平均响应时长',
  'menu.agent.Chats': '聊天率',
  'menu.agent.AvgDuration': '平均时长',
  'menu.agent.Messages': '信息数',
  'menu.agent.AvgResponse': '平均响应时长',
  'menu.agent.BestResponse': '最佳响应时长',
  'menu.agent.AvgReaction': '平均回应时长',
  'menu.agent.TalkTime': '聊天时长',
  'menu.agent.WaitTime': '等待时长',
  'menu.agent.AvgResponses': '平均响应时长',
  'menu.agent.Emalititle': '邮件数量和响应时间',
  'menu.agent.ReceivedSent': '接收和发送',
  'menu.stats.Chattitle': '聊天',
  'menu.stats.TimeMetrics': '时间指标',
  'menu.case.action.open': ' 查看',
  'menu.case.action.quickview': '预览',
  'menu.case.action.delete': '删除',
  'menu.case.view.stream': '备注',
  'menu.case.view.email2': '邮件',
  'menu.case.view.call': '电话',
  'menu.case.view.sms': '短信',
  'menu.case.view.ChatRoom': '聊天室',
  'menu.case.view.webchat': '网站聊天',
  'menu.case.view.facebook': '脸书',
  'menu.case.view.stream2': '请在这里输入你的备注...',
  'menu.case.view.webchat2': '请在这里输入你的信息 ...',
  'menu.case.view.subject': '请在这里输入你的邮件主题 ...',
  'menu.case.view.email': '请在这里输入你的邮件信息 ...',
  'menu.stats.alert': '滑动查看更多',
  'menu.Channels':'渠道',
  'menu.Channels.PhoneCalls':'电话',
  'menu.Channels.Chats':'聊天',
  'menu.Channels.Emails':'邮箱',
  'menu.sms.Online':'在线',
  'menu.sms.Offline':' 下线 ',
  'menu.sms.typing':'正在输入',
  'menu.note.StandardDate':'日期',
  'menu.note.PrettyDate':'时间点',
  'menu.more.EmailTemplate': '邮件模板',
  'menu.Back':'返回',
  'menu.closeAll':'一键清空',
  'menu.case.view.feature':'您的账户未启用此功能',
  'menu.timeSheet.invoiceStatus':'你想改变发票状态吗？',
  'menu.case.view.FoldTheDialogue':'折叠对话',
  'menu.case.view.OpenaDialogue':'展开对话',
  'menu.stats.Csat': '满意度',
  'menu.stats.Csat.Export': '导出',
  'menu.stats.Csat.overview': '概览',
  'menu.stats.Csat.particulars': '详情',
  'menu.stats.Csat.positive': '积极',
  'menu.stats.Csat.neutrality': '中立',
  'menu.stats.Csat.passive': '消极',
  'menu.stats.Csat.verySatisfiedNum': '非常满意',
  'menu.stats.Csat.basicallySatisfiedNum': '基本满意',
  'menu.stats.Csat.neutralNum': '中立',
  'menu.stats.Csat.dissatisfiedNum': '不满意',
  'menu.stats.Csat.veryDissatisfiedNum': '非常不满意',
  'menu.speedTest.poorInternet':'当前网络不佳,请检查网络状况!',
  'menu.speedTest.noInternet':'网络连接已断开,请检查网络状况!',
  'menu.ChatsOpen.satisfactionrating':'服务评价',
  'menu.ChatsOpen.issueresolved':'问题是否解决:',
  'menu.ChatsOpen.servicerating':'服务满意度:',
  'menu.ChatsOpen.yes':'解决了',
  'menu.ChatsOpen.no':'未解决',
  'menu.ChatsOpen.comments':'反馈:',
  'menu.phoneCalls.missedCall':'来电未接',
  'menu.phone.notopenprompt':'注意：拨号盘未打开。',
  'menu.phone.dialpadhasopened':'拨号盘已打开。',
  'menu.modules.include.Archived':'包含存档',
  'menu.modules.include.Archived.Tips':'包含归档开关: 开启/关闭开启: 表示 查看 从当前时间开始,过去365天内的数据。关闭: 表示 查看 从当前时间开始,过去120天内的数据;页面默认选择是关闭状态。',
  'menu.modules.include.Archived.title.open': '开启',
  'menu.modules.include.Archived.title.close': '关闭',
  'menu.Signature.CKEditor.flie.tips': '附件太大。可发送附件的最大尺寸是50M，请重试。',
  'menu.Signature.CKEditor.title.tips': '签名字数太长，请减少字数后重试。',
  'menu.Notification.tips': '未读',
  'menu.Contact.email.bemixed': '该联系人已存在',
  'menu.searchFrom.saveAs': '另存为',
  'menu.SMTimesheet.workDate': '工作日期',
  'menu.SMTimesheet.totalWorkingHours': '工作时长',
  'menu.SMTimesheet.language': '语种',
  'menu.SMTimesheet.jobType': '工作类型',
};
