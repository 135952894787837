import component from './id-ID/component';
import globalHeader from './id-ID/globalHeader';
import menu from './id-ID/menu';
import pwa from './id-ID/pwa';
import settingDrawer from './id-ID/settingDrawer';
import settings from './id-ID/settings';
import pages from './id-ID/pages';

export default {
  'navBar.lang': 'Bahasa',
  'layout.user.link.help': 'Bantuan',
  'layout.user.link.privacy': 'Privasi',
  'layout.user.link.terms': 'Ketentuan',
  'app.preview.down.block': 'Unduh halaman ini ke proyek lokal Anda',
  'app.welcome.link.fetch-blocks': 'Ambil seluruh block',
  'app.welcome.link.block-list': 'Buat standar dengan cepat, halaman berdasarkan pada pengembangan`block`',
  'search.info': `To refine your search keyword, please see examples below:

  1. If no operator is used, then search is based on OR.Below  
     is a search of records with 'refund' or 'return' keyword:
     refund return
  
  2. Use + to indicate AND:
     +michael +jackson
  
  3. Use - to indicate NOT:
     +michael -jackson
  
  4. Use * to indicate partial keyword. Search records with 
     phone number 16502329999:
     1650232*
  
  5. Use " to search exact phrase:
     "broken package"`,
  'charts.tickets': 'Tiket ditutup',
  'charts.phoneCalls': 'Panggilan telepon',
  'charts.emails': 'Email',
  'charts.chats': 'Chat',
  'charts.tooltip.overview':
    'Tinjauan tiket yang dibuat dan ditutup menurut channel dan tanggal. Anda juga dapat melihat metrik dasar aktivitas panggilan, email, dan obrolan.',
  'charts.tooltip.callsTotal': 'Based on all calls, including unanswered or after-hour calls.',
  'charts.tooltip.callUnanswered':
    'Berdasarkan semua panggilan tak terjawab, termasuk pesan suara, panggilan tak terjawab atau panggilan yang ditutup sebelum waktunya. Panggilan pada jam kerja dan setelah jam kerja juga akan terhitung.',
  'charts.tooltip.avgWait':
    'Berdasarkan hanya pada panggilan masuk yang dijawab oleh agen. Ini tidak termasuk panggilan yang menghasilkan pesan suara setelah mencapai batas maksimal waktu tunggu.',
  'charts.tooltip.avgTalk': 'Berdasarkan pada panggilan masuk yang dijawab oleh agen.',
  'charts.tooltip.chatsTotal': 'Berdasarkan pada seluruh chat, termasuk sesi obrolan offline.',
  'charts.tooltip.chatsMissed': 'Berdasarkan pada pesan masuk saat tidak ada agen yang sedang online.',
  'charts.tooltip.email': 'Email yang dikirim/diterima dan waktu respon rata-rata per jam setiap harinya.',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
};
