import { queryCurrent, query as queryUsers, updateUserOnlineStatus } from '@/services/user';
import { setAuthority } from '@/utils/authority';
import request from '@/utils/request';
import { Base64 } from 'js-base64';

const UserModel = {
  namespace: 'user',
  state: {

  },
  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },

    *fetchCurrent(_, { call, put }) {
      const userName = window.atob(localStorage.getItem('auth-username'));
      const authToken = window.atob(localStorage.getItem('auth-token'));
      const token = `${userName}:${authToken}`;
      const encodedToken = Base64.encode(token);

      request.extendOptions({
        headers: {
          Authorization: `Basic ${encodedToken}`,
          'Espo-Authorization': `${encodedToken}`,
          'Espo-Authorization-By-Token': true,
        }
      });

      const response = yield call(queryCurrent);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },

    *updateOnlineStatus({ payload }, { call, put }) {
      const response = yield call(updateUserOnlineStatus, payload.user, {
        onlineStatus: payload.status,
      });
      yield put({
        type: 'setStatus',
        payload: response
      });
    },

    // *updatePreferences({ payload }, { call, put }) {
    //   const response = yield call(updateUserPreferences, payload);
    //   yield put({
    //     type: 'setUserPreferences',
    //     payload: response,
    //   });
    // },

    *clear(_, { put }) {
      yield put({
        type: 'clearUser',
      });
    }
  },
  reducers: {
    clearUser() {
      return {}
    },
    setStatus(state, action) {
      const { user } = state;
      return { ...state, user: { ...user, ...action.payload } };
    },
    saveCurrentUser(state, { payload }) {
      const { settings } = payload;
      if (settings && settings.tabList) {
        setAuthority(settings.tabList.filter(a => !a.startsWith('_')));
      }
      return { ...state, ...payload };
    },
    setUserPreferences(state, action) {
      return { ...state, preferences: action.payload }
    },

    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
export default UserModel;
