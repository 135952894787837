import request from '@/utils/request';

export async function getOutstandingSmsMessages() {
  return request('/api/SMS?select=name,from,createdAt,type,status&status=Missed&type=sms&maxSize=5&offset=0');
}

export async function getOutstandingLiveCharts() {
  return request('/api/SMS?select=name,from,createdAt,type,status&status=Missed&type=livechart&maxSize=5&offset=0');
}

export async function getOutstandingFacebookMessages() {
  return request('/api/SMS?select=name,from,createdAt,type,status&status=Missed&type=facebook&maxSize=5&offset=0');
}

export async function getSelectChatDataByRoomId(payload){
  return request(`/api/rocketChat/selectChatDataByRoomId?${payload}`,{
    method:'GET'
  });
}
