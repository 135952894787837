export default {
  'app.setting.pagestyle': 'Pengaturan Mode Halaman',
  'app.setting.pagestyle.dark': 'Mode Gelap',
  'app.setting.pagestyle.light': 'Mode Terang',
  'app.setting.content-width': 'Lebar Konten',
  'app.setting.content-width.fixed': 'Fixed',
  'app.setting.content-width.fluid': 'Fluid',
  'app.setting.themecolor': 'Warna Tema',
  'app.setting.themecolor.dust': 'Dust Red',
  'app.setting.themecolor.volcano': 'Volcano',
  'app.setting.themecolor.sunset': 'Sunset Orange',
  'app.setting.themecolor.cyan': 'Cyan',
  'app.setting.themecolor.green': 'Polar Green',
  'app.setting.themecolor.daybreak': 'Daybreak Blue (default)',
  'app.setting.themecolor.geekblue': 'Geek Glue',
  'app.setting.themecolor.purple': 'Golden Purple',
  'app.setting.navigationmode': 'Mode Navigasi',
  'app.setting.sidemenu': 'Side Menu Layout',
  'app.setting.topmenu': 'Top Menu Layout',
  'app.setting.fixedheader': 'Fixed Header',
  'app.setting.fixedsidebar': 'Fixed Sidebar',
  'app.setting.fixedsidebar.hint': 'Bekerja di side Menu Layout',
  'app.setting.hideheader': 'Sembunyikan Header saat scrolling',
  'app.setting.hideheader.hint': 'Bekerja saat Hidden Header diaktifkan',
  'app.setting.othersettings': 'Pengaturan Lainnya',
  'app.setting.weakmode': 'Mode Lemah',
  'app.setting.copy': 'Salin Pengaturan',
  'app.setting.copyinfo': 'Berhasil disalin, silakan ubah pengaturan awal di src/models/setting.js',
  'app.setting.production.hint':
    'Panel pengaturan hanya ditampilkan di mode pengembangan, silakan ubah secara manual',
};
