import CommentsApi from "@/services/comments";

export default {
    namespace: 'teamsUser',
    state: {
        teams: [],
        name: '',
        tid: '',
    },
    effects: {
        *fetch(_, {call, put}) {
            const response = yield call(CommentsApi.getCommentsUser);
            yield put({
                type: 'setResult',
                payload: response
            });
        }
    },

    reducers: {
        setResult(_, { payload }) {
            return payload;
        },
        clear() {
            return {};
        }
    }
}