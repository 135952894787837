export default {
  namespace: 'window',
  state: {
    current: null,
  },
  reducers: {
    set(_, { payload }) {
      return payload;
    }
  }
}