import { getI18n } from "@/services/i18n"
import i18n from "../../mock/i18n";
import espoConfig from "../../config/espoConfig";

export default {
  namespace: 'i18n',
  state: {},

  effects: {
    *fetch(_, {call, put}) {
      if (espoConfig.useMock) {
        yield put({
          type: 'setI18n',
          payload: i18n
        });
      } else {
        const response = yield call(getI18n);
        yield put({
          type: 'setI18n',
          payload: response
        });
      }
    },

    *clear(_, { put }) {
      yield put({
        type: 'clearI18n',
      });
    }
  },

  reducers: {
    setI18n(_, { payload }) {
      return payload;
    },
    clearI18n() {
      return {};
    }
  }
}