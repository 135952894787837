/*
 * @Author: wake.wu HUYpBVyzQMH5Xi55
 * @Date: 2023-02-13 13:28:23
 * @LastEditors: wake.wu HUYpBVyzQMH5Xi55
 * @LastEditTime: 2023-04-24 10:42:50
 * @FilePath: \espo-ui\config\espoConfig.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { TrophyOutlined } from '@ant-design/icons';

export default {
  useMock: false,
  useDrawer: true, // view/edit/create 是否使用drawer
  phoneCallUrl: 'https://mycrm2.callnovo.net/dialpad/',
  chatUrl: 'https://message.callnovo.net/home',
  EspoChatUrl: 'mycrm2.callnovo.net',
  RockChatUrl: 'message.callnovo.net',
  autoOpenWindows: false,
  enabledModules: ['PhoneCall', 'Case', 'Contact'],
  defaultTableColumnCount: 30,
  modules: {
    PhoneCall: {
      defaultTableColumnCount: 30,
    },
  },
};
