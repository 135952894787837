export default {
  'pages.layouts.userLayout.title':
    'Ant Design adalah web design spesifikasi yang paling berpengaruh di Distrik Xihu',
  'pages.login.accountLogin.tab': 'Login Akun',
  'pages.login.accountLogin.errorMessage': 'Username/Password salah (admin/ant.design)',
  'pages.login.username.placeholder': 'Username',
  'pages.login.username.required': 'Silakan masukkan username Anda!',
  'pages.login.password.placeholder': 'Password',
  'pages.login.password.required': 'Silakan masukkan password Anda!',
  'pages.login.phoneLogin.tab': 'Login Telepon',
  'pages.login.phoneLogin.errorMessage': 'Kode Verifikasi salah',
  'pages.login.phoneNumber.placeholder': 'Nomor Telepon',
  'pages.login.phoneNumber.required': 'Silakan masukkan nomor telepon Anda!',
  'pages.login.phoneNumber.invalid': 'Nomor telepon tidak valid!',
  'pages.login.captcha.placeholder': 'Kode verifikasi',
  'pages.login.captcha.required': 'Silakan masukkan kode verifikasi!',
  'pages.login.phoneLogin.getVerificationCode': 'Minta kode',
  'pages.getCaptchaSecondText': 'detik',
  'pages.login.rememberMe': 'Ingat saya',
  'pages.login.forgotPassword': 'Lupa Password?',
  'pages.login.submit': 'Kirim',
  'pages.login.loginWith': 'Login dengan:',
  'pages.login.registerAccount': 'Register Akun',
  'pages.welcome.advancedComponent': 'Komponen Advanced',
  'pages.welcome.link': 'Selamat Datang',
  'pages.welcome.advancedLayout': 'Layout Advanced',
  'pages.welcome.alertMessage': 'Komponen yang lebih cepat dan lebih kuat telah dirilis.',
  'pages.admin.subPage.title': 'Halaman ini hanya dapat dilihat oleh Admin',
  'pages.admin.subPage.alertMessage':
    'Umi ui telah dirilis, silakan gunakan npm run ui untuk mulai mencoba.',
  'pages.searchTable.createForm.newRule': 'Aturan Baru',
  'pages.searchTable.updateForm.ruleConfig': 'Konfigurasi Aturan',
  'pages.searchTable.updateForm.basicConfig': 'Informasi Dasar',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Nama Aturan',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Harap masukkan nama aturan!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Deskripsi Aturan',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Mohon masukkan paling sedikit 5 karakter',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Mohon masukkan deskripsi aturan paling sedikit 5 karakter!',
  'pages.searchTable.updateForm.ruleProps.title': 'Konfigurasi Properti',
  'pages.searchTable.updateForm.object': 'Objek Monitoring',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Template Aturan',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Jenis Aturan',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Atur Periode Jadwal',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Waktu Mulai',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Silakan pilih waktu mulai!',
  'pages.searchTable.titleDesc': 'Deskripsi',
  'pages.searchTable.ruleName': 'Nama Aturan diperlukan',
  'pages.searchTable.titleCallNo': 'Jumlah Layanan Telepon',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'default',
  'pages.searchTable.nameStatus.running': 'running',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'abnormal',
  'pages.searchTable.titleUpdatedAt': 'Jadwal terakhir pada',
  'pages.searchTable.exception': 'Mohon masukkan alasan pengecualian!',
  'pages.searchTable.titleOption': 'Opsi',
  'pages.searchTable.config': 'Konfigurasi',
  'pages.searchTable.subscribeAlert': 'Berlangganan Peringatan',
  'pages.searchTable.title': 'Formulir Pertanyaan',
  'pages.searchTable.new': 'Baru',
  'pages.searchTable.chosen': 'Terpilih',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Jumlah Total Layanan Telepon',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'Hapus per batch',
  'pages.searchTable.batchApproval': 'Terima per batch',
  'pages.commentRequired': 'Komentar diperlukan.',
  'pages.dashboard.opentickets': 'Buka tiket',
  'pages.dashboard.ticketstoupdate': 'Perbarui tiket',
  'pages.dashboard.callstoreturn': 'Panggilan telepon untuk kembali',
  'pages.dashboard.callsticket': 'Telepon tanpa tiket',
  'pages.dashboard.missedsms': 'SMS tidak terbaca',
  'pages.dashboard.missedWebchat': 'Webchat tidak terbaca',
  'pages.dashboard.missedfbmsges': 'Pesan FB tidak terbaca',
  'pages.dashboard.goal': 'Target',
  'pages.dashboard.monthlychange': 'Perubahan Bulanan',
  'pages.dashboard.csat': 'CSAT',
  'pages.dashboard.csatrating': 'CSAT Penilaian Bintang 5',
  'pages.dashboard.csatlowrating': 'CSAT Penilaian Bintang Rendah',
  'pages.dashboard.averagewaittimeange': 'Rata-rata Waktu Menunggu',
  'pages.dashboard.unexecutedunanswered': 'Tidak terjawab dan tidak tereksekusi',
  'pages.dashboard.csattip': 'Silakan minta penilaian di setiap akhir panggilan',
  'pages.dashboard.csatratingtip': 'Sediakan Customer Service yang terbaik',
  'pages.dashboard.csatlowratingtip': 'Kembangkan saat di situasi sulit',
  'pages.dashboard.averagewaittimeangetip': 'Angkat telepon lebih cepat',
  'pages.dashboard.unexecutedunansweredtip': 'Usahakan untuk tidak melewatkan panggilan',
  'pages.stats.overview': 'Tinjauan',
  'pages.stats.callsTotal': 'Total Panggilan',
  'pages.stats.callUnanswered': 'Panggilan tidak terjawab',
  'pages.stats.avgWait': 'Rata-rata Menunggu',
  'pages.stats.avgTalk': 'Rata-rata Berbicara',
  'pages.stats.chatsTotal': 'Total Chat',
  'pages.stats.chatsMissed': 'Chat tidak terbaca',
  'pages.stats.emailSent': 'Email yang terkirim',
  'pages.stats.emailReceived': 'Email yang diterima',
  'pages.stats.ticketscreated': 'Tiket yang dibuat',
  'pages.stats.ticketsclosed': 'Tiket yang ditutup',
  'pages.stats.ticketscreatedbyhour': 'Tiket yang dibuat berdasarkan waktu',
  'pages.stats.creationdate': 'Tanggal buat',
  'pages.stats.phonecallanalytics': 'Analisa Panggilan Telepon',
  'pages.stats.inbound': 'Panggilan masuk',
  'pages.stats.outbound': 'Panggilan keluar',
  'pages.stats.answeredvsunanswered': 'Terjawab vs. Tidak terjawab',
  'pages.stats.inboundoutbound': 'Panggilan masuk vs. Panggilan keluar',
  'pages.stats.rating': 'Rating',
  'pages.stats.inboundanswered': 'Panggilan masuk yang terjawab',
  'pages.stats.inboundunAnswered': 'Panggilan masuk yang tidak terjawab',
  'pages.stats.outboundanswered': 'Panggilan keluar yang terjawab',
  'pages.stats.outboundunanswered': 'Panggilan keluar yang tidak terjawab',
  'pages.stats.ratedtotal': 'Total yang dinilai',
  'page.case.view.msg.success': 'Sukses',
  'pages.stats.inbounda': 'Panggilan masuk <br> Terjawab',
  'pages.stats.outbounda': 'Panggilan Keluar <br> Terjawab',
  'page.stats.overview.ticket': 'Volume Dukungan',
  'page.stats.overview.chat': 'Volume Metrik',
  'page.stats.overview.channel': 'Berdasarkan Channel',
  'page.stats.overview.respondedandmiss': 'Terjawab vs. Tidak terjawab',
  'page.stats.overview.workperiod': 'Berdasarkan Periode Kerja',
  'page.stats.overview.missedduringwork': 'Terjawab vs. Tidak terjawab selama Jam Kerja',
  'pages.stats.Yes': 'Ya',
  'pages.stats.No': 'Tidak',
  'pages.stats.numberoftickets':'Jumlah Tiket',
  'pages.stats.day':'Hari',
  'pages.stats.month':'Bulan',
  'pages.stats.Completed':'Selesai',
  'pages.stats.missed':'Tidak terjawab',
  'pages.stats.hungup':'Ditutup',
  'pages.stats.voicemail':'Pesan Suara',
  'pages.stats.busy':'Sedang sibuk',
  'pages.stats.talked':'Telah berbicara',
  'pages.stats.unreachable':'Tidak dapat dihubungi',
  'pages.stats.inboundtaken':'Panggilan masuk tidak terjawab<br>Ditangani oleh',
  'pages.stats.waittimesla':'Waktu Tunggu SLA (detik)',
  'pages.stats.received':'Diterima',
  'pages.stats.sent':'Telah dikirimkan',
  'pages.stats.ticketsbytypes':'Tiket berdasarkan tipe',
  'pages.stats.type':'Tipe',
  'pages.stats.created':'Telah dibuat',
  'pages.stats.closed':'Telah ditutup',
  'pages.stats.avg':'Rata-rata waktu selesai pemrosesan (detik)',
  'pages.stats.inprogress':'Sedang diproses',
  'pages.stats.directsolution':'Solusi Arahan',
  'pages.stats.avgresponsetimeHour':'Rata-rata waktu respon (jam)',
  'pages.stats.emailvolume':'Volume Email',
  'pages.stats.emailresponsetime':'Waktu Respon Email SLA (jam)',
  'pages.stats.minute':'menit',
  'pages.stats.second':'detik',
  'pages.stats.web':'Web',
  'pages.stats.avgchatduration':'Rata-rata waktu chat',
  'pages.stats.avgfirstresponsetime':'Rata-rata waktu respon pertama',
  'pages.stats.avgrectiontime':'Rata-rata waktu reaksi',
  'pages.stats.avgresponsetime':'Rata-rata waktu respon',
  'pages.stats.bestresponsetime':'Waktu Respon terbaik',
  'pages.stats.totalconversations':'Total Percakapan',
  'pages.stats.totalmessages':'Total Pesan',
  'pages.contectEdit.validPhone':'Mohon masukkan nomor telepon yang valid'
};
