import request from '@/utils/request';

export async function query() {
  return request('/api/users');
}
export async function queryCurrent() {
  return request('/api/App/user');
}
export async function queryNotices() {
  return request('/api/notices');
}

export async function updateUserOnlineStatus(user, payload) {
  return request(`/api/User/${user.id}`, {
    method: 'PUT',
    data: payload
  });
}

export async function updateUserPreferences(preferences) {
  return request(`/api/Preferences/${preferences.id}`, {
    method: 'PUT',
    data: preferences
  });
}
export async function queryUser(name) {
  return request(`/api/User?q=${name}&primaryFilter=activeorderBy=name&maxSize=20&select=id,name,userName`);
}

export async function queryLastViewed() {
  return request('/api/LastViewed?maxSize=100&offset=0&orderBy=number&order=desc');
}
