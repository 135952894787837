export default {
  'component.globalHeader.search': 'Pencarian',
  'component.globalHeader.search.example1': 'Contoh Pencarian 1',
  'component.globalHeader.search.example2': 'Contoh Pencarian 2',
  'component.globalHeader.search.example3': 'Contoh Pencarian 3',
  'component.globalHeader.help': 'Bantuan',
  'component.globalHeader.notification': 'Notifikasi',
  'component.globalHeader.notification.empty': 'Anda telah melihat seluruh notifikasi.',
  'component.globalHeader.message': 'Pesan',
  'component.globalHeader.message.empty': 'Anda telah membaca semua pesan.',
  'component.globalHeader.event': 'Event',
  'component.globalHeader.event.empty': 'Anda telah melihat seluruh event.',
  'component.noticeIcon.clear': 'Bersihkan',
  'component.noticeIcon.cleared': 'Telah dibersihkan',
  'component.noticeIcon.empty': 'Tidak ada notifikasi',
  'component.noticeIcon.view-more': 'Lihat selengkapnya',
};
