import { getNotifications, getNotReadCount, markAllNotificationAsRead, markNotificationAsRead } from "@/services/notification";
import moment from 'moment';

export default {
  namespace: 'notification',
  state: {
    notReadCount: 0,
    total: 0,
    list: [],
    lastTime: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  },

  effects: {
    *fetchNotifications(_, { call, put }) {
      const response = yield call(getNotifications);
      yield put({
        type: 'setNotification',
        payload: response
      });

      if (response.list && response.list.length) {
        yield put({
          type: 'setLastTime',
          payload: {
            lastTime: response.list[0].createdAt,
          }
        });
      }
    },

    *fetchNotReadCount(_, { call, put }) {
      const response = yield call(getNotReadCount);
      const t = typeof response;
      if (t === 'number' || t === 'string') {
        yield put({
          type: 'setNotReadCount',
          payload: {
            notReadCount: response,
          }
        });
      }
    },

    *markAllRead(_, { call, put, select }) {
      yield call(markAllNotificationAsRead);

      yield put({
        type: 'setNotReadCount',
        payload: {
          notReadCount: 0,
        }
      });
      
      const list = yield select(state => state.notification.list);
      if (list && list.length) {
        yield put({
          type: 'setList',
          payload: {
            list: list.map(obj => ({
              ...obj,
              read: true
            })),
          }
        });
      }
    },

    *markAsRead({ payload }, { call, put, select }) {
      yield call(markNotificationAsRead, payload);
      const list = yield select(state => state.notification.list);
      if (list && list.length) {
        yield put({
          type: 'setList',
          payload: {
            list: list.map(obj => ({
              ...obj,
              read: obj.id === payload ? true : obj.read,
            })),
          }
        });
      }
    },
  },

  reducers: {
    setList(state, { payload }) {
      return { ...state, list: payload.list };
    },
    setNotification(state, { payload }) {
      return { ...state, ...payload };
    },
    setLastTime(state, { payload }) {
      const { lastTime } = payload;
      return { ...state, lastTime };
    },
    setNotReadCount(state, { payload }) {
      return { ...state, ...payload };
    }
  }
}