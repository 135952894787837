// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/var/lib/jenkins/workspace/espo-ui-mycrm-prod/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "login",
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__login' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/User/login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/login/login",
        "redirect": "/login",
        "exact": true
      }
    ]
  },
  {
    "path": "/lnk",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__LnkLayout' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/layouts/LnkLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "lnk",
        "path": "/lnk/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Lnk__Index' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Lnk/Index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/Lnk/Chats",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Lnk__BaseChats' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Lnk/BaseChats'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/lnk/chats/:tid",
            "name": "lnk",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__Dashboard__DashboardActivity' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/components/Dashboard/DashboardActivity'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "redirect": "/Case",
            "exact": true
          },
          {
            "path": "/exception/404",
            "name": "404",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/404'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/exception/403",
            "name": "403",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__403' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/403'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/exception/500",
            "name": "500",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__500' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/500'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/dashboard",
            "authority": [
              "admin",
              "user"
            ],
            "name": "dashboard",
            "icon": "dashboard",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Dashboard'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Contact",
            "authority": [
              "Contact"
            ],
            "name": "contact",
            "icon": "team",
            "filed": "Contact",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contact__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Contact/List'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/Contact/create",
            "authority": [
              "Contact"
            ],
            "name": "create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contact__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Contact/Create'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Contact/edit/:id",
            "authority": [
              "Contact"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contact__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Contact/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Contact/view/:id",
            "authority": [
              "Contact"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contact__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Contact/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Case",
            "authority": [
              "Case"
            ],
            "name": "case",
            "icon": "solution",
            "filed": "Case",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Case__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Case/List'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/Case/create",
            "authority": [
              "Case"
            ],
            "name": "create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Case__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Case/Create'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Case/edit/:id",
            "authority": [
              "Case"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Case__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Case/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Case/view/:id",
            "authority": [
              "Case"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Case__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Case/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/ChatsOpen",
            "name": "Chatting With",
            "hideInMenu": true,
            "routes": [
              {
                "path": "/ChatsOpen/view/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Case__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Case/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/PhoneCall/create",
            "authority": [
              "PhoneCall"
            ],
            "name": "create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PhoneCall__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/PhoneCall/Create'), loading: LoadingComponent}),
            "key": "/channels/PhoneCall",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/PhoneCall/edit/:id",
            "authority": [
              "PhoneCall"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PhoneCall__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/PhoneCall/Edit'), loading: LoadingComponent}),
            "key": "/channels/PhoneCall",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/PhoneCall/view/:id",
            "authority": [
              "PhoneCall"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PhoneCall__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/PhoneCall/View'), loading: LoadingComponent}),
            "key": "/channels/PhoneCall",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Chat/create",
            "authority": [
              "Chat"
            ],
            "name": "create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Chat__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Chat/Create'), loading: LoadingComponent}),
            "key": "/channels/Chat",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Chat/edit/:id",
            "authority": [
              "Chat"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Chat__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Chat/Edit'), loading: LoadingComponent}),
            "key": "/channels/Chat",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Chat/view/:id",
            "authority": [
              "Chat"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Chat__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Chat/View'), loading: LoadingComponent}),
            "key": "/channels/Chat",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Account/view/:id",
            "authority": [
              "Account"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Account/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Channels",
            "icon": "AppstoreAddOutlined",
            "path": "/channels",
            "authority": [
              "PhoneCall",
              "Chat",
              "Email"
            ],
            "routes": [
              {
                "path": "/channels/PhoneCall",
                "authority": [
                  "PhoneCall"
                ],
                "name": "PhoneCalls",
                "icon": "phone",
                "filed": "PhoneCall",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PhoneCall__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/PhoneCall/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/channels/PhoneCall/create",
                "authority": [
                  "PhoneCall"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PhoneCall__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/PhoneCall/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/channels/PhoneCall/edit/:id",
                "authority": [
                  "PhoneCall"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PhoneCall__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/PhoneCall/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/channels/PhoneCall/view/:id",
                "authority": [
                  "PhoneCall"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PhoneCall__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/PhoneCall/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/channels/Chat",
                "authority": [
                  "Chat"
                ],
                "name": "Chats",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Chat__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Chat/List'), loading: LoadingComponent}),
                "filed": "Chat",
                "exact": true
              },
              {
                "path": "/channels/Chat/create",
                "authority": [
                  "Chat"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Chat__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Chat/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/channels/Chat/edit/:id",
                "authority": [
                  "Chat"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Chat__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Chat/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/channels/Chat/view/:id",
                "authority": [
                  "Chat"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Chat__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Chat/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/channels/Email",
                "authority": [
                  "Email"
                ],
                "name": "Emails",
                "icon": "Mail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Email__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Email/List'), loading: LoadingComponent}),
                "filed": "Email",
                "exact": true
              },
              {
                "path": "/channels/Email/create",
                "authority": [
                  "Email"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Email__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Email/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/channels/Email/edit/:id",
                "authority": [
                  "Email"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Email__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Email/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/channels/Email/view/:id",
                "authority": [
                  "Email"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Email__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Email/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/checkinout",
            "authority": [
              "Check In/Out"
            ],
            "name": "Check In/Out",
            "icon": "dashboard",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Dashboard'), loading: LoadingComponent}),
            "filed": "Dashboard",
            "exact": true
          },
          {
            "path": "/KnowledgeBaseArticle",
            "authority": [
              "KnowledgeBaseArticle"
            ],
            "name": "knowledgeBaseArticle",
            "icon": "database",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__KnowledgeBaseArticle__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/KnowledgeBaseArticle/List'), loading: LoadingComponent}),
            "filed": "KnowledgeBaseArticle",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/KnowledgeBaseArticle/create",
            "authority": [
              "KnowledgeBaseArticle"
            ],
            "name": "create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__KnowledgeBaseArticle__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/KnowledgeBaseArticle/Create'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/KnowledgeBaseArticle/edit/:id",
            "authority": [
              "KnowledgeBaseArticle"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__KnowledgeBaseArticle__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/KnowledgeBaseArticle/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/KnowledgeBaseArticle/view/:id",
            "authority": [
              "KnowledgeBaseArticle"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__KnowledgeBaseArticle__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/KnowledgeBaseArticle/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/SMS/create",
            "authority": [
              "SMS"
            ],
            "name": "create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SMS__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/SMS/Create'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/SMS/edit/:id",
            "authority": [
              "SMS"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SMS__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/SMS/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/SMS/view/:id",
            "authority": [
              "SMS"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SMS__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/SMS/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Document",
            "authority": [
              "Document"
            ],
            "name": "Document",
            "icon": "FolderOpen",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Document__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Document/List'), loading: LoadingComponent}),
            "filed": "Document",
            "exact": true
          },
          {
            "path": "/Document/create",
            "authority": [
              "Document"
            ],
            "name": "create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Document__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Document/Create'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Document/edit/:id",
            "authority": [
              "Document"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Document__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Document/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Document/view/:id",
            "authority": [
              "Document"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Document__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Document/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Call/view/:id",
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Call__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Call/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Notification",
            "name": "notification",
            "icon": "Sound",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Notification' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Notification'), loading: LoadingComponent}),
            "filed": "Notification",
            "exact": true
          },
          {
            "path": "/OrderLine/view/:id",
            "authority": [
              "OrderLine"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderLine__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/OrderLine/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/OrderLine/edit/:id",
            "authority": [
              "OrderLine"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderLine__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/OrderLine/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Product/create",
            "authority": [
              "Product"
            ],
            "name": "create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Product/Create'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Product/edit/:id",
            "authority": [
              "Product"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Product/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Product/view/:id",
            "authority": [
              "Product"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Product/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Order/create",
            "authority": [
              "Order"
            ],
            "name": "create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Order/Create'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Order/edit/:id",
            "authority": [
              "Order"
            ],
            "name": "edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Order/Edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/Order/view/:id",
            "authority": [
              "Order"
            ],
            "name": "view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Order/View'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "stats",
            "icon": "snippets",
            "path": "/stats",
            "routes": [
              {
                "path": "/stats/Overview",
                "name": "Overview",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Overview__index' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Overview/index'), loading: LoadingComponent}),
                "filed": "Overview",
                "exact": true
              },
              {
                "path": "/stats/Agent",
                "name": "Agent",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Agent__index' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Agent/index'), loading: LoadingComponent}),
                "filed": "Agent",
                "exact": true
              },
              {
                "path": "/stats/PhoneCall",
                "name": "PhoneCall",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ReportPhoneCall__index' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ReportPhoneCall/index'), loading: LoadingComponent}),
                "filed": "PhoneCall",
                "exact": true
              },
              {
                "path": "/stats/Email",
                "name": "Email",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ReportEmail__index' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ReportEmail/index'), loading: LoadingComponent}),
                "filed": "Email",
                "exact": true
              },
              {
                "path": "/stats/Chat",
                "name": "Chat",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ReportChat__index' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ReportChat/index'), loading: LoadingComponent}),
                "filed": "Chat",
                "exact": true
              },
              {
                "path": "/stats/Csat",
                "name": "Csat",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Csat__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Csat/View'), loading: LoadingComponent}),
                "filed": "Csat",
                "exact": true
              }
            ]
          },
          {
            "name": "fulfillment",
            "icon": "shop",
            "path": "/fulfillment",
            "authority": [
              "Product",
              "Order",
              "OrderLine"
            ],
            "routes": [
              {
                "path": "/fulfillment/Product",
                "authority": [
                  "Product"
                ],
                "name": "Product",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Product/List'), loading: LoadingComponent}),
                "filed": "Product",
                "hideInMenu": true,
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/Product/view/:id",
                    "authority": [
                      "Product"
                    ],
                    "name": "Product",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Product/View'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "hideChildrenInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/Product/create",
                    "authority": [
                      "Product"
                    ],
                    "name": "Product",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Product/Create'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "hideChildrenInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/Product/edit/:id",
                    "authority": [
                      "Product"
                    ],
                    "name": "Product",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Product/Edit'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "hideChildrenInMenu": true,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/fulfillment/Order",
                "authority": [
                  "Order"
                ],
                "name": "Order",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Order/List'), loading: LoadingComponent}),
                "filed": "Order",
                "hideInMenu": true,
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/Order/create",
                    "authority": [
                      "Order"
                    ],
                    "name": "Order",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Order/Create'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "hideChildrenInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/Order/edit/:id",
                    "authority": [
                      "Order"
                    ],
                    "name": "Order",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Order/Edit'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "hideChildrenInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/Order/view/:id",
                    "authority": [
                      "Order"
                    ],
                    "name": "Order",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Order/View'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "hideChildrenInMenu": true,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/fulfillment/OrderLine",
                "authority": [
                  "OrderLine"
                ],
                "name": "OrderLine",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderLine__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/OrderLine/List'), loading: LoadingComponent}),
                "filed": "OrderLine",
                "hideInMenu": true,
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/OrderLine/view/:id",
                    "authority": [
                      "OrderLine"
                    ],
                    "name": "OrderLine",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderLine__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/OrderLine/View'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "hideChildrenInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/OrderLine/edit/:id",
                    "authority": [
                      "OrderLine"
                    ],
                    "name": "OrderLine",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderLine__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/OrderLine/Edit'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "hideChildrenInMenu": true,
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "name": "more",
            "icon": "more",
            "routes": [
              {
                "path": "/Timesheet",
                "authority": [
                  "Timesheet"
                ],
                "name": "Timesheet",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Timesheet__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Timesheet/List'), loading: LoadingComponent}),
                "filed": "Timesheet",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Timesheet/create",
                "authority": [
                  "Timesheet"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Timesheet__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Timesheet/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Timesheet/edit/:id",
                "authority": [
                  "Timesheet"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Timesheet__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Timesheet/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Timesheet/view/:id",
                "authority": [
                  "Timesheet"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Timesheet__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Timesheet/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/SMTimesheet",
                "authority": [
                  "Case"
                ],
                "name": "SMTimesheet",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SMTimesheet__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/SMTimesheet/List'), loading: LoadingComponent}),
                "filed": "Case",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/SMTimesheet/view/:id",
                "authority": [
                  "Case"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SMTimesheet__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/SMTimesheet/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/AdminTimesheet",
                "authority": [
                  "Case"
                ],
                "name": "AdminTimesheet",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AdminTimesheet__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AdminTimesheet/List'), loading: LoadingComponent}),
                "filed": "Case",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/AdminTimesheet/create",
                "authority": [
                  "Case"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AdminTimesheet__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AdminTimesheet/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/AdminTimesheet/edit/:id",
                "authority": [
                  "Case"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AdminTimesheet__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AdminTimesheet/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/AdminTimesheet/view/:id",
                "authority": [
                  "Case"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AdminTimesheet__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AdminTimesheet/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/WriteUp",
                "authority": [
                  "WriteUp"
                ],
                "name": "WriteUp",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WriteUp__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/WriteUp/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "WriteUp",
                "exact": true
              },
              {
                "path": "/WriteUp/create",
                "authority": [
                  "WriteUp"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WriteUp__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/WriteUp/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/WriteUp/edit/:id",
                "authority": [
                  "WriteUp"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WriteUp__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/WriteUp/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/WriteUp/view/:id",
                "authority": [
                  "WriteUp"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WriteUp__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/WriteUp/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/AbsenceNote",
                "authority": [
                  "AbsenceNote"
                ],
                "name": "AbsenceNote",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AbsenceNote__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AbsenceNote/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "AbsenceNote",
                "exact": true
              },
              {
                "path": "/AbsenceNote/create",
                "authority": [
                  "AbsenceNote"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AbsenceNote__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AbsenceNote/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/AbsenceNote/edit/:id",
                "authority": [
                  "AbsenceNote"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AbsenceNote__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AbsenceNote/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/AbsenceNote/view/:id",
                "authority": [
                  "AbsenceNote"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AbsenceNote__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AbsenceNote/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/AgentSurvey",
                "authority": [
                  "AgentSurvey"
                ],
                "name": "AgentSurvey",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentSurvey__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AgentSurvey/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "AgentSurvey",
                "exact": true
              },
              {
                "path": "/AgentSurvey/view/:id",
                "authority": [
                  "AgentSurvey"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AgentSurvey__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/AgentSurvey/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Signature",
                "authority": [
                  "Signature"
                ],
                "name": "Signature",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Signature__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Signature/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "Signature",
                "exact": true
              },
              {
                "path": "/Signature/view/:id",
                "authority": [
                  "Signature"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Signature__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Signature/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Exam",
                "authority": [
                  "Exam"
                ],
                "name": "Exam",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exam__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Exam/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "Exam",
                "exact": true
              },
              {
                "path": "/Exam/create",
                "authority": [
                  "Exam"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exam__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Exam/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Exam/edit/:id",
                "authority": [
                  "Exam"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exam__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Exam/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Exam/view/:id",
                "authority": [
                  "Exam"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exam__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Exam/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Quiz",
                "authority": [
                  "Quiz"
                ],
                "name": "Quiz",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quiz__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Quiz/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "Quiz",
                "exact": true
              },
              {
                "path": "/Quiz/create",
                "authority": [
                  "Quiz"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quiz__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Quiz/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Quiz/edit/:id",
                "authority": [
                  "Quiz"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quiz__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Quiz/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Quiz/view/:id",
                "authority": [
                  "Quiz"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quiz__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Quiz/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/CannedMessage",
                "authority": [
                  "CannedMessage"
                ],
                "name": "CannedMessage",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CannedMessage__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/CannedMessage/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "CannedMessage",
                "exact": true
              },
              {
                "path": "/CannedMessage/create",
                "authority": [
                  "CannedMessage"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CannedMessage__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/CannedMessage/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/CannedMessage/edit/:id",
                "authority": [
                  "CannedMessage"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CannedMessage__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/CannedMessage/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/CannedMessage/view/:id",
                "authority": [
                  "CannedMessage"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CannedMessage__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/CannedMessage/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ScreenshotActivity",
                "authority": [
                  "ScreenshotActivity"
                ],
                "name": "ScreenshotActivity",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotActivity__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotActivity/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "ScreenshotActivity",
                "exact": true
              },
              {
                "path": "/ScreenshotActivity/create",
                "authority": [
                  "ScreenshotActivity"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotActivity__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotActivity/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ScreenshotActivity/edit/:id",
                "authority": [
                  "ScreenshotActivity"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotActivity__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotActivity/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ScreenshotActivity/view/:id",
                "authority": [
                  "ScreenshotActivity"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotActivity__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotActivity/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ScreenshotAppsUrls",
                "authority": [
                  "ScreenshotAppsUrls"
                ],
                "name": "ScreenshotAppsUrls",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotAppsUrls__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotAppsUrls/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "ScreenshotAppsUrls",
                "exact": true
              },
              {
                "path": "/ScreenshotAppsUrls/create",
                "authority": [
                  "ScreenshotAppsUrls"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotAppsUrls__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotAppsUrls/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ScreenshotAppsUrls/edit/:id",
                "authority": [
                  "ScreenshotAppsUrls"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotAppsUrls__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotAppsUrls/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ScreenshotAppsUrls/view/:id",
                "authority": [
                  "ScreenshotAppsUrls"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotAppsUrls__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotAppsUrls/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ScreenshotReport",
                "authority": [
                  "ScreenshotReport"
                ],
                "name": "ScreenshotReport",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotReport__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotReport/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "ScreenshotReport",
                "exact": true
              },
              {
                "path": "/ScreenshotReport/create",
                "authority": [
                  "ScreenshotReport"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotReport__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotReport/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ScreenshotReport/edit/:id",
                "authority": [
                  "ScreenshotReport"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotReport__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotReport/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ScreenshotReport/view/:id",
                "authority": [
                  "ScreenshotReport"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScreenshotReport__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ScreenshotReport/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ServiceRequest",
                "authority": [
                  "ServiceRequest"
                ],
                "name": "ServiceRequest",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ServiceRequest__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ServiceRequest/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "ServiceRequest",
                "exact": true
              },
              {
                "path": "/ServiceRequest/create",
                "authority": [
                  "ServiceRequest"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ServiceRequest__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ServiceRequest/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ServiceRequest/edit/:id",
                "authority": [
                  "ServiceRequest"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ServiceRequest__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ServiceRequest/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ServiceRequest/view/:id",
                "authority": [
                  "ServiceRequest"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ServiceRequest__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/ServiceRequest/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/SpeedTest",
                "authority": [
                  "SpeedTest"
                ],
                "name": "SpeedTest",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SpeedTest__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/SpeedTest/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "SpeedTest",
                "exact": true
              },
              {
                "path": "/SpeedTest/create",
                "authority": [
                  "SpeedTest"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SpeedTest__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/SpeedTest/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/SpeedTest/edit/:id",
                "authority": [
                  "SpeedTest"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SpeedTest__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/SpeedTest/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/SpeedTest/view/:id",
                "authority": [
                  "SpeedTest"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SpeedTest__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/SpeedTest/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Screenshot",
                "authority": [
                  "Screenshot"
                ],
                "name": "Screenshot",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Screenshot__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Screenshot/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "Screenshot",
                "exact": true
              },
              {
                "path": "/Screenshot/create",
                "authority": [
                  "Screenshot"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Screenshot__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Screenshot/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Screenshot/edit/:id",
                "authority": [
                  "Screenshot"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Screenshot__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Screenshot/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Screenshot/view/:id",
                "authority": [
                  "Screenshot"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Screenshot__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Screenshot/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/EmailTemplate",
                "authority": [
                  "EmailTemplate"
                ],
                "name": "EmailTemplate",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmailTemplate__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/EmailTemplate/List'), loading: LoadingComponent}),
                "filed": "EmailTemplate",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/EmailTemplate/create",
                "authority": [
                  "EmailTemplate"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmailTemplate__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/EmailTemplate/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/EmailTemplate/edit/:id",
                "authority": [
                  "EmailTemplate"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmailTemplate__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/EmailTemplate/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/EmailTemplate/view/:id",
                "authority": [
                  "EmailTemplate"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EmailTemplate__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/EmailTemplate/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Team",
                "authority": [
                  "Team"
                ],
                "name": "Team",
                "filed": "Team",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Team__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Team/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Team/view/:id",
                "authority": [
                  "Team"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Team__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Team/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/User",
                "authority": [
                  "User"
                ],
                "name": "User",
                "icon": "message",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/User/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "User",
                "exact": true
              },
              {
                "path": "/User/create",
                "authority": [
                  "User"
                ],
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Create' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/User/Create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/User/edit/:id",
                "authority": [
                  "User"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/User/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/User/view/:id",
                "authority": [
                  "User"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/User/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Account",
                "authority": [
                  "Account"
                ],
                "name": "Account",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__List' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Account/List'), loading: LoadingComponent}),
                "hideInMenu": true,
                "filed": "Account",
                "exact": true
              },
              {
                "path": "/Account/edit/:id",
                "authority": [
                  "Account"
                ],
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Edit' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Account/Edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/Account/view/:id",
                "authority": [
                  "Contact"
                ],
                "name": "view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__View' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/Account/View'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/lib/jenkins/workspace/espo-ui-mycrm-prod/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
