export default {
  'menu.sample': 'Sample',
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.create': 'Create',
  'menu.edit': 'Edit',
  'menu.view': 'View',
  'menu.PhoneCalls': 'Phone Calls',
  'menu.phoneCall.phoneCallEdit': 'Edit',
  'menu.phoneCall.phoneCallView': 'View',
  'menu.phoneCallEdit': 'Edit',
  'menu.phoneCallView': 'View',
  'menu.case': 'Tickets',
  'menu.caseEdit': 'Edit',
  'menu.caseView': 'View',
  'menu.Emails': 'Emails',
  'menu.EmailEdit': 'Edit',
  'menu.EmailView': 'View',
  'menu.knowledgeBaseArticle': 'Knowledge Base',
  'menu.knowledgeBaseArticleEdit': 'Edit',
  'menu.knowledgeBaseArticleView': 'View',
  'menu.SMS': 'Messages',
  'menu.SMSEdit': 'Edit',
  'menu.SMSView': 'View',
  'menu.Chat': 'Chats',
  'menu.contact': 'Customers',
  'menu.contactEdit': 'Edit',
  'menu.contactView': 'View',
  'menu.report': 'Report',
  'menu.report.dashboard': 'Dashboard',
  'menu.notification': 'Notification',
  'menu.report.sample': 'Sample',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.more': 'More',
  'menu.more.create': 'Create',
  'menu.more.edit': 'Edit',
  'menu.more.view': 'View',
  'menu.more.Timesheet': 'Timesheets',
  'menu.more.TimesheetEdit': 'Edit',
  'menu.more.TimesheetView': 'View',
  'menu.more.WriteUp': 'Write-ups',
  'menu.more.WriteUpEdit': 'Edit',
  'menu.more.WriteUpView': 'View',
  'menu.more.AbsenceNote': 'Absence Notes',
  'menu.more.AbsenceNoteEdit': 'Edit',
  'menu.more.AbsenceNoteView': 'View',
  'menu.more.AgentSurvey': 'Agent Surveys',
  'menu.more.AgentSurveyEdit': 'Edit',
  'menu.more.AgentSurveyView': 'View',
  'menu.more.Signature': 'Signatures',
  'menu.more.SignatureEdit': 'Edit',
  'menu.more.SignatureView': 'View',
  'menu.more.Account': 'Account',
  'menu.more.Team': 'Team',
  'menu.more.User': 'User',
  'menu.more.AdminTimesheet': 'Timesheet Tracking',
  'menu.more.SMTimesheet': 'Timesheet Management',
  'menu.fulfillment': 'Fulfillment',
  'menu.fulfillment.create': 'Create',
  'menu.fulfillment.edit': 'Edit',
  'menu.fulfillment.view': 'View',
  'menu.fulfillment.Order': 'Orders',
  'menu.fulfillment.OrderLine':'OrderLine',
  'menu.fulfillment.OrderEdit': 'Edit',
  'menu.fulfillment.OrderView': 'View',
  'menu.fulfillment.Product': 'Products',
  'menu.fulfillment.ProductEdit': 'Edit',
  'menu.fulfillment.ProductView': 'View',
  'menu.Order': 'Orders',
  'menu.OrderEdit': 'Edit',
  'menu.OrderView': 'View',
  'menu.Product': 'Products',
  'menu.ProductEdit': 'Edit',
  'menu.ProductView': 'View',
  'menu.Document': 'Documents',
  'menu.DocumentEdit': 'Edit',
  'menu.DocumentView': 'View',
  'menu.more.Exam': 'Exams',
  'menu.more.ExamEdit': 'Edit',
  'menu.more.ExamView': 'view',
  'menu.more.Quiz': 'Quizzes',
  'menu.more.QuizEdit': 'Edit',
  'menu.more.QuizView': 'view',
  'menu.more.CannedMessage': 'Canned Messages',
  'menu.more.CannedMessageEdit': 'Edit',
  'menu.more.CannedMessageView': 'view',
  'menu.more.ScreenshotActivity': 'Monitoring Slots',
  'menu.more.ScreenshotActivityEdit': 'Edit',
  'menu.more.ScreenshotActivityView': 'view',
  'menu.more.ScreenshotAppsUrls': 'Monitoring Apps',
  'menu.more.ScreenshotAppsUrlsEdit': 'Edit',
  'menu.more.ScreenshotAppsUrlsView': 'view',
  'menu.more.ScreenshotReport': 'Monitoring Summaries',
  'menu.more.ScreenshotReportEdit': 'Edit',
  'menu.more.ScreenshotReportView': 'view',
  'menu.more.ServiceRequest': 'Service Requests',
  'menu.more.ServiceRequestEdit': 'Edit',
  'menu.more.ServiceRequestView': 'view',
  'menu.more.SpeedTest': 'Speed Tests',
  'menu.more.SpeedTestEdit': 'Edit',
  'menu.more.SpeedTestView': 'view',
  'menu.more.Screenshot': 'Monitoring Screenshots',
  'menu.more.ScreenshotEdit': 'Edit',
  'menu.more.ScreenshotView': 'view',
  'menu.stats': 'Analytics',
  'menu.stats.Overview': 'Overview',
  'menu.stats.AgentPerformance': 'Agent Performance',
  'menu.stats.PhoneCall': 'PhoneCall',
  'menu.stats.Email': 'Email',
  'menu.stats.Chat': 'Chat',
  'menu.ChatsOpen': 'Chatting With',
  'menu.agent.AvgReply': 'Avg. Response',
  'menu.agent.Chats': 'Chats',
  'menu.agent.AvgDuration': 'Avg Duration',
  'menu.agent.Messages': 'Messages',
  'menu.agent.AvgResponse': 'Avg 1st Response',
  'menu.agent.BestResponse': 'Best 1st Response',
  'menu.agent.AvgResponses': 'Avg Response',
  'menu.agent.AvgReaction': 'Avg Reaction',
  'menu.agent.TalkTime': 'Talk Time',
  'menu.agent.WaitTime': 'Wait Time',
  'menu.agent.Emalititle': 'Email Volume and Response Time',
  'menu.agent.ReceivedSent': 'Received vs. Sent',
  'menu.stats.Chattitle': 'Chat Analytics',
  'menu.stats.TimeMetrics': 'Time Metrics',
  'menu.case.action.open': 'Open',
  'menu.case.action.quickview': 'Quick View',
  'menu.case.action.delete': 'Delete',
  'menu.case.view.stream2': 'Write your note here...',
  'menu.case.view.webchat2': 'Type your message here ...',
  'menu.case.view.subject': 'Type email subject here ...',
  'menu.case.view.email': 'Write email message here ...',
  'menu.case.view.stream': 'Note',
  'menu.case.view.email2': 'Email',
  'menu.case.view.ChatRoom': 'Live Chat',
  'menu.case.view.call': 'Call',
  'menu.case.view.sms': 'SMS',
  'menu.case.view.webchat': 'LiveChat',
  'menu.case.view.facebook': 'Facebook',
  'menu.stats.Agent': 'Agent',
  'menu.stats.alert': 'Scroll down to see more',
  'menu.Channels':'Channels',
  'menu.Channels.PhoneCalls':'PhoneCalls',
  'menu.Channels.Chats':'Chats',
  'menu.Channels.Emails':'Emails',
  'menu.sms.Online':'Pause',
  'menu.sms.Offline':' Paused',
  'menu.sms.typing':'typing...',
  'menu.note.StandardDate':'Standard Date',
  'menu.note.PrettyDate':'Pretty Date',
  'menu.more.EmailTemplate': 'Email Template',
  'menu.Back':'Back',
  'menu.closeAll':'Close All',
  'menu.case.view.feature':'This feature is not enabled for your account.',
  'menu.timeSheet.invoiceStatus':'Do you want to change the invoice status?',
  'menu.case.view.FoldTheDialogue':'Fold',
  'menu.case.view.OpenaDialogue':'Unfold',
  'menu.stats.Csat': 'CSAT',
  'menu.stats.Csat.Export': 'Export',
  'menu.stats.Csat.overview': 'Overview',
  'menu.stats.Csat.particulars': 'Particulars',
  'menu.stats.Csat.positive': 'Positive',
  'menu.stats.Csat.neutrality': 'Neutrality',
  'menu.stats.Csat.passive': 'Passive',
  'menu.stats.Csat.verySatisfiedNum': 'Excellent',
  'menu.stats.Csat.basicallySatisfiedNum': 'Good',
  'menu.stats.Csat.neutralNum': 'Fair',
  'menu.stats.Csat.dissatisfiedNum': 'Poor',
  'menu.stats.Csat.veryDissatisfiedNum': 'Very poor',
  'menu.speedTest.poorInternet':'Poor internet connection, pls check your internet settings!',
  'menu.speedTest.noInternet':'No internet connection, pls check your internet settings!',
  'menu.ChatsOpen.satisfactionrating':'Satisfaction Rating',
  'menu.ChatsOpen.issueresolved':'Issue resolved:',
  'menu.ChatsOpen.servicerating':'Service rating:',
  'menu.ChatsOpen.yes':'Yes',
  'menu.ChatsOpen.no':'No',
  'menu.ChatsOpen.comments':'Comments:',
  'menu.phoneCalls.missedCall':'missed call',
  'menu.phone.notopenprompt':'Attention: Dial pad has not opened.',
  'menu.phone.dialpadhasopened':'Dial pad has opened.',
  'menu.modules.include.Archived':'Include Archived',
  'menu.modules.include.Archived.Tips':'Include Archived Switch: Yes / No. Yes: if a user changes the switch to Yes means the user can view the data for the last 365 days. No: if a user changes the switch to No means the user can view the data for the last 120 days. The default option is No.',
  'menu.modules.include.Archived.title.open': 'yes',
  'menu.modules.include.Archived.title.close': 'no',
  'menu.Signature.CKEditor.flie.tips': 'The attachment is too large. The supported max size is 50M, please  retry.',
  'menu.Signature.CKEditor.title.tips': 'The signature content is too long. Please shorten it and retry.',
  'menu.Notification.tips': 'Unread',
  'menu.Contact.email.bemixed': 'The same customer has found in system',
  'menu.searchFrom.saveAs': 'Save As',
  'menu.SMTimesheet.workDate': 'Work Date',
  'menu.SMTimesheet.totalWorkingHours': 'Total Working Hours',
  'menu.SMTimesheet.language': 'Language',
  'menu.SMTimesheet.jobType': 'Job Type',
};
